import { Box, Flex, HStack, Select } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Alert, SearchInput } from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import {
  useNumberSearchParam,
  useSearchParam,
} from "../../../hooks/useSearchParam";
import { getAllowedLimitValues } from "../../../utils/pagination";
import CallAiSummaryTemplateList from "../../components/CallAiSummaryTemplateList/CallAiSummaryTemplateList";
import {
  CallAiSummaryTemplateListItemFragment,
  useCurrentUserMyCallSummaryTemplatesLazyQuery,
  useCurrentUserSharedCallSummaryTemplatesLazyQuery,
} from "../../graphql";

const DEFAULT_LIMIT = 40;

const TemplatesList: React.FC<{
  category: "my" | "shared";
  active: boolean;
}> = ({ category, active }) => {
  const location = useLocation();
  const [limit, setLimit] = useNumberSearchParam("limit", DEFAULT_LIMIT);
  const limits = useMemo((): number[] => {
    return getAllowedLimitValues(limit ?? DEFAULT_LIMIT);
  }, [limit, location.pathname]);

  const query =
    category === "my"
      ? useCurrentUserMyCallSummaryTemplatesLazyQuery
      : useCurrentUserSharedCallSummaryTemplatesLazyQuery;

  const [getTemplates, { loading, error }] = query({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      const paginatedTemplates =
        currentUser?.organization.callAiSummaryTemplates;
      if (paginatedTemplates?.results) {
        setListItems(paginatedTemplates.results);
        setNumberOfPages(paginatedTemplates.pageInfo?.totalPages || 0);
      }
    },
  });

  const [initialSearchTerm] = useSearchParam("searchTerm");
  const {
    listItems,
    setListItems,
    setNumberOfPages,
    pageOptions,
    setSearchTerm,
    searchTerm,
  } = useOffsetPagination<CallAiSummaryTemplateListItemFragment>(getTemplates, {
    initialSortBy: { id: "createdAt", desc: true },
    initialSearchTerm,
    pageLimit: limit ?? DEFAULT_LIMIT,
  });

  const search = (
    <Flex py={4} flexFlow="row nowrap" width="100%">
      <SearchInput
        height="40px"
        pr={4}
        maxWidth="450px"
        defaultValue={searchTerm}
        placeholder="Filter by template name"
        onSearch={(updatedQuery: string) => {
          if (updatedQuery !== searchTerm) {
            setSearchTerm(updatedQuery);
          }
        }}
      />
      <Flex alignItems="center">
        <HStack hidden={false}>
          <Box px={2} whiteSpace="nowrap">
            Max results
          </Box>
          <Select
            defaultValue={limit ?? DEFAULT_LIMIT}
            size="sm"
            bg="white"
            width="120px"
            borderRadius="md"
            onChange={(e: React.SyntheticEvent<HTMLSelectElement, Event>) => {
              setLimit(parseInt(e.currentTarget.value, 10));
            }}
          >
            {limits.map((l) => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </Select>
        </HStack>
      </Flex>
    </Flex>
  );

  if (error)
    return (
      <>
        {search}
        <Alert
          status="error"
          title="Error loading templates"
          description={error.message}
        />
      </>
    );

  return (
    <>
      <Box mt="4">{search}</Box>

      <CallAiSummaryTemplateList
        pageOptions={pageOptions}
        templates={listItems}
        loading={loading}
        sortBy={pageOptions.sortBy}
      />
    </>
  );
};

export default TemplatesList;
