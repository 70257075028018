import { useMemo, useState } from "react";

import {
  dateRange6MonthsAgo,
  SelectDateRangeState,
} from "../../../../components/SelectDateRange/SelectDateRange";
import {
  useEnumSearchParam,
  useSelectDateRangeParams,
  useStringArrayParam,
} from "../../../../hooks/useSearchParam";
import { formatDateRange } from "../../../../utils/datetime";
import { AnalyticsDimension, CandidateMotivationTopic } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { MultiSelect, SingleSelect } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import { isDimensionSupportedForOrg } from "../utils";

export type CandidateMotivationOption = CandidateMotivationTopic | "ALL";

export type CandidateMotivationsConfig = {
  primaryDimension: SingleSelect<AnalyticsDimension>;
  secondaryDimension: SingleSelect<AnalyticsDimension>;
  positions: MultiSelect;
  interviewers: MultiSelect;
  departments: MultiSelect;
  stages: MultiSelect;
  motivationTopic: SingleSelect<CandidateMotivationOption>;
  dateRange: {
    value: SelectDateRangeState;
    displayValue: string;
    setValue(value: SelectDateRangeState | undefined): void;
  };
  filters: {
    positions: string[];
    interviewers: string[];
    departments: string[];
    stages: string[];
  };
  enabledFilters: {
    positions: boolean;
    interviewers: boolean;
    departments: boolean;
    stages: boolean;
  };
};

/**
 * Manages the state of user-selectable analytics configurations such as
 * filters, dimensions, and chart settings.
 *
 * Only manages the config, but not the query execution.
 */
const useCandidateMotivationsConfig = (
  defaultDateRange = dateRange6MonthsAgo
): CandidateMotivationsConfig => {
  const [positions, setPositions] = useStringArrayParam("positions");
  const [interviewers, setInterviewers] = useStringArrayParam("interviewers");
  const [interviewerLabels, setInterviewerLabels] = useState<
    string[] | undefined
  >(undefined);
  const [departments, setDepartments] = useStringArrayParam("departments");
  const [stages, setStages] = useStringArrayParam("stages");
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    defaultDateRange()
  );
  const [motivationTopic, setMotivationTopic] =
    useEnumSearchParam<CandidateMotivationOption>("motivation", "ALL");

  const filters = {
    positions: positions || [],
    interviewers: interviewers || [],
    departments: departments || [],
    stages: stages || [],
  };

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  const { atsDataState } = useAnalyticsContext();
  const stageFilterOn = useFeatureFlag("analytics:canva-stage-filters");
  const stageSupported =
    stageFilterOn ||
    isDimensionSupportedForOrg(AnalyticsDimension.JobStage, atsDataState);

  return {
    primaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    secondaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    positions: {
      values: positions,
      setValues: setPositions,
    },
    interviewers: {
      values: interviewers,
      setValues: setInterviewers,
      labels: interviewerLabels,
      setLabels: setInterviewerLabels,
    },
    departments: {
      values: departments,
      setValues: setDepartments,
    },
    stages: {
      values: stages,
      setValues: setStages,
    },
    motivationTopic: {
      value: motivationTopic || "ALL",
      setValue: setMotivationTopic,
      // These are calculated dynamically based on the chart (count) data
      options: [],
    },
    dateRange: {
      // Default is defined inline because it dynamically calculates a range
      value: dateRange,
      displayValue: displayDateRangeValue,
      setValue: setDateRange,
    },
    filters,
    enabledFilters: {
      positions: true,
      interviewers: true,
      departments: true,
      stages: stageSupported,
    },
  };
};

export default useCandidateMotivationsConfig;
