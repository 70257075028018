import { Checkbox, Text } from "@chakra-ui/react";
import React from "react";

import { SettingsPageContainer, useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationInterviewPlanSettingsMutation,
} from "../../graphql";

interface PlanInterviewPlanSettingsProps {
  currentUser: CurrentUserFragment;
}

const PlanInterviewPlanSettings: React.FC<PlanInterviewPlanSettingsProps> = ({
  currentUser,
}) => {
  const { createGuidesFromInterviewPlansEnabled } = currentUser.organization;
  const toast = useToast();
  const [updateOrganizationInterviewPlanSettings] =
    useUpdateOrganizationInterviewPlanSettingsMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationInterviewPlanSettings?.organization) {
          toast({
            status: "success",
            description: "Settings updated",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          description: "There was a problem - please try again",
        });
      },
    });
  return (
    <SettingsPageContainer heading="Interview plans" maxW="776px">
      <Checkbox
        isChecked={createGuidesFromInterviewPlansEnabled}
        onChange={() =>
          updateOrganizationInterviewPlanSettings({
            variables: {
              createGuidesFromInterviewPlansEnabled:
                !createGuidesFromInterviewPlansEnabled,
            },
          })
        }
      >
        <Text>Create guides from interview plans</Text>
      </Checkbox>
      <Text mt="2" color="gray.600" fontSize="sm">
        When enabled, interview guides can be created with one click from
        existing interview plans. Guides will be created in BrightHire and are
        not synced to an ATS.
      </Text>
    </SettingsPageContainer>
  );
};

export default PlanInterviewPlanSettings;
