import React from "react";

import { SettingsPageContainer, useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationBetaMutation,
} from "../../graphql";
import OrganizationSettingsForm from "./OrganizationSettingsForm";

interface SignUpAndSignInProps {
  currentUser: CurrentUserFragment;
}

const SignUpAndSignIn: React.FC<SignUpAndSignInProps> = ({ currentUser }) => {
  const toast = useToast();
  const [
    updateOrganizationSettings,
    { loading: orgSettingsLoading, error: orgSettingsError },
  ] = useUpdateOrganizationBetaMutation({
    onCompleted: (data) => {
      if (data?.updateOrganizationBeta?.currentUser) {
        toast({
          status: "success",
          title: "Organization Settings",
          description: "Success!",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Organization Settings",
        description: "There was a problem - please try again",
      });
    },
  });

  return (
    <SettingsPageContainer
      heading="Sign up & sign in"
      subHeading="Configure options for how your users sign up for and sign in to BrightHire"
    >
      <OrganizationSettingsForm
        allowSelfSignUp={currentUser.organization.allowSelfSignUp}
        allowPhoneSignIn={currentUser.organization.allowPhoneSignIn}
        emailDomains={currentUser.organization.emailDomains?.join(", ")}
        inviteScheduledInterviewers={
          currentUser.organization.inviteScheduledInterviewers
        }
        requireActiveUserToImport={
          currentUser.organization.requireActiveUserToImport
        }
        isLoading={orgSettingsLoading}
        error={orgSettingsError}
        onSubmit={(formData) => {
          updateOrganizationSettings({
            variables: {
              allowSelfSignUp: formData.allowSelfSignUp,
              allowPhoneSignIn: formData.allowPhoneSignIn,
              emailDomains: formData.emailDomains
                .split(",")
                .map((e) => e.trim()),
              inviteScheduledInterviewers: formData.inviteScheduledInterviewers,
              requireActiveUserToImport: formData.requireActiveUserToImport,
            },
          });
        }}
      />
    </SettingsPageContainer>
  );
};

export default SignUpAndSignIn;
