import * as Sentry from "@sentry/browser";
import { useState } from "react";

/**
 * Like `useLocalStorageState`, but never persists the initial value.
 */
function useLazyLocalStorageState<T>(
  key: string,
  initialValue: T
): [T, (v: T) => void] {
  // Local storage setting by itself does not trigger a react tree rerender
  const [forceRerender, setForceRerender] = useState(0);
  const getStoredValue = (): T => {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  };
  const value = getStoredValue();
  const setValue = (v: T): void => {
    if (v === initialValue) {
      localStorage.removeItem(key);
    } else {
      try {
        localStorage.setItem(key, JSON.stringify(v));
        setForceRerender(forceRerender + 1);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  return [value, setValue];
}

export default useLazyLocalStorageState;
