import { Box, Divider, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { MdCheckCircle } from "react-icons/md";

import { Alert, Link, SettingsPageContainer } from "../../../../components";
import { CurrentUserFragment, Organization } from "../../../graphql";
import StyledZoomImg from "../StyleZoomImg";
import { ZoomAppAutoOpenUserSetting } from "./ZoomAppAutoOpenUserSetting";
import { ZoomNativeUserSettings } from "./ZoomNativeUserSettings";

export type ZoomAppSettingsProps = {
  currentUser: Pick<
    CurrentUserFragment,
    "isZoomAppInstalled" | "zoomAppAutoOpenEnabled"
  > & {
    organization: Pick<
      Organization,
      | "isZoomAuthorized"
      | "zoomNotetakerEnabled"
      | "allowsZoomAppAutoOpen"
      | "requiresZoomAppAutoOpen"
    >;
  };
};

export const ZoomAppSettings: React.FC<ZoomAppSettingsProps> = ({
  currentUser,
}) => {
  const { organization } = currentUser;

  if (!(organization.isZoomAuthorized || organization.zoomNotetakerEnabled)) {
    return (
      <Alert
        description="Please contact an admin to enable zoom for your account"
        status="error"
      />
    );
  }

  return (
    <SettingsPageContainer
      heading="Zoom app"
      subHeading={
        <Box mb="3">
          Use the Interview Assistant in Zoom Meetings.&nbsp;
          <Link
            href="https://help.brighthire.ai/en/collections/3357021-brighthire-for-zoom-meetings"
            target="_blank"
          >
            Learn More
          </Link>
        </Box>
      }
    >
      <Heading as="h2" size="sm" mb={2}>
        Installation
      </Heading>
      {currentUser.isZoomAppInstalled ? (
        <>
          <Flex alignItems="center" mb="2">
            <Icon as={MdCheckCircle} color="green.600" />
            <Box ml={2}>Installed</Box>
          </Flex>
          <Text fontSize="sm" color="gray.600">
            To manage the application please visit the{" "}
            <Link
              href="https://marketplace.zoom.us/apps/TUyIp7DkQM29tYD4qg0s9g"
              target="_blank"
            >
              Zoom Marketplace
            </Link>
          </Text>
          {organization.allowsZoomAppAutoOpen && (
            <>
              <Divider my="8" />
              <ZoomAppAutoOpenUserSetting
                userHasZoomAppAutoOpenEnabled={
                  !!currentUser.zoomAppAutoOpenEnabled
                }
                orgRequiresZoomAppAutoOpen={
                  organization.requiresZoomAppAutoOpen
                }
              />
            </>
          )}
        </>
      ) : (
        <>
          <Box mb="4" color="gray.600">
            Click below to install the BrightHire Zoom application
          </Box>
          <a href="/zoomapp/install" target="_blank" rel="noopener noreferrer">
            <StyledZoomImg
              src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
              alt="Add to ZOOM"
            />
          </a>
        </>
      )}
      {organization.isZoomAuthorized && (
        <>
          <Divider my="8" />
          <Heading as="h2" size="sm" mb={2}>
            Zoom User Settings
          </Heading>
          <ZoomNativeUserSettings />
        </>
      )}
    </SettingsPageContainer>
  );
};
