import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Button, useToast } from "../../../components";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";

export interface FormData {
  persona: string;
}

const SignUpForm: React.FC = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({ mode: "onChange" });
  const [updateCurrentUser, { loading }] = useUpdateCurrentUser({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onSubmit = (formData: FormData): void => {
    updateCurrentUser({ variables: formData });
  };

  return (
    <form
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
      autoComplete="on"
    >
      <FormControl
        id="persona"
        mb={3}
        isRequired
        isInvalid={errors.persona !== undefined}
      >
        <FormLabel maxW="240px" fontSize="sm">
          Which option best describes your role?
        </FormLabel>

        <Select
          {...register("persona", {
            required: "Please select an option",
          })}
          size="sm"
          isDisabled={loading}
          width="240px"
          data-testid="select-persona"
        >
          <option key="-" value="">
            Please select an option
          </option>
          <option key="INTERVIEWER" value="INTERVIEWER">
            Interviewer
          </option>
          <option key="COORDINATOR_OPS" value="COORDINATOR_OPS">
            Recruiting Coordinator/Operations
          </option>
          <option key="RECRUITER" value="RECRUITER">
            Recruiter
          </option>
          <option key="SOURCER" value="SOURCER">
            Sourcer
          </option>
          <option key="HIRING_MANAGER" value="HIRING_MANAGER">
            Hiring Manager
          </option>
          <option key="TA_LEADER" value="TA_LEADER">
            Executive/TA Leader
          </option>
        </Select>
        {errors.persona !== undefined && (
          <FormErrorMessage>{errors.persona.message}</FormErrorMessage>
        )}
        <FormHelperText maxW="240px">
          This will help us provide the best experience for you.
        </FormHelperText>
      </FormControl>
      <Flex>
        <Button
          type="submit"
          mt={6}
          mx="auto"
          data-testid="continue"
          isLoading={loading}
          isDisabled={!isValid}
        >
          Continue
        </Button>
      </Flex>
    </form>
  );
};

export default SignUpForm;
