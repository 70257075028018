import {
  FeatureName,
  useCandidateSummaryPositionPermissionsQuery,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { useHasFeatureAccess } from "../../../hooks/useHasFeatureAccess";
import { useCandidateSummaryFullyEnabled } from "./useCandidateSummaryFullyEnabled";

export const useCanViewCandidateSummary = (
  positionId: string | null
): {
  summariesEnabled: boolean;
  canViewSummaries: boolean;
  loading: boolean;
} => {
  const uiFlagEnabled = useFeatureFlag("candidate_summary:ui");
  const fullyFlagEnabled = useCandidateSummaryFullyEnabled();
  const flagEnabled = fullyFlagEnabled || uiFlagEnabled;
  const summariesEnabled = useHasFeatureAccess(FeatureName.CandidateAiSummary);
  const { data: permissions, loading } =
    useCandidateSummaryPositionPermissionsQuery({
      skip: !summariesEnabled,
      variables: {
        positionId,
      },
    });
  const canViewSummaries = Boolean(
    permissions?.candidateSummaryPositionPermissions?.canViewSummaries
  );

  return {
    summariesEnabled,
    canViewSummaries: flagEnabled && canViewSummaries,
    loading,
  };
};
