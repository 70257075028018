import { FeatureName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";

export const useCandidateAlertDisabled = (): boolean => {
  const userEnabled = useFeatureFlag("enable:candidate:alert:user");
  const orgDisabled = useFeatureFlag("disable:candidate:alert");
  const hasFeatureAccess = useHasFeatureAccess(FeatureName.CandidateAlerts);
  if (!hasFeatureAccess) return true;
  // User level enablement overrides org level disablement
  if (userEnabled === true) return false;
  if (orgDisabled === true) return true;
  return false;
};
