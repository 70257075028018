import { ExampleTemplate } from "../types";

export const intakeExample: ExampleTemplate = {
  name: "New Role Intake",
  description: "Actionable details from an intake meeting.",
  prompt: `This call is a job intake session. Organize by topic with h2 headers and bullets. Focus on capturing key details about the job role, requirements, and recruitment strategy. Group the topics into three main categories (use h1 headers).

Major categories:
* Role Overview
* Candidate Profile
* Recruitment Strategy and Next Steps

Organize by these topics:
* Role Overview: Summarize the job title, department, and main responsibilities. Capture details about the reporting structure and key performance indicators (KPIs).
  
  - Job Title and Department
  - Key Responsibilities
  - Reporting Structure
  - Performance Indicators (KPIs)

* Candidate Profile: Outline the key skills, qualifications, and experience required for the candidate. Capture details on cultural fit and any preferred qualifications.
  
  - Essential Skills and Qualifications
  - Experience Level
  - Cultural Fit
  - Preferred Qualifications

* Recruitment Strategy and Next Steps: Detail the recruitment process, including sourcing strategies and interview stages. Summarize any timelines or deadlines discussed and finalize the next steps.
  
  - Sourcing Strategies
  - Interview Stages
  - Timelines and Deadlines
  - Next Steps

Do not insert your own judgement or opinions. Only summarize the information provided during the intake, and ensure to accurately represent the expectations laid out by the participants.`,
  output: `# Role Overview

## Job Title and Department
- The position title is "Senior Marketing Analyst" within the Marketing Analytics Department. The role focuses on driving data-driven marketing strategies. [03:15](?t=195)
- This role is essential for collaborating with product teams to enhance targeted marketing efforts. [04:45](?t=285)

## Key Responsibilities
- The main responsibility involves analyzing market trends and customer data to support business decisions. [07:22](?t=442)
- The role also includes developing dashboards and reports to track marketing campaign performance. [08:35](?t=515)
- A significant part of the job is working closely with cross-functional teams to optimize channel performance. [09:47](?t=587)

## Reporting Structure
- The Senior Marketing Analyst reports directly to the Marketing Director. [12:03](?t=723)
- Team collaboration occurs predominantly with the data science and digital marketing teams. [13:22](?t=802)

## Performance Indicators (KPIs)
- Key performance indicators include campaign ROI, customer acquisition cost reduction, and data accuracy in reports. [15:18](?t=918)
- Another significant KPI is the improvement in customer engagement metrics through analytical insights. [16:50](?t=1010)

# Candidate Profile

## Essential Skills and Qualifications
- The candidate must possess advanced proficiency in data analysis tools such as SQL, Excel, and Tableau. [19:23](?t=1163)
- Strong communication skills are required to convey complex analytical findings to non-technical stakeholders. [20:47](?t=1247)

## Experience Level
- A minimum of 5 years of experience in marketing analytics or a related field is required. [23:15](?t=1395)
- Previous experience in e-commerce or digital marketing sectors is highly desirable. [24:36](?t=1476)

## Cultural Fit
- Candidates should be adaptable and comfortable working in a fast-paced, dynamic environment. [27:10](?t=1630)
- Emphasizing teamwork and a collaborative spirit is crucial for success in this role. [28:45](?t=1725)

## Preferred Qualifications
- An advanced degree in data analytics, marketing, or a related field is preferred. [31:00](?t=1860)
- Familiarity with machine learning techniques or predictive analytics will be an asset. [32:34](?t=1954)

# Recruitment Strategy and Next Steps

## Sourcing Strategies
- The recruitment team will use a mix of online job portals and professional networking sites to source candidates. [35:12](?t=2112)
- Internal referrals and university partnerships will also be leveraged to identify potential hires. [36:40](?t=2200)

## Interview Stages
- The interview process will consist of an initial screening, followed by a technical assessment, and concluding with a panel interview. [38:49](?t=2329)
- Candidates may be asked to present a case study or data analysis problem during the final stage. [39:55](?t=2395)

## Timelines and Deadlines
- The aim is to fill the position within 60 days, targeting a completion by the end of the current quarter. [42:18](?t=2538)
- Application reviews and initial screenings will commence immediately over the next two weeks. [43:45](?t=2625)

## Next Steps
- The next step involves drafting a comprehensive job advertisement to be posted on various platforms by the end of the week. [46:05](?t=2765)
- Following up with potential candidates via email or phone calls to schedule interviews is scheduled to start from next Monday. [47:30](?t=2850)`,
};
