import { Alert, AlertDescription, Box } from "@chakra-ui/react";
import React from "react";

import { SearchInput, SettingsPageContainer } from "../../../components";
import useOffsetPagination from "../../../hooks/useOffsetPagination";
import HiringTeamList from "../../components/HiringTeamList";
import {
  PositionListItemFragment,
  useOrgHiringTeamsLazyQuery,
} from "../../graphql";

const TeamsPage: React.FC = () => {
  const [getPositions, { loading, error }] = useOrgHiringTeamsLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      if (currentUser?.organization?.positions?.results) {
        const positionsList = currentUser?.organization?.positions?.results;
        setListItems(positionsList);
        setNumberOfPages(
          currentUser?.organization?.positions.pageInfo?.totalPages || 0
        );
      }
    },
  });

  const {
    listItems,
    setListItems,
    setNumberOfPages,
    pageOptions,
    searchTerm,
    setSearchTerm,
    didFetchComplete,
  } = useOffsetPagination<PositionListItemFragment>(getPositions, {
    initialSortBy: { id: "createdAt", desc: true },
  });

  if (error)
    return (
      <Alert status="error" title="Error loading teams">
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    );

  return (
    <SettingsPageContainer
      maxW="fit-content"
      heading="Hiring teams"
      subHeading="Select a hiring team to view position details and manage members."
      mt={4}
    >
      <Box mb={2}>
        <SearchInput
          onSearch={setSearchTerm}
          defaultValue={searchTerm}
          placeholder="Search positions"
        />
      </Box>
      {didFetchComplete ? (
        <HiringTeamList
          positions={listItems}
          loading={loading}
          pageOptions={pageOptions}
          sortBy={pageOptions.sortBy}
        />
      ) : null}
    </SettingsPageContainer>
  );
};

export default TeamsPage;
