import { ExampleTemplate } from "../types";

export const recruitingScreenExample: ExampleTemplate = {
  name: "Recruiting Screen",
  description: "Take notes for the hiring manager after a recruiter screen.",
  prompt: `Summarize this recruiter screen. The output is for the hiring manager of this role. Focus on the key information to pass to the hiring manager. 
    
Organize the results by h2 header topics, with bullet points for each topic and citations for each bullet. Be objective, and avoid inserting your own opinions.

Topics:
* Candidate background
* Professional accomplishments
* Motivations and ideal next role
* Search status and timing
* Next steps and follow ups.
`,
  output: `## Candidate Background
- The candidate has over six years of experience in sales, primarily in the technology sector, with a focus on B2B solutions. They have worked at both startups and mid-sized companies. [01:05](?t=65)
- They hold a Bachelor's degree in Business Administration and are fluent in both English and Spanish, which has been beneficial in expanding their company's reach into Latin American markets. [02:10](?t=130)

## Professional Accomplishments
- Successfully increased annual sales by 30% within a year at their current company, contributing to the company becoming a market leader in their region. [07:15](?t=435)
- Recognized as 'Salesperson of the Year' for three consecutive years due to consistently exceeding sales targets and fostering key client relationships. [07:18](?t=438)
- Led a team of five sales representatives, providing mentorship and training that improved team performance by 40%. [07:22](?t=442)

## Motivations and Ideal Next Role
- Motivated to work in a dynamic, growth-oriented environment, leveraging expertise in B2B sales and strategy. [12:25](?t=745)
- Ideal next role involves more strategic input in business development and managing larger, cross-functional teams. [12:27](?t=747)

## Search Status and Timing
- Actively exploring new opportunities for the past month following structural changes in the current company that affected growth prospects. [17:30](?t=1050)
- Available to transition into a new role with a two-week notice period. [17:33](?t=1053)

## Next Steps and Follow Ups
- Keen to learn more about the company's culture and growth plans, and interested in a meeting with the hiring manager. [22:35](?t=1355)
- Will follow up with references and required documents by the end of the week and is open to additional screenings or interviews. [22:38](?t=1358)`,
};
