import { Divider } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import React from "react";
import { useForm } from "react-hook-form";

import {
  Alert,
  Button,
  LoadingIndicator,
  SettingsNote,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  TextMessageTemplateType,
  useTextMessageTemplatesQuery,
  useUpdateTextMessageTemplateMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import TextMessageTemplate, { FormData } from "./TextMessageTemplate";

const TemplateSettings: React.FC = () => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();
  const orgSettingEnabled = currentUser.organization.textBeforeCallEnabled;
  /** Temp flag to allow select users at org to try out feature */
  const flagEnabled = useFeatureFlag("text-before-call");
  const textBeforeCallEnabled = flagEnabled || orgSettingEnabled;

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      callBack: "",
      preCall: "",
    },
  });

  const { loading: fetchTemplatesLoading, error: fetchTemplatesError } =
    useTextMessageTemplatesQuery({
      onCompleted(data) {
        let callBack = "";
        let preCall = "";

        data.textMessageTemplates.forEach(({ templateType, content }) => {
          if (templateType === TextMessageTemplateType.CallBack) {
            callBack = content;
          }
          if (templateType === TextMessageTemplateType.TextBeforeCall) {
            preCall = content;
          }
        });

        reset({ callBack, preCall });
      },
    });

  const [updateTextMessageTemplate, { loading: updateLoading }] =
    useUpdateTextMessageTemplateMutation({
      onError: (e) => {
        Sentry.captureException(e);
      },
    });

  const onSubmit = async ({ callBack, preCall }: FormData): Promise<void> => {
    sendGAEvent("updated", "template_settings");
    const updateCallBack = updateTextMessageTemplate({
      variables: {
        templateType: TextMessageTemplateType.CallBack,
        content: callBack,
      },
    });
    const updates = [updateCallBack];

    if (textBeforeCallEnabled) {
      const updatePreCall = updateTextMessageTemplate({
        variables: {
          templateType: TextMessageTemplateType.TextBeforeCall,
          content: preCall,
        },
      });
      updates.push(updatePreCall);
    }

    const results = await Promise.all(updates);

    if (results.some((r) => !!r.errors)) {
      toast({
        title: "Error updating template",
        status: "error",
      });
    } else {
      toast({
        title: "Settings saved",
        status: "success",
      });
    }
  };

  return (
    <SettingsPageContainer
      heading="Text message templates"
      subHeading="Customize the templates below to quickly send texts from the Interview Assistant. Templates can always be edited before sending a text."
    >
      <SettingsNote mb="7" mt="4">
        Note: texts will be sent from your callback number. If a candidate calls
        your callback number, it will route to your registered phone and be
        captured in BrightHire.
      </SettingsNote>

      {fetchTemplatesError && (
        <Alert status="error" description="Error loading templates" mb="6" />
      )}
      {fetchTemplatesLoading ? (
        <LoadingIndicator />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextMessageTemplate
            title="Call back text"
            name="callBack"
            value={watch("callBack")}
            instructions={[
              "Use this template to send a text from the Interview Assistant if the recipient doesn't answer your call.",
            ]}
            register={register}
            setValue={setValue}
            isLoading={updateLoading}
            error={errors.callBack?.message}
          />
          {textBeforeCallEnabled && (
            <>
              <Divider my="5" />
              <TextMessageTemplate
                title="Pre-call text"
                name="preCall"
                value={watch("preCall")}
                instructions={[
                  "Use this template to send a text message to a candidate before the interview so they expect your call.",
                ]}
                register={register}
                setValue={setValue}
                isLoading={updateLoading}
                error={errors.preCall?.message}
              />
            </>
          )}
          <Button type="submit" mt={4} isLoading={updateLoading}>
            Save
          </Button>
        </form>
      )}
    </SettingsPageContainer>
  );
};

export default TemplateSettings;
