import { InMemoryCache, Reference } from "@apollo/client/cache";
import {
  KeyArgsFunction,
  KeySpecifier,
} from "@apollo/client/cache/inmemory/policies"; // cspell:disable-line
import * as Sentry from "@sentry/browser";
import gql from "graphql-tag";

import {
  AiNotesProcessingStatus,
  CallAiSummaryProcessingStatus,
  LeverFeedbackTemplateFieldTypes,
} from "../../main/graphql";
import {
  formatPlanUserRolePermissions,
  formatPositionRolePermissions,
  formatUserRolePermissions,
  sentenceCase,
  snakeCaseToTitleCase,
} from "../string";

enum UserRolePermission {
  ManageOrganizationSettings = "MANAGE_ORGANIZATION_SETTINGS",
  ManageIntegrationSettings = "MANAGE_INTEGRATION_SETTINGS",
  ManageUserRoles = "MANAGE_USER_ROLES",
  ManageHiringTeam = "MANAGE_HIRING_TEAM",
  CreatePosition = "CREATE_POSITION",
  DeleteCall = "DELETE_CALL",
  DeleteCandidate = "DELETE_CANDIDATE",
  AccessAllPublicCalls = "ACCESS_ALL_PUBLIC_CALLS",
  AccessAllRestrictedCalls = "ACCESS_ALL_RESTRICTED_CALLS",
  AccessAllPrivateCalls = "ACCESS_ALL_PRIVATE_CALLS",
  CreateTrainings = "CREATE_TRAININGS",
  ManageAllTrainings = "MANAGE_ALL_TRAININGS",
  ManageIQReport = "MANAGE_INTERVIEWER_QUALITY_REPORT",
  ManageCustomTemplates = "MANAGE_CUSTOM_TEMPLATES",
}

export enum PositionRolePermission {
  AccessTeamPublicCalls = "ACCESS_TEAM_PUBLIC_CALLS",
  AccessTeamRestrictedCalls = "ACCESS_TEAM_RESTRICTED_CALLS",
  AccessTeamPrivateCalls = "ACCESS_TEAM_PRIVATE_CALLS",
  ManageHiringTeam = "MANAGE_HIRING_TEAM",
  CreateTrainings = "CREATE_TRAININGS",
}

enum PlanUserRolePermission {
  ManageOrganizationSettings = "MANAGE_ORGANIZATION_SETTINGS",
  ManageUserRoles = "MANAGE_USER_ROLES",
  CreateJobDescription = "CREATE_JOB_DESCRIPTION",
  ViewAllJobDescriptions = "VIEW_ALL_JOB_DESCRIPTIONS",
}

// This function provides generic cache merging of lists for offset/limit style
// pagination in our application. If you provide an offset in the graphql query
// it will merge the results appropriately into a longer list. This works for
// loadMore style pagination, but for page-based pagination you would not want
// to use this.
function loadMorePagination(
  keyArgs: false | KeySpecifier | KeyArgsFunction | undefined = false
): any {
  return {
    keyArgs,
    merge(existing: any, incoming: any, rest: any): any {
      const merged = existing?.results ? existing.results.slice(0) : [];
      const args = rest?.args;
      if (args) {
        let offset = 0;
        let limit = 0;
        if (args?.pagination?.page) {
          offset = parseInt(args.pagination.page);
        }
        if (args?.pagination?.limit) {
          limit = parseInt(args.pagination.limit);
        }
        const incList = incoming?.results || [];
        for (let i = 0; i < incList.length; ++i) {
          merged[offset * limit + i] = incList[i];
        }
        return {
          pageInfo: incoming.pageInfo,
          results: merged,
        };
      }
      return incoming;
    },
  };
}

export const isWsConnectedQuery = gql`
  {
    isWsConnected @client
  }
`;

const initialState = {
  isWsConnected: false,
};

const cache = new InMemoryCache({
  possibleTypes: {
    CallHighlight: ["AiNoteHighlight", "CallNoteHighlight"],
  },
  typePolicies: {
    PaginatedCandidateAlertFeed: {
      fields: {
        pageInfo: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        results: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    CallHighlight: {
      fields: {
        text: (s: string) => sentenceCase(s ?? ""),
      },
    },
    UpdateCallAISummaryFormat: {
      fields: {
        /** Re-fetch scorecard notes when the selected note type changes */
        summary: {
          merge: (_, incoming, { cache, variables = {} }) => {
            const { callId } = variables;
            if (callId) {
              cache.evict({
                id: cache.identify({ id: callId, __typename: "Call" }),
                fieldName: "aiScorecardQuestionNotes",
              });
              cache.gc();
            }
            return incoming;
          },
        },
      },
    },
    CallAiNotes: {
      fields: {
        /** Re-fetch scorecard notes when AI notes finish generating */
        aiNotesProcessingStatus: {
          merge: (existing, incoming, { cache, variables = {} }) => {
            const isCompleted = incoming === AiNotesProcessingStatus.Completed;
            const wasInCompleted =
              existing !== undefined &&
              existing !== AiNotesProcessingStatus.Completed;

            if (wasInCompleted && isCompleted) {
              const { callId } = variables;
              cache.evict({
                id: cache.identify({ id: callId, __typename: "Call" }),
                fieldName: "aiScorecardQuestionNotes",
              });
            }

            return incoming;
          },
        },
      },
    },
    CallAiSummary: {
      fields: {
        /** Re-fetch scorecard notes when AI summary notes finish generating */
        status: {
          merge: (existing, incoming, { cache, variables = {} }) => {
            const isCompleted =
              incoming === CallAiSummaryProcessingStatus.Completed;
            const wasInCompleted =
              existing !== undefined &&
              existing !== CallAiSummaryProcessingStatus.Completed;

            if (wasInCompleted && isCompleted) {
              const { callId } = variables;
              cache.evict({
                id: cache.identify({ id: callId, __typename: "Call" }),
                fieldName: "aiScorecardQuestionNotes",
              });
            }

            return incoming;
          },
        },
      },
    },
    Call: {
      fields: {
        notes: {
          merge: false,
        },
        greenhouseScorecardLinkWithId(_, { readField }) {
          try {
            const greenhouseScorecardLink = readField<string | undefined>(
              "greenhouseScorecardLink"
            );
            if (!greenhouseScorecardLink) return "";
            const id = readField<string | undefined>("id");
            const url = new URL(greenhouseScorecardLink);
            url.hash = "#scorecard";
            url.searchParams.append("brighthireInterviewId", id ?? "");
            // scorecard urls ending in /interview are re-written by the greenhouse frontend, which clears the param append above
            // re-rewrite the url ahead of time to avoid this issue
            if (url.pathname.endsWith("/interview")) {
              url.pathname = url.pathname.substring(
                0,
                url.pathname.lastIndexOf("/")
              );
            }
            if (url.pathname.startsWith("//")) {
              url.pathname = url.pathname.substring(1);
            }
            return url.href;
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                greenhouseScorecardLink: readField<string[] | undefined>(
                  "greenhouseScorecardLink"
                ),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        leverInterviewLinkWithId(_, { readField }) {
          try {
            const leverInterviewLink = readField<string | undefined>(
              "leverInterviewLink"
            );
            if (!leverInterviewLink) return "";
            const id = readField<string | undefined>("id");
            const url = new URL(leverInterviewLink);
            url.searchParams.append("brighthireInterviewId", id ?? "");
            return url.href;
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                leverInterviewLink: readField<string[] | undefined>(
                  "leverInterviewLink"
                ),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        /**
         * Gets `generalNotes` from `notes`. Will short-circuit
         * and just return `generalNotes` if already found in the cache
         */
        generalNotes(existing, { readField, args }) {
          if (existing) return existing;
          try {
            const callNotes = readField<Reference[]>({
              fieldName: "notes",
              args: args ?? undefined,
            });
            return (
              callNotes?.filter(
                (noteRef) => !readField("scorecardQuestionId", noteRef)
              ) ?? []
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                callId: readField("id"),
                field: "generalNotes",
              });
              Sentry.captureException(e);
            });
            return [];
          }
        },
        currentUserScorecard(_, { readField }) {
          try {
            const scorecards = readField<Reference[]>("scorecards");
            const currentUser = readField<Reference>("currentUser", {
              __ref: "ROOT_QUERY",
            });
            const currentUserId = readField("id", currentUser);
            return scorecards?.find(
              (scorecardRef) =>
                readField("id", readField("interviewer", scorecardRef)) ===
                currentUserId
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                callId: readField("id"),
                field: "currentUserScorecard",
              });
              Sentry.captureException(e);
            });
            return null;
          }
        },
        otherUsersScorecards(_, { readField }) {
          try {
            const scorecards = readField<Reference[]>("scorecards");
            const currentUser = readField<Reference>("currentUser", {
              __ref: "ROOT_QUERY",
            });
            const currentUserId = readField("id", currentUser);
            return scorecards?.filter(
              (scorecardRef) =>
                readField("id", readField("interviewer", scorecardRef)) !==
                currentUserId
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                callId: readField("id"),
                field: "otherUsersScorecards",
              });
              Sentry.captureException(e);
            });
            return null;
          }
        },
      },
    },
    CallNote: {
      fields: {
        isAtsSingleLineInput(_, { readField }): boolean {
          try {
            return (
              readField("leverFieldType") ===
              LeverFeedbackTemplateFieldTypes.Text
            );
          } catch {
            return false;
          }
        },
        canAutofill(_, { readField }): boolean {
          try {
            return [
              undefined,
              null,
              LeverFeedbackTemplateFieldTypes.Text,
              LeverFeedbackTemplateFieldTypes.Textarea,
            ].includes(readField("leverFieldType"));
          } catch {
            return true;
          }
        },
      },
    },
    UserRole: {
      fields: {
        formattedName(_, { readField }) {
          try {
            const name = readField<string | undefined>("name");
            if (name == null) return "";
            return snakeCaseToTitleCase(name);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                name: readField<string[] | undefined>("name"),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        formattedPermissions(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return "";
            return formatUserRolePermissions(permissions);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        canManageIntegrationSettings(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.ManageIntegrationSettings
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageOrganizationSettings(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.ManageOrganizationSettings
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageUserRoles(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.ManageUserRoles);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageHiringTeam(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.ManageHiringTeam);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canDeleteCandidates(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.DeleteCandidate);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canCreatePosition(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.CreatePosition);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canDeleteCall(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.DeleteCall);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canAccessPublicCalls(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.AccessAllPublicCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canAccessRestrictedCalls(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.AccessAllRestrictedCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canAccessPrivateCalls(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.AccessAllPrivateCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canCreateTrainings(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.CreateTrainings);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageAllTrainings(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.ManageAllTrainings);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canViewInsights(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.AccessAllPublicCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canViewInsightsAlerts(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.AccessAllPublicCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageIQReport(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(UserRolePermission.ManageIQReport);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageCustomTemplates(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              UserRolePermission.ManageCustomTemplates
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
      },
    },
    PositionRole: {
      fields: {
        formattedName(_, { readField }) {
          try {
            const name = readField<string | undefined>("name");
            if (name == null) return "";
            if (name === "MEMBER") {
              return "Team Member";
            }
            return snakeCaseToTitleCase(name);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                name: readField<string[] | undefined>("name"),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        formattedPermissions(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return "";
            return formatPositionRolePermissions(permissions);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        canAccessTeamPublicCalls(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              PositionRolePermission.AccessTeamPublicCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canAccessTeamRestrictedCalls(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              PositionRolePermission.AccessTeamRestrictedCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canAccessTeamPrivateCalls(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              PositionRolePermission.AccessTeamPrivateCalls
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageHiringTeam(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              PositionRolePermission.ManageHiringTeam
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canCreateTrainings(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(PositionRolePermission.CreateTrainings);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canViewInsights(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              PositionRolePermission.ManageHiringTeam
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
      },
    },
    PlanUserRole: {
      fields: {
        formattedName(_, { readField }) {
          try {
            const name = readField<string | undefined>("name");
            if (name == null) return "";
            return snakeCaseToTitleCase(name);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                name: readField<string[] | undefined>("name"),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        formattedPermissions(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return "";
            return formatPlanUserRolePermissions(permissions);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return "";
          }
        },
        canManageOrganizationSettings(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(
              PlanUserRolePermission.ManageOrganizationSettings
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
        canManageUserRoles(_, { readField }) {
          try {
            const permissions = readField<string[] | undefined>("permissions");
            if (permissions == null) return false;
            return permissions.includes(PlanUserRolePermission.ManageUserRoles);
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                permissions: readField<string[] | undefined>("permissions"),
              });
              Sentry.captureException(e);
            });
            return false;
          }
        },
      },
    },
    // same as NotesScorecardItem, but restricted version for call notes
    //  any changes to either should be done in both
    ScorecardItem: {
      fields: {
        isAtsSingleLineInput(_, { readField }): boolean {
          try {
            return (
              readField("leverFieldType") ===
              LeverFeedbackTemplateFieldTypes.Text
            );
          } catch {
            return false;
          }
        },
        questionNotes(_, { readField, variables, toReference, args }) {
          const callId = variables?.callId ?? variables?.id;
          if (!callId) return [];

          const scorecardItemId = readField("id");
          try {
            const callNotes = readField<Reference[]>({
              fieldName: "notes",
              from: toReference({ __typename: "Call", id: callId }),
              args: args ?? undefined,
            });

            return (
              callNotes?.filter(
                (noteRef) =>
                  scorecardItemId === readField("scorecardQuestionId", noteRef)
              ) ?? []
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                scorecardItemId,
                field: "questionNotes",
              });
              Sentry.captureException(e);
            });
            return [];
          }
        },
      },
    },
    // same as ScorecardItem, but restricted version for call notes
    //  any changes to either should be done in both
    NotesScorecardItem: {
      fields: {
        questionNotes(_, { readField, variables, toReference, args }) {
          const callId = variables?.id;
          if (!callId) return [];

          const scorecardItemId = readField("id");
          try {
            const callNotes = readField<Reference[]>({
              fieldName: "notes",
              from: toReference({ __typename: "Call", id: callId }),
              args: args ?? undefined,
            });

            return (
              callNotes?.filter(
                (noteRef) =>
                  scorecardItemId === readField("scorecardQuestionId", noteRef)
              ) ?? []
            );
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setExtras({
                scorecardItemId,
                field: "questionNotes",
              });
              Sentry.captureException(e);
            });
            return [];
          }
        },
      },
    },
    UserOnboardingStep: {
      keyFields: ["step"],
    },
    User: {
      fields: {
        memberships: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        numUnreadAlerts: (existing, { readField }) => {
          const enabledFeatures = new Set(
            readField<string[]>("enabledFeatures") ?? []
          );
          if (
            enabledFeatures.has("candidate:alert") ||
            enabledFeatures.has("candidate:alert:for_orgs")
          ) {
            return existing;
          }
          return 0;
        },
        scheduledInterviews: loadMorePagination(),
        calls: loadMorePagination(),
        callsSharedWithMe: loadMorePagination(["pagination", ["orderBy"]]),
        clipsSharedWithMe: loadMorePagination(["pagination", ["orderBy"]]),
        playlistsSharedWithMe: loadMorePagination(["pagination", ["orderBy"]]),
      },
    },
    Position: {
      fields: {
        memberships: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    ScheduledInterview: {
      fields: {
        scheduledInterviewers: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    CandidateEmailOptOut: {
      keyFields: ["verificationCode"],
    },
    Candidate: {
      fields: {
        scheduledInterviews: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    PaginatedActivityFeed: {
      queryType: true,
      fields: {
        pageInfo: {
          keyArgs: [],
          merge(existing, incoming) {
            return incoming;
          },
        },
        results: {
          keyArgs: [],
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Clip: {
      fields: {
        notes: {
          merge: false,
        },
        greenhouseScorecardLinkWithId() {
          return "";
        },
        leverInterviewLinkWithId() {
          return "";
        },
        skipRedaction() {
          return false;
        },
        rating() {
          return 0;
        },
      },
    },
    TrainingProgram: {
      fields: {
        trainingProgramItems: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        trainingProgramQuestions: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    CallGuide: {
      fields: {
        assignedUsers: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    PlanInterview: {
      fields: {
        questionGroups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    JobDescriptionTemplate: {
      fields: {
        sections: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

cache.writeQuery({ query: isWsConnectedQuery, data: initialState });

export default cache;
