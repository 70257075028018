import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Input,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import {
  CompetencyRatingDescriptions,
  RatingDescriptions,
} from "../../main/graphql";
import { Button } from "../Buttons";
import { Link } from "../Link";
import SettingsPageContainer from "./SettingsPageContainer";

type ScoringSettingsBaseProps = {
  /** Initial value for if scoring is enabled */
  defaultScoringEnabled: boolean;
  /** Initial value for whether to enable guide overall scoring by default */
  defaultGuideOverallScoringEnabled: boolean;
  /** Initial value for whether to enable competency overall scoring by default */
  defaultCompetencyScoringEnabled: boolean;
  /** Receives selected values when user hits "Save" */
  onSave(values: {
    scoringEnabled: boolean;
    guideOverallScoringEnabledByDefault: boolean;
    guideCompetencyScoringEnabledByDefault: boolean;
    interviewRatingDescriptions: RatingDescriptions;
    competencyStarRatingDescriptions: CompetencyRatingDescriptions;
  }): void;
  /** Whether saving is disabled */
  disabled: boolean;
  competencyRatingDescriptions: CompetencyRatingDescriptions;
  ratingDescriptions: RatingDescriptions;
};

/** Presentational component for an org's scoring settings */
export const ScoringSettingsBase: React.FC<ScoringSettingsBaseProps> = ({
  defaultScoringEnabled,
  defaultGuideOverallScoringEnabled,
  defaultCompetencyScoringEnabled,
  disabled,
  onSave,
  competencyRatingDescriptions,
  ratingDescriptions,
}) => {
  const [scoringEnabled, setScoringEnabled] = useState(defaultScoringEnabled);
  const [interviewRatingDescriptions, setInterviewRatingDescriptions] =
    useState(ratingDescriptions);
  const [
    competencyStarRatingDescriptions,
    setCompetencyStarRatingDescriptions,
  ] = useState(competencyRatingDescriptions);
  const {
    oneStar: oneStarRating,
    twoStar: twoStarRating,
    threeStar: threeStarRating,
    fourStar: fourStarRating,
    fiveStar: fiveStarRating,
  } = interviewRatingDescriptions;
  const {
    oneStar: oneStarCompetencyRating,
    twoStar: twoStarCompetencyRating,
    threeStar: threeStarCompetencyRating,
    fourStar: fourStarCompetencyRating,
    fiveStar: fiveStarCompetencyRating,
  } = competencyStarRatingDescriptions;
  const [
    isGuideOverallScoringEnabledByDefault,
    setIsGuideOverallScoringEnabledByDefault,
  ] = useState(defaultGuideOverallScoringEnabled);
  const [
    isGuideCompetencyScoringEnabledByDefault,
    setIsGuideCompetencyScoringEnabledByDefault,
  ] = useState(defaultCompetencyScoringEnabled);

  const body = (
    <Flex direction="column" alignItems="baseline">
      <Flex justifyContent="center" mb={5}>
        <Switch
          alignSelf="center"
          defaultChecked={defaultScoringEnabled}
          onChange={() => setScoringEnabled(!scoringEnabled)}
        />
        <Flex ml={3} fontWeight="400" color="gray.900" fontSize="md">
          Enable Scoring
        </Flex>
      </Flex>
      <Divider />
      <Heading mt="5" mb="3" as="h3" size="sm" fontWeight="600">
        Scoring preferences
      </Heading>
      <VStack align="flex-start">
        <Checkbox
          isChecked={isGuideCompetencyScoringEnabledByDefault}
          disabled={!scoringEnabled}
          onChange={() =>
            setIsGuideCompetencyScoringEnabledByDefault(
              !isGuideCompetencyScoringEnabledByDefault
            )
          }
        >
          <Text fontWeight="400" fontSize="sm" color="gray.900">
            All new guides enable interview scoring by default
          </Text>
        </Checkbox>
        <Checkbox
          isChecked={isGuideOverallScoringEnabledByDefault}
          disabled={!scoringEnabled}
          onChange={() =>
            setIsGuideOverallScoringEnabledByDefault(
              !isGuideOverallScoringEnabledByDefault
            )
          }
        >
          <Text fontWeight="400" fontSize="sm" color="gray.900">
            All new competencies are scored by default
          </Text>
        </Checkbox>
      </VStack>
      <Divider mt="5" />
      <Flex width="100%" gap="20" mt="5" mb="3">
        <Box hidden={!scoringEnabled} minWidth="250px">
          <Text mb="3" as="h3" size="sm" fontWeight="600">
            Interview rating description
          </Text>
          <Text fontSize="sm" mb="1">
            One Star
          </Text>
          <Input
            value={oneStarRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setInterviewRatingDescriptions((interviewRatingDescriptions) => {
                return {
                  ...interviewRatingDescriptions,
                  oneStar: e.target.value,
                };
              })
            }
          />
          <Text fontSize="sm" mb="1">
            Two Star
          </Text>
          <Input
            value={twoStarRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setInterviewRatingDescriptions((interviewRatingDescriptions) => {
                return {
                  ...interviewRatingDescriptions,
                  twoStar: e.target.value,
                };
              })
            }
          />
          <Text fontSize="sm" mb="1">
            Three Star
          </Text>
          <Input
            value={threeStarRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setInterviewRatingDescriptions((interviewRatingDescriptions) => {
                return {
                  ...interviewRatingDescriptions,
                  threeStar: e.target.value,
                };
              })
            }
          />
          <Text fontSize="sm" mb="1">
            Four Star
          </Text>
          <Input
            value={fourStarRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setInterviewRatingDescriptions((interviewRatingDescriptions) => {
                return {
                  ...interviewRatingDescriptions,
                  fourStar: e.target.value,
                };
              })
            }
          />
          <Text fontSize="sm" mb="1">
            Five Star
          </Text>
          <Input
            value={fiveStarRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setInterviewRatingDescriptions((interviewRatingDescriptions) => {
                return {
                  ...interviewRatingDescriptions,
                  fiveStar: e.target.value,
                };
              })
            }
          />
        </Box>
        <Box hidden={!scoringEnabled} minWidth="250px">
          <Text mb="3" as="h3" size="sm" fontWeight="600">
            Competency rating description
          </Text>
          <Text fontSize="sm" mb="1">
            One Star
          </Text>
          <Input
            value={oneStarCompetencyRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setCompetencyStarRatingDescriptions(
                (competencyRatingDescriptions) => {
                  return {
                    ...competencyRatingDescriptions,
                    oneStar: e.target.value,
                  };
                }
              )
            }
          />
          <Text fontSize="sm" mb="1">
            Two Star
          </Text>
          <Input
            value={twoStarCompetencyRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setCompetencyStarRatingDescriptions(
                (competencyRatingDescriptions) => {
                  return {
                    ...competencyRatingDescriptions,
                    twoStar: e.target.value,
                  };
                }
              )
            }
          />
          <Text fontSize="sm" mb="1">
            Three Star
          </Text>
          <Input
            value={threeStarCompetencyRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setCompetencyStarRatingDescriptions(
                (competencyRatingDescriptions) => {
                  return {
                    ...competencyRatingDescriptions,
                    threeStar: e.target.value,
                  };
                }
              )
            }
          />
          <Text fontSize="sm" mb="1">
            Four Star
          </Text>
          <Input
            value={fourStarCompetencyRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setCompetencyStarRatingDescriptions(
                (competencyRatingDescriptions) => {
                  return {
                    ...competencyRatingDescriptions,
                    fourStar: e.target.value,
                  };
                }
              )
            }
          />
          <Text fontSize="sm" mb="1">
            Five Star
          </Text>
          <Input
            value={fiveStarCompetencyRating}
            maxLength={35}
            mb="3"
            onChange={(e) =>
              setCompetencyStarRatingDescriptions(
                (competencyRatingDescriptions) => {
                  return {
                    ...competencyRatingDescriptions,
                    fiveStar: e.target.value,
                  };
                }
              )
            }
          />
        </Box>
      </Flex>
      <Button
        type="submit"
        mt={5}
        fontWeight="500"
        isLoading={disabled}
        isDisabled={disabled}
        onClick={() =>
          onSave({
            scoringEnabled,
            guideCompetencyScoringEnabledByDefault:
              isGuideCompetencyScoringEnabledByDefault,
            guideOverallScoringEnabledByDefault:
              isGuideOverallScoringEnabledByDefault,
            interviewRatingDescriptions,
            competencyStarRatingDescriptions,
          })
        }
      >
        Save
      </Button>
    </Flex>
  );

  return (
    <SettingsPageContainer
      heading="Scoring"
      subHeading={
        <>
          Enabling scoring gives you the option to create scored competencies
          within interview guides. This{" "}
          <Link
            href="https://help.brighthire.ai/en/articles/8598584-interview-scoring"
            target="_blank"
          >
            help article
          </Link>{" "}
          gives an overview of how scoring works in BrightHire.
        </>
      }
    >
      {body}
    </SettingsPageContainer>
  );
};
