import {
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import Alert from "../Alert";
import { ButtonWithConfirmDialogue } from "../ButtonWithConfirmDialogue";
import FileUploadInput from "../FileUploadInput/FileUploadInput";
import NotetakerDisplayImageGenerator from "./NotetakerDisplayImageGenerator";
import NotetakerDisplayImagePreview from "./NotetakerDisplayImagePreview";

interface NotetakerDisplayImageSelectorProps {
  previewImageSrc: string;
  onImageGenerated: (imageBase64: string) => void;
  onImageReset: () => void;
  onResetToDefault: () => void;
  showResetToDefault: boolean;
  showFilenameInput?: boolean;
  onFilenameChange?: (filename: string) => void;
}

const NotetakerDisplayImageSelector: React.FC<
  NotetakerDisplayImageSelectorProps
> = ({
  previewImageSrc,
  showResetToDefault,
  showFilenameInput = false,
  onImageGenerated,
  onImageReset,
  onResetToDefault,
  onFilenameChange,
}) => {
  // if previewImageSrc is in bucket/file format, extract file
  const previewImageSrcWithoutBucket = previewImageSrc?.includes("/")
    ? previewImageSrc.split("/").pop() || previewImageSrc
    : previewImageSrc;

  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const [filename, setFilename] = React.useState<string>(
    previewImageSrcWithoutBucket
  );

  const hasInvalidExtension = !!(filename && !filename.match(/\.(jpg|jpeg)$/i));

  useEffect(() => {
    setUploadedFile(null);
    setFilename(previewImageSrcWithoutBucket);
  }, [previewImageSrc]);

  return (
    <>
      <Divider mb={6} />
      <Heading as="h4" fontSize="md" mb={6}>
        Notetaker display image
      </Heading>
      {uploadedFile && (
        <Alert
          status="warning"
          mb={4}
          description="You have uploaded a new image. Please save the form to apply the changes."
        />
      )}
      <FormControl id="displayImage" mb={8}>
        {showFilenameInput && (
          <FormControl
            id="displayImageFilename"
            mb={4}
            isInvalid={hasInvalidExtension}
          >
            <FormLabel>Display Image Filename</FormLabel>
            <Input
              value={filename}
              onChange={(e) => {
                const newFilename = e.target.value;
                setFilename(newFilename);
                setUploadedFile(null);
                onFilenameChange?.(newFilename);
              }}
              placeholder="white-label-image.jpg"
            />
            {hasInvalidExtension && (
              <FormErrorMessage>
                Filename must end in .jpg or .jpeg
              </FormErrorMessage>
            )}
            <FormHelperText>
              .jpg file with dimensions 1280x720 uploaded to GCP bucket
              `notetaker_display_images`. Use this only if you know the exact
              filename in the bucket and you are not going to use the file
              selector below.
            </FormHelperText>
          </FormControl>
        )}
        <FormHelperText mb={4}>
          To customize your notetaker image, please drag and drop a logo with a
          transparent background.
        </FormHelperText>
        <FileUploadInput
          onUpload={(fileList) => {
            const file = fileList[0];
            setUploadedFile(file);
          }}
          allowedFormats={{ "image/png": [".png"] }}
        />
        {uploadedFile ? (
          <NotetakerDisplayImageGenerator
            file={uploadedFile}
            onImageGenerated={onImageGenerated}
            onClear={() => {
              onImageReset();
              setUploadedFile(null);
            }}
          />
        ) : (
          <NotetakerDisplayImagePreview
            imageFilename={
              filename && filename !== previewImageSrcWithoutBucket
                ? filename
                : previewImageSrc
            }
          />
        )}
        <Flex mt={4} gap={4}>
          {showResetToDefault && (
            <ButtonWithConfirmDialogue
              headerText="Reset to BrightHire logo"
              headerBody="Are you sure you want to reset your display image? The default display image with the BrightHire logo will be used."
              onConfirm={() => {
                onResetToDefault();
                setUploadedFile(null);
              }}
              buttonProps={{
                variant: "danger",
                size: "sm",
                ml: 2,
                marginLeft: 0,
              }}
            >
              Reset to BrightHire logo
            </ButtonWithConfirmDialogue>
          )}
        </Flex>
      </FormControl>
    </>
  );
};

export default NotetakerDisplayImageSelector;
