import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";

import { useTheme } from "../../../../components";
import {
  CallNoteFragment,
  CallNoteType,
  CallQuestionFragment,
} from "../../../graphql";
import { ClipRange } from "../../Interview/Clip/types";
import CallNotesSection from "./CallNotesSection";
import CallQuestionNotesHeaderMenu from "./CallQuestionNotesHeaderMenu";
import CallQuestionNoteTitle from "./CallQuestionNoteTitle";

export interface CallQuestionNotesSectionProps {
  callId: string;
  clipId?: string;
  callDuration: number;
  interviewerIds: string[];
  question: CallQuestionFragment;
  playerTime: number;
  onAddNote: (opts: {
    text: string;
    type: CallNoteType;
    time: number;
    questionId: string;
  }) => Promise<void>;
  onClickTimestamp?: (time: number) => void;
  currentNoteId?: string;
  notesReadOnly?: boolean;
  setClipRange?(clipRange: ClipRange): void;
}

const CallQuestionNotesSection: React.FC<CallQuestionNotesSectionProps> = ({
  callId,
  clipId,
  callDuration,
  interviewerIds,
  question,
  playerTime,
  onAddNote,
  onClickTimestamp,
  notesReadOnly,
  currentNoteId,
  setClipRange,
}) => {
  const { colors } = useTheme();
  const [isBeingTimestamped, setIsBeingTimestamped] = useState(false);

  const notes = question.questionNotes;
  const hasNotes = notes.length > 0;

  const timestampNote = useTimestampNote(notes);

  const {
    isOpen: isHoveringTitle,
    onOpen: startTitleHover,
    onClose: stopTitleHover,
  } = useDisclosure({
    isOpen: isMobile ? false : undefined,
  });

  const timestampQuestion = async (): Promise<void> => {
    if (isBeingTimestamped) return;
    setIsBeingTimestamped(true);
    await onAddNote({
      text: question.description ?? "",
      type: CallNoteType.Cue,
      time: playerTime,
      questionId: question.id,
    });
    setIsBeingTimestamped(false);
  };

  return (
    <>
      {isHoveringTitle && !notesReadOnly && timestampNote && (
        <CallQuestionNotesHeaderMenu
          callId={callId}
          question={question}
          timestampNote={timestampNote}
          callDuration={callDuration}
          setClipRange={setClipRange}
          onMouseEnter={startTitleHover}
          onMouseLeave={stopTitleHover}
        />
      )}

      <CallQuestionNoteTitle
        title={question.description ?? ""}
        onClickTimestamp={onClickTimestamp}
        timestampNote={timestampNote}
        showLoading={isBeingTimestamped}
        bg={
          hasNotes
            ? colors.blue[50]
            : isHoveringTitle
            ? colors.gray[100]
            : colors.gray[50]
        }
        onMouseEnter={startTitleHover}
        onMouseLeave={stopTitleHover}
        onClick={() => {
          if (!timestampNote) {
            timestampQuestion();
          }
        }}
        cursor="pointer"
        borderRadius="sm"
        mb="3"
      />

      {hasNotes && (
        <CallNotesSection
          mb="1"
          callId={callId}
          clipId={clipId}
          callDuration={callDuration}
          interviewerIds={interviewerIds}
          notes={notes}
          onAddNote={(note) => onAddNote({ ...note, questionId: question.id })}
          onClickTimestamp={onClickTimestamp}
          setClipRange={setClipRange}
          playerTime={playerTime}
          currentNoteId={currentNoteId}
          notesReadOnly={notesReadOnly}
        />
      )}
    </>
  );
};

export default CallQuestionNotesSection;

function useTimestampNote<T extends Pick<CallNoteFragment, "type" | "score">>(
  notes: T[]
): T | undefined {
  const cueNotes = notes.filter((n) => n.type === CallNoteType.Cue);

  const autoHighlightNotes = notes
    .filter((n) => n.type === CallNoteType.HighlightV2)
    .sort((a, b) => (b.score || 0) - (a.score || 0));

  const timestampNote = cueNotes.length
    ? cueNotes[0]
    : autoHighlightNotes.length
    ? autoHighlightNotes[0]
    : undefined;

  return timestampNote;
}
