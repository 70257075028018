// Feature flagged over tab shellimport {

import { Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { LoadingIndicator, useToast } from "../../../../components";
import GeneralErrorIcon from "../../../../plan/pages/images/GeneralErrorIcon";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateSummaryProcessingStatus,
  useAutoGenerateCandidateSummaryIfMissingMutation,
  useCandidateSummaryLazyQuery,
  useGenerateCandidateSummaryMutation,
} from "../../../graphql";
import SummaryTabContentV2 from "./SummaryTabContentV2";
import useCandidateSummaryTemplates from "./useCandidateSummaryTemplates";

type SummaryTabV2Props = {
  candidateId: string;
  positionId?: string;
  notesExpanded: boolean;
  toggleNotes: () => void;
  innerExpandedWidth: string;
  numCalls: number;
};

/**
 * Wrapper for V2 candidate summary tab.
 */
const SummaryTabV2: React.FC<SummaryTabV2Props> = ({
  candidateId,
  positionId,
  notesExpanded,
  toggleNotes,
  innerExpandedWidth,
  numCalls,
}) => {
  const toast = useToast();
  const [autoOpen, setAutoOpen] = useState(false);

  const {
    templates,
    templatesLoading,
    selectedTemplateId,
    selectedTemplateReadyToUse,
    selectedTemplateInProgress,
    handleChangeTemplate,
  } = useCandidateSummaryTemplates(candidateId, positionId);

  const [autoGenerateCandidateSummaryIfMissing] =
    useAutoGenerateCandidateSummaryIfMissingMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: "An error occurred while generating the summary.",
          status: "error",
        });
      },
    });

  const [fetchSummary, aiCandidateSummary] = useCandidateSummaryLazyQuery();

  // original fetch with polling
  useEffect(() => {
    if (!selectedTemplateId) {
      return;
    }
    fetchSummary({
      variables: {
        candidateId,
        positionId,
        templateId: selectedTemplateId,
      },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  }, [candidateId, positionId, selectedTemplateId]);

  // effect check to turn off polling
  // check should only run when loading is done
  const aiSummaryStatus = aiCandidateSummary.data?.candidateSummary?.status;
  const aiSummaryLoading = aiCandidateSummary.loading;

  // Skip autogeneration if already attempted on this template.
  const autogenerateAlreadyAttempted = useRef(new Set());

  // Hook: try to auto-generate summary if missing, once the summary query is loaded
  useEffect(() => {
    if (selectedTemplateId === "") {
      return;
    }
    // Template must have successfully generationed
    if (!selectedTemplateReadyToUse) {
      return;
    }
    // No duplicate generations
    if (autogenerateAlreadyAttempted.current.has(selectedTemplateId)) {
      return;
    }
    const queryComplete = !aiSummaryLoading && aiCandidateSummary.data;
    const noSummary = !aiCandidateSummary.data?.candidateSummary;
    if (queryComplete && noSummary) {
      autogenerateAlreadyAttempted.current.add(selectedTemplateId);
      autoGenerateCandidateSummaryIfMissing({
        variables: {
          candidateId,
          positionId,
          templateId: selectedTemplateId,
        },
        onCompleted: (data) => {
          if (data.autoGenerateCandidateSummaryIfMissing?.created) {
            aiCandidateSummary.refetch();
          }
        },
      });
    }
  }, [
    aiSummaryLoading,
    aiCandidateSummary.data,
    aiCandidateSummary.data?.candidateSummary,
    selectedTemplateReadyToUse,
  ]);

  useEffect(() => {
    if (aiSummaryLoading) {
      // Keep polling while loading
      return;
    }

    // Stop polling when summary is completed or failed
    if (
      aiSummaryStatus &&
      [
        CandidateSummaryProcessingStatus.Completed,
        CandidateSummaryProcessingStatus.Failed,
      ].includes(aiSummaryStatus)
    ) {
      aiCandidateSummary.stopPolling();
    }
  }, [aiSummaryStatus, aiSummaryLoading]);

  // effect to control auto-open when summary finishes processing
  useEffect(() => {
    if (!aiSummaryLoading) {
      if (aiSummaryStatus === CandidateSummaryProcessingStatus.InProgress) {
        setAutoOpen(true);
      }
      if (
        aiSummaryStatus === CandidateSummaryProcessingStatus.Completed &&
        autoOpen &&
        !notesExpanded
      ) {
        toggleNotes();
        setAutoOpen(false);
      }
    }
  }, [
    aiSummaryStatus,
    aiSummaryLoading,
    toggleNotes,
    autoOpen,
    setAutoOpen,
    notesExpanded,
  ]);

  const [generateCandidateSummary] = useGenerateCandidateSummaryMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });
  const sendGAEvent = useSendGAEvent();
  const generateSummary = (templateId: string): void => {
    sendGAEvent("candidate_summary_generate", "candidate");
    generateCandidateSummary({
      variables: {
        templateId,
        positionId,
        candidateId,
      },
    }).then(() => {
      fetchSummary({
        variables: {
          candidateId,
          positionId,
          templateId,
        },
        pollInterval: 5000,
        fetchPolicy: "network-only",
      });
    });
  };

  if (
    aiCandidateSummary.loading ||
    templatesLoading ||
    (!aiCandidateSummary.data && !aiCandidateSummary.error) // not fetched yet
  ) {
    return <LoadingIndicator />;
  }
  if (aiCandidateSummary.error) {
    return (
      <>
        <GeneralErrorIcon
          mb={4}
          mt={4}
          display="flex"
          justifyContent="center"
        />
        <Text
          fontSize="16px"
          mt="2"
          fontWeight={600}
          color="gray.200"
          textAlign="center"
        >
          There was an error loading this summary. Contact support for more
          info.
        </Text>
      </>
    );
  }

  return (
    <SummaryTabContentV2
      data={aiCandidateSummary.data?.candidateSummary}
      dataLoading={aiCandidateSummary.loading}
      templates={templates}
      generateSummary={generateSummary}
      selectedTemplateId={selectedTemplateId}
      handleChangeTemplate={handleChangeTemplate}
      candidateId={candidateId}
      notesExpanded={notesExpanded}
      toggleNotes={toggleNotes}
      innerExpandedWidth={innerExpandedWidth}
      positionId={positionId || null}
      numCalls={numCalls}
      selectedTemplateInProgress={selectedTemplateInProgress}
    />
  );
};

export default SummaryTabV2;
