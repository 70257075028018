import { ExampleTemplate } from "../types";

export const hiringManagerSummary: ExampleTemplate = {
  name: "General Interview Summary",
  description: "Summary of a post-screen call, for the hiring manager to read.",
  prompt: `Summarize this  job interview transcript. Organize by topic with h2 headers and one or more bullets per topic. Each of the bullets in each topic has a citation link. 

Write a chronological summary of each topic discussed in this interview. When possible, prefer using a paraphrased version of the interviewer's question as the topic header.

Focus on summarizing the candidate's answers, not the interviewers.

Do not insert your own judgement or opinions. Only summarize the information provided during the intake, and ensure to accurately represent the expectations laid out by the participants.`,
  output: `
## Introductions
- The candidate introduced themselves as being located in Dallas, Texas, with experience in HR tech and implementation roles. They mentioned being laid off from a previous employer, a well-known HR technology company, and later joining another company before reconnecting with the interviewer about this opportunity [4:00](?t=240).

## Handling Resistance from Stakeholders During Implementation
- The candidate shared an example of working with a client where the HRIS director was not a buyer or advocate of the product. They described how they addressed skepticism by understanding the director's concerns, demonstrating the product's benefits, and building relationships with other stakeholders to gain support [5:42](?t=342).
- They emphasized the importance of process discovery and showing measurable improvements, such as reducing time-to-hire metrics, to build trust and overcome resistance [7:41](?t=461).

## Supporting a Client Champion Facing Internal Friction
- The candidate discussed a scenario involving scheduling issues for hourly workers without email addresses. They described how they managed resistance from a scheduling coordinator by conducting onsite meetings, addressing concerns, and making minor adjustments to the process [11:38](?t=698).
- They highlighted the importance of listening to stakeholders, uncovering hidden issues, and balancing changes to meet both organizational and individual needs [13:46](?t=826).

## Navigating Change Management and Process Discovery
- The candidate explained their approach to guiding clients through fragmented workflows and change management. They emphasized being candid about trade-offs, ensuring scalability, and aligning processes with long-term goals [15:30](?t=930).
- They described how they would address situations where process changes create friction for end users, focusing on the overall workflow benefits and improved candidate experience [18:01](?t=1081).

## Questions About the Role and Team Structure
- The candidate asked about the responsibilities and client ownership for the Implementation Manager role. The interviewer explained the collaborative nature of the team and the evolving processes for implementation and Customer Success [21:30](?t=1290).
- They inquired about project management tools and processes, learning that the team uses Doc for client-facing work and Notion for internal projects, with flexibility to adapt to client-preferred systems [24:50](?t=1490).
- The candidate also asked about handling large enterprise clients and whether project management responsibilities would be split. The interviewer noted that the team is small and such decisions would depend on future needs [27:17](?t=1637).

## Post-Implementation Handoff
- The candidate asked about the handoff process after implementation. The interviewer described a collaborative approach where Customer Success takes over strategic touchpoints and ensures adoption, with the transition depending on the implementation's complexity and duration [29:19](?t=1759).`,
};
