import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";

import { Button, SettingsPageContainer, useToast } from "../../../components";
import { useIcimsSetupQuery, useSetupIcimsMutation } from "../../graphql";

interface FormValues {
  enabled: boolean;
  customerId?: string;
  clientId?: string;
  clientSecret?: string;
  tokenUrl?: string;
}

const IcimsSettings: React.FC = () => {
  const toast = useToast();
  const { data, loading } = useIcimsSetupQuery();
  const [setupIcims, { loading: setupIcimsLoading }] = useSetupIcimsMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to setup Icims: ${err.message}`,
        status: "error",
      });
    },
  });
  const { register, handleSubmit, setValue, watch } = useForm<FormValues>({});
  const watchValues = watch();

  const onSubmit = handleSubmit((formValues) => {
    setupIcims({
      variables: {
        enabled: formValues.enabled,
        customerId: formValues.customerId,
        clientId: formValues.clientId,
        clientSecret:
          formValues.clientSecret === "XXX"
            ? data?.icimsSetup?.clientSecret
            : formValues.clientSecret,
      },
    });
  });

  useEffect(() => {
    setValue("enabled", data?.icimsSetup?.enabled ?? false);
    setValue("customerId", data?.icimsSetup?.customerId || "");
    setValue("clientId", data?.icimsSetup?.clientId || "");
    setValue("clientSecret", data?.icimsSetup?.clientSecret ? "XXX" : "");
    setValue("tokenUrl", data?.icimsSetup?.tokenUrl || "");
  }, [data]);

  const isLoading = loading || setupIcimsLoading;

  return (
    <SettingsPageContainer heading="iCIMS">
      <Flex dir="column" my={8}>
        <Text fontWeight="bold" mr={2}>
          Integration Status:{" "}
        </Text>
        {(isLoading && <Spinner />) ||
          (data?.icimsSetup?.valid && (
            <Icon
              mt="2px"
              as={IoMdCheckmarkCircleOutline}
              color="green.400"
              height="5"
              width="5"
            />
          )) || (
            <Icon
              mt="2px"
              as={IoMdCloseCircleOutline}
              color="red.600"
              height="5"
              width="5"
            />
          )}
      </Flex>
      <form onSubmit={onSubmit}>
        <VStack spacing="8" align="start">
          <FormControl id="enabled">
            <Checkbox
              {...register("enabled")}
              isChecked={watchValues.enabled}
              onChange={(e) => {
                setValue("enabled", e.target.checked);
              }}
            >
              Enabled
            </Checkbox>
          </FormControl>
          <FormControl id="customerId" maxW="400">
            <FormLabel>Customer ID</FormLabel>
            <Input {...register("customerId")} />
          </FormControl>
          <FormControl id="clientId" maxW="400">
            <FormLabel>Client ID</FormLabel>
            <Input {...register("clientId")} />
          </FormControl>
          <FormControl id="clientSecret" maxW="400">
            <FormLabel>Client Secret</FormLabel>
            <Input {...register("clientSecret")} />
          </FormControl>
          <FormControl id="tokenUrl" maxW="400">
            <FormLabel>Access Token URL</FormLabel>
            <Input {...register("tokenUrl")} />
          </FormControl>
          <Button type="submit" disabled={isLoading}>
            Save
          </Button>
        </VStack>
      </form>
    </SettingsPageContainer>
  );
};

export default IcimsSettings;
