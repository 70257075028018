import { Box, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";
import { IoCopyOutline } from "react-icons/io5";

import { Button, IconButton } from "../../../components";
import DragHandleDots from "../../../components/Icons/DragHandleDots";
import Section from "./Section";
import { CustomTemplateSectionInput } from "./types";

type SectionFormProps = {
  sections: CustomTemplateSectionInput[];
  updateSection: (index: number, title: string, prompt: string) => void;
  onDeleteSection: (id: string) => void;
  onAddSection: () => void;
  handleMove: (fromIndex: number, toIdx: number) => void;
  copyTemplateText?: () => void;
  headerFontSize?: string;
};

const SectionsForm: React.FC<SectionFormProps> = ({
  sections,
  updateSection,
  onDeleteSection,
  onAddSection,
  handleMove,
  copyTemplateText,
  headerFontSize = "md",
}) => {
  return (
    <>
      <HStack>
        <Box>
          <Text
            fontWeight="600"
            fontSize={headerFontSize}
            color="gray.800"
            mb="1"
          >
            Sections
          </Text>
          <Text fontWeight="400" fontSize="sm" color="gray.700">
            Specify which sections you want to include in your AI Notes.
            Describe what you’d like the AI to summarize in each section.
          </Text>
        </Box>
        {copyTemplateText && (
          <Tooltip label="Copy template text">
            <IconButton
              data-tour-id="ai-note-copy-template-text"
              icon={<IoCopyOutline size={20} />}
              aria-label="Copy template text"
              size="sm"
              p="2"
              variant="ghost"
              flexShrink={0}
              onClick={() => {
                copyTemplateText();
              }}
            />
          </Tooltip>
        )}
      </HStack>
      <Box>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }
            handleMove(result.source.index, result.destination.index);
          }}
        >
          <Droppable droppableId="sectionDroppable">
            {(provided) => (
              // eslint-disable-next-line @typescript-eslint/unbound-method
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {sections?.map((child, index) => (
                  <Draggable
                    key={child.id}
                    draggableId={child.id}
                    index={index}
                    isDragDisabled={false}
                  >
                    {(provided) => (
                      <Flex
                        key={child.id}
                        alignItems="flex-start"
                        backgroundColor="gray.50"
                        pt={2}
                        pb={5}
                        pr={4}
                        borderRadius="8px"
                        mt={5}
                        // eslint-disable-next-line @typescript-eslint/unbound-method
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Flex
                          {...provided.dragHandleProps}
                          height={8}
                          width={8}
                          mr={1}
                          ml={2}
                          alignItems="center"
                          justifyContent="center"
                          data-testid={`drag-handle-${index}`}
                        >
                          <DragHandleDots width={5} height={5} />
                        </Flex>
                        <Section
                          title={child.title}
                          prompt={child.prompt[0] || ""}
                          onUpdate={(title, prompt) => {
                            updateSection(index, title, prompt);
                          }}
                        />
                        <Flex
                          height={8}
                          width={8}
                          mr={2}
                          alignItems="center"
                          justifyContent="center"
                          data-testid={`drag-handle-${index}`}
                        >
                          <IconButton
                            variant="iconDanger"
                            aria-label="Delete Section"
                            backgroundColor="transparent"
                            icon={<HiOutlineTrash size={20} />}
                            ml={6}
                            onClick={() => onDeleteSection(child.id)}
                          />
                        </Flex>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          data-testid="Add Section"
          mt={5}
          size="prismMdLight"
          variant="prismEmphasis"
          colorScheme="blue"
          leftIcon={<HiOutlinePlus />}
          onClick={onAddSection}
        >
          Add section
        </Button>
      </Box>
    </>
  );
};

export default SectionsForm;
