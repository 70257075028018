import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

import { EmptyVideoPlayer } from "../../../components";

const EMPTY_INTERVIEW_ICON = (
  <svg
    width="100%"
    height="167"
    viewBox="0 0 190 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M162.955 49.1361C166.689 50.1367 168.905 53.975 167.904 57.7093L141.767 155.257C140.654 159.41 136.365 161.869 132.186 160.749L8.46862 127.599C4.28981 126.479 1.80484 122.206 2.91768 118.052L29.0555 20.5048C30.056 16.7706 33.8944 14.5545 37.6287 15.5551L162.955 49.1361Z"
      fill="#DDF5FF"
    />
    <path
      d="M38.4486 15.711L162.166 48.861C166.345 49.9807 168.823 54.2797 167.702 58.4641L166.07 64.5546L27.2209 27.3501L28.8529 21.2596C29.9741 17.0752 34.2697 14.5913 38.4486 15.711Z"
      fill="#AEDEFF"
    />
    <path
      d="M39.0423 25.9073C40.3454 26.2564 41.6851 25.4802 42.0342 24.1772C42.3838 22.8724 41.6113 21.5322 40.3082 21.183C39.0069 20.8344 37.6659 21.6082 37.3163 22.9131C36.9672 24.216 37.7411 25.5586 39.0423 25.9073Z"
      fill="#EAF9FF"
    />
    <path
      d="M46.7064 27.9612C48.0076 28.3099 49.3491 27.5342 49.6983 26.2312C50.0479 24.9263 49.2735 23.5857 47.9722 23.237C46.6691 22.8878 45.33 23.6622 44.9804 24.967C44.6312 26.27 45.4033 27.612 46.7064 27.9612Z"
      fill="#EAF9FF"
    />
    <path
      d="M54.3744 30.0156C55.6774 30.3648 57.0171 29.5886 57.3662 28.2856C57.7159 26.9808 56.9433 25.6406 55.6402 25.2914C54.3371 24.9423 52.998 25.7166 52.6483 27.0215C52.2992 28.3244 53.0713 29.6665 54.3744 30.0156Z"
      fill="#EAF9FF"
    />
    <path
      d="M81.0234 34.5676L115.887 43.9093"
      stroke="#EAF9FF"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.8664 61.614L82.7128 69.0754C84.4824 69.5496 85.5379 71.3806 85.0626 73.1545C84.5878 74.9265 82.7582 75.9844 80.9886 75.5102L53.1422 68.0488C51.3706 67.5742 50.3152 65.7432 50.79 63.9712C51.2653 62.1972 53.0948 61.1393 54.8664 61.614Z"
      fill="#7FC6FE"
    />
    <path
      d="M51.3039 74.9136L67.5823 79.2754C69.3538 79.75 70.4093 81.581 69.9345 83.353C69.4592 85.127 67.6296 86.1849 65.8581 85.7102L49.5797 81.3484C47.8081 80.8737 46.7527 79.0428 47.228 77.2689C47.7028 75.4968 49.5323 74.4389 51.3039 74.9136Z"
      fill="#AEDEFF"
    />
    <path
      d="M78.2922 82.1454L83.432 83.5227C85.2036 83.9973 86.259 85.8283 85.7842 87.6004C85.3089 89.3743 83.4794 90.4322 81.7078 89.9575L76.568 88.5803C74.7964 88.1056 73.741 86.2746 74.2163 84.5007C74.6911 82.7287 76.5206 81.6708 78.2922 82.1454Z"
      fill="#7FC6FE"
    />
    <path
      d="M47.7375 88.2117L51.736 89.2831C53.5057 89.7572 54.5611 91.5882 54.0863 93.3603C53.611 95.1342 51.7814 96.1921 50.0118 95.7179L46.0133 94.6465C44.2417 94.1718 43.1863 92.3409 43.6616 90.567C44.1364 88.7949 45.9659 87.737 47.7375 88.2117Z"
      fill="#AEDEFF"
    />
    <path
      d="M62.4466 92.1516L86.4376 98.58C88.2091 99.0546 89.2646 100.886 88.7898 102.658C88.3144 104.432 86.4849 105.489 84.7134 105.015L60.7223 98.5864C58.9527 98.1122 57.8972 96.2813 58.3725 94.5074C58.8473 92.7353 60.6769 91.6774 62.4466 92.1516Z"
      fill="#7FC6FE"
    />
    <path
      d="M97.1516 101.451L117.715 106.961C119.484 107.435 120.54 109.266 120.065 111.038C119.59 112.812 117.76 113.87 115.991 113.396L95.4273 107.886C93.6558 107.411 92.6003 105.58 93.0756 103.806C93.5505 102.034 95.38 100.976 97.1516 101.451Z"
      fill="#EAF9FF"
    />
    <path
      d="M44.175 101.51L57.0274 104.953C58.7971 105.428 59.8526 107.258 59.3773 109.032C58.9025 110.804 57.0729 111.862 55.3032 111.388L42.4508 107.944C40.6792 107.47 39.6238 105.639 40.0986 103.867C40.5739 102.093 42.4034 101.035 44.175 101.51Z"
      fill="#7FC6FE"
    />
    <path
      d="M67.7395 107.822L70.5967 108.588C72.3663 109.062 73.4218 110.893 72.9465 112.667C72.4717 114.439 70.6421 115.497 68.8724 115.023L66.0153 114.257C64.2456 113.783 63.1902 111.952 63.665 110.18C64.1403 108.406 65.9698 107.348 67.7395 107.822Z"
      fill="#AEDEFF"
    />
    <path
      d="M81.3059 111.458L98.3 116.011C100.07 116.485 101.125 118.316 100.65 120.09C100.175 121.862 98.3455 122.92 96.5758 122.446L79.5817 117.892C77.8121 117.418 76.7566 115.587 77.2314 113.815C77.7067 112.041 79.5363 110.983 81.3059 111.458Z"
      fill="#7FC6FE"
    />
    <g opacity="0.8">
      <path
        d="M153.477 79.6188C164.165 84.0229 176.402 78.9166 180.808 68.2128C185.214 57.509 180.122 45.2616 169.433 40.8575C158.745 36.4534 146.508 41.5597 142.102 52.2635C137.696 62.9673 142.788 75.2146 153.477 79.6188Z"
        fill="#7FC6FE"
      />
    </g>
    <path
      d="M155.477 75.6207C166.165 80.0248 178.402 74.9185 182.808 64.2147C187.214 53.5109 182.122 41.2636 171.433 36.8594C160.745 32.4553 148.508 37.5617 144.102 48.2654C139.696 58.9692 144.788 71.2166 155.477 75.6207Z"
      fill="#AEDEFF"
    />
    <path
      d="M167.441 45.5316L167.411 45.5175C165.329 44.7204 160.864 44.4817 158.877 49.3118C158.501 50.2226 158.932 51.2618 159.843 51.6376C160.753 52.013 161.792 51.5791 162.167 50.6683C163.251 48.036 165.451 48.6014 166.116 48.8395C168.212 49.7164 169.051 51.3816 168.296 53.2163C167.74 54.5636 166.982 54.8212 165.124 55.1593C163.464 55.4568 160.948 55.9238 159.846 58.8624L159.65 59.3406C159.275 60.2514 159.706 61.2906 160.617 61.6665C161.518 62.0375 162.56 61.6186 162.958 60.7156L163.197 60.1353C163.562 59.1579 164.121 58.9764 165.77 58.6741L165.796 58.6732C167.563 58.3466 170.242 57.8374 171.587 54.5728C173.105 50.8839 171.404 47.1642 167.441 45.5316Z"
      fill="#EAF9FF"
    />
    <path
      d="M159.987 63.4905C158.984 63.078 157.829 63.5586 157.414 64.5646C157.002 65.5693 157.483 66.7256 158.485 67.14C159.504 67.5466 160.655 67.0748 161.059 66.0658C161.472 65.0593 160.992 63.9053 159.987 63.4905Z"
      fill="#EAF9FF"
    />
  </svg>
);

export const EmptyInterviewState: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent="center"
      alignSelf="center"
      maxW="500"
      mt="80px"
    >
      <Box width="100%">{EMPTY_INTERVIEW_ICON}</Box>
      <Box
        color="gray.900"
        width="100%"
        alignItems="center"
        justifyContent="center"
        mt="40px"
      >
        <Text as="p" mb="3" fontSize="2xl" fontWeight="600">
          No interviews to display
        </Text>
        <Text
          as="p"
          fontSize="sm"
          fontWeight="400"
          color="gray.600"
          lineHeight="20px"
          px={[3, 0]}
        >
          There are no recorded interviews to display for this candidate. If you
          believe is this is incorrect, verify that you have access to view
          them.
        </Text>
      </Box>
    </Flex>
  );
};

export const EmptyHighlightNotEnabledState: React.FC = () => (
  <Flex
    py="8"
    textAlign="center"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    minHeight="520px"
  >
    <EmptyVideoPlayer width="192" height="186" />
    <Text
      as="p"
      fontSize="2xl"
      color="gray.800"
      fontWeight="md"
      mt="10"
      maxW={480}
    >
      Candidate highlights are not supported without interview recordings.
    </Text>
    <Text as="p" fontSize="sm" color="gray.600" mt="3">
      Get in touch with your administrator if you feel this may be an error.
    </Text>
  </Flex>
);

export const EmptyHighlightFilteredState: React.FC = () => (
  <Flex
    py="8"
    textAlign="center"
    bg="yellow.50"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    minHeight="520px"
  >
    <EmptyVideoPlayer width="192" height="186" colorScheme="yellow" />
    <Text
      as="p"
      fontSize="2xl"
      color="gray.900"
      fontWeight="600"
      lineHeight="32px"
      mt="10"
    >
      No highlights match your filter selections.
    </Text>
    <Text as="p" fontSize="sm" lineHeight="20px" color="gray.600" mt="3">
      Try changing or removing them.
    </Text>
  </Flex>
);

export const EmptyHighlightUnfilteredState: React.FC = () => (
  <Flex
    py="8"
    textAlign="center"
    bg="yellow.50"
    alignItems="center"
    justifyContent="center"
    flexDir="column"
    minHeight="520px"
  >
    <EmptyVideoPlayer width="192" height="186" colorScheme="yellow" />
    <Text
      as="p"
      fontSize="2xl"
      color="gray.900"
      fontWeight="600"
      lineHeight="32px"
      mt="10"
    >
      No highlights to display.
    </Text>
    <Text as="p" fontSize="sm" lineHeight="20px" color="gray.600" mt="3">
      There are no highlights to display for this candidate. If you believe this
      is incorrect, verify that you have access to view them.
    </Text>
  </Flex>
);
