import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useDropzone } from "react-dropzone";

type FileUploadInputProps = {
  maxFiles?: number;
  allowedFormats?: Record<string, string[]>;
  value?: string;
  onUpload: (files: File[]) => void;
};

const FileUploadInput: React.FC<FileUploadInputProps> = ({
  onUpload,
  value,
  maxFiles = 1,
  allowedFormats = {
    "video/mp4": [".mp4"],
    "audio/m4a": [".m4a"],
  },
}) => {
  const onDrop = (acceptedFiles: File[]): void => {
    onUpload(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFormats,
    maxFiles,
  });

  const inputDescription =
    maxFiles === 1
      ? [
          "Drop the file here...",
          "Drag and drop a file here, or click to select one",
        ]
      : [
          "Drop the files here...",
          "Drag and drop some files here, or click to select files",
        ];

  const allowedFormatsString = Object.values(allowedFormats)
    .map((extensions) => `${extensions.join(", ")}`)
    .join(", ")
    .toLocaleUpperCase();
  const allowedFormatsDescription = allowedFormatsString || "";
  return (
    <>
      <Box
        {...getRootProps()}
        border="2px dashed"
        borderColor={isDragActive ? "blue.500" : "gray.300"}
        p={4}
        borderRadius="md"
        textAlign="center"
        cursor="pointer"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text color="blue.500">{inputDescription[0]}</Text>
        ) : value ? (
          <Text color="blue.500">{value}</Text>
        ) : (
          <Text color="gray.600">{inputDescription[1]}</Text>
        )}
      </Box>
      <Text mt={1} color="gray.500" fontSize="sm">
        Allowed file types: {allowedFormatsDescription}
      </Text>
    </>
  );
};

export default FileUploadInput;
