import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CustomTemplateCustomType,
  useCreateAiSummaryTemplateMutation,
  useDuplicateAiSummaryTemplateMutation,
  useUpdateAiSummaryTemplateMutation,
  useUpdateCallAiSummaryFormatMutation,
} from "../../graphql";
import { CustomTemplateInput } from "./types";

type CustomTemplateReturn = {
  createCustomTemplate: (
    input: CustomTemplateInput,
    onValidation: (validationError: string | null) => void
  ) => void;
  updateCustomTemplate: (
    input: CustomTemplateInput,
    onValidation: (validationError: string | null) => void
  ) => void;
  duplicateCustomTemplate: (id: string) => void;
  navigateBack: (templateId?: string) => void;
};

type UseCustomTemplateProps = {
  fromCallId: string | null;
};

const validationToastError = "Unable to save template. See error below.";

const useCustomTemplate = (
  props: UseCustomTemplateProps
): CustomTemplateReturn => {
  const { fromCallId } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();

  // Navigate back to the correct page, either call page or templates list
  const navigateBack = useCallback(
    (templateId?: string, regenerate?: boolean) => {
      if (fromCallId && templateId) {
        // Navigate back to the call page with the new or updated template
        // Option to regenerate the summary (for updating existing templates)
        if (regenerate) {
          generateSummary({
            variables: {
              callId: fromCallId,
              format: CallAiSummaryFormat.Custom,
              customTemplateId: templateId,
              targetSpeakerTags: [],
              customTopics: [],
              forceRegenerate: true,
            },
          });
        }
        navigate(
          `/interview/${fromCallId}?tab=ai_notes&customTemplateId=${templateId}`
        );
      } else if (fromCallId) {
        // Navigate back to the previous URL (for cancelling creation from call page)
        // Strip the template id query param in case it was a delete (no-op for cancel)
        navigate(`/interview/${fromCallId}?tab=ai_notes`);
      } else {
        // Navigate back to the templates list
        navigate("/templates");
      }
    },
    [fromCallId, navigate]
  );

  // Create a new template
  const [createTemplateMutation] = useCreateAiSummaryTemplateMutation();
  const createCustomTemplate = (
    input: CustomTemplateInput,
    onValidation: (validationError: string | null) => void
  ): void => {
    const sectionsWithoutIds = input.sections.map((section) => ({
      title: section.title,
      prompt: section.prompt,
    }));
    createTemplateMutation({
      variables: {
        name: input.name,
        isCreatorDefault: input.isCreatorDefault,
        sections: sectionsWithoutIds,
        customType: input.customType || CustomTemplateCustomType.Sections,
        customPrompt: input.customPrompt,
        model: input.model,
        visibility: input.visibility,
        isCurrentUserDefault: input.isCurrentUserDefault,
        defaultFor: input.defaultFor,
        defaultForPersona: input.defaultForPersona,
        defaultForKeywords: input.defaultForKeywords,
        defaultForMeetingType: input.defaultForMeetingType,
      },
      onCompleted: (data) => {
        // Currently we only expect one error
        const validationError = (data.createAiSummaryTemplate
          ?.validationError || [])[0];
        onValidation(validationError);
        if (data.createAiSummaryTemplate?.validationError) {
          toast({
            status: "error",
            description: validationToastError,
            position: "top",
          });
          return;
        }
        const newTemplateId = data.createAiSummaryTemplate?.template?.id;
        if (newTemplateId) {
          toast({
            status: "success",
            description: "Template created",
            position: "top",
          });
          sendGAEvent(
            "ai_notes_custom_template_create_save",
            "call_ai_templates",
            input.customType === CustomTemplateCustomType.CustomPrompt
              ? "custom_prompt"
              : "sections",
            undefined,
            { newTemplateId }
          );
          navigateBack(newTemplateId, true);
        }
      },
      onError: (error) => {
        onValidation(null);
        toast({
          status: "error",
          description: "Failed to create template. Please try again.",
          position: "top",
        });
      },
      refetchQueries: ["CurrentUserAiSummaryTemplatesQuery"],
    });
  };

  // Update an existing template
  const [updateTemplateMutation] = useUpdateAiSummaryTemplateMutation();
  const updateCustomTemplate = (
    input: CustomTemplateInput,
    onValidation: (validationError: string | null) => void
  ): void => {
    const sectionsWithoutIds = input.sections.map((section) => ({
      title: section.title,
      prompt: section.prompt,
    }));
    if (!input.id) return;
    updateTemplateMutation({
      variables: {
        id: input.id,
        name: input.name,
        isCreatorDefault: input.isCreatorDefault,
        sections: sectionsWithoutIds,
        customType: input.customType || CustomTemplateCustomType.Sections,
        customPrompt: input.customPrompt,
        model: input.model,
        visibility: input.visibility,
        isCurrentUserDefault: input.isCurrentUserDefault,
        defaultFor: input.defaultFor,
        defaultForPersona: input.defaultForPersona,
        defaultForKeywords: input.defaultForKeywords,
        defaultForMeetingType: input.defaultForMeetingType,
      },
      onCompleted: (data) => {
        // Currently we only expect one error
        const validationError = (data.updateAiSummaryTemplate
          ?.validationError || [])[0];
        onValidation(validationError);
        if (data.updateAiSummaryTemplate?.validationError) {
          toast({
            status: "error",
            description: validationToastError,
            position: "top",
          });
          return;
        }
        const templateId = data.updateAiSummaryTemplate?.template?.id;
        if (templateId) {
          toast({
            status: "success",
            description: "Template updated",
            position: "top",
          });
          sendGAEvent(
            "ai_notes_custom_template_edit_save",
            "call_ai_templates",
            input.customType === CustomTemplateCustomType.CustomPrompt
              ? "custom_prompt"
              : "sections",
            undefined,
            { templateId }
          );
          navigateBack(templateId, true);
        }
      },
      onError: (error) => {
        onValidation(null);
        toast({
          status: "error",
          description: "Failed to update template. Please try again.",
          position: "top",
        });
      },
    });
  };

  // Copy a template
  const [duplicateTemplateMutation] = useDuplicateAiSummaryTemplateMutation();
  const duplicateCustomTemplate = (id: string): void => {
    duplicateTemplateMutation({
      variables: {
        id,
      },
      onCompleted: (data) => {
        const templateId = data.duplicateAiSummaryTemplate?.template?.id;
        if (templateId) {
          toast({
            title: "Template copied to My Templates",
            status: "success",
          });
          sendGAEvent(
            "ai_notes_custom_template_duplicate",
            "call_ai_templates",
            undefined,
            undefined,
            { templateId }
          );
          navigateBack(templateId);
        }
      },
      onError: (error) => {
        toast({
          status: "error",
          description: "Failed to copy template. Please try again.",
          position: "top",
        });
      },
    });
  };

  const [generateSummary] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  return {
    createCustomTemplate,
    updateCustomTemplate,
    duplicateCustomTemplate,
    navigateBack,
  };
};

export default useCustomTemplate;
