import { useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { errorToast, successToast, useToast } from "../../../../components";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { getSmartrecruitersLink } from "../../../../utils/smartRecruiters";
import {
  CandidateFragment,
  CandidateLinkType,
  useUpdateCandidateOptOutMutation,
} from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import useCurrentUser from "../../../hooks/useCurrentUser";
import CandidateHeaderBase from "./CandidateHeaderBase";
import DeleteCandidateModal from "./DeleteCandidateModal";
import EditCandidateModal from "./EditCandidateModal";
import ToggleCandidateDeletionModal from "./ToggleCandidateDeletionModal";
import { useCandidateAlerts } from "./useCandidateAlerts";
import { useCandidatePositionOptions } from "./useCandidatePositionOptions";

type CandidateHeaderProps = {
  candidate: CandidateFragment;
  positionId?: string;
  isAskEnabled?: boolean;
  onAskClick?: () => void;
  hasCompletedInterviews?: boolean;
};

const CandidateHeader: React.FC<CandidateHeaderProps> = ({
  candidate,
  positionId,
  isAskEnabled = false,
  onAskClick,
  hasCompletedInterviews,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const isExtension = useIsExtension();
  const isSmallScreen = useIsSmallScreen();

  const candidateAlertsProps = useCandidateAlerts({
    candidate,
    onSubscribeToAlerts: () =>
      successToast(
        toast,
        "You have subscribed to all future alerts for this candidate"
      ),
    onMuteAlerts: () =>
      successToast(
        toast,
        "You have muted all future alerts for this candidate"
      ),
    onSubscriptionError: () =>
      errorToast(toast, "There was a problem updating your alert subscription"),
  });

  const editCandidateModal = useDisclosure();
  const deleteCandidateModal = useDisclosure();
  const toggleCandidateDeletionModal = useDisclosure();

  const [updateCandidateOptOut] = useUpdateCandidateOptOutMutation({
    onError: () => {
      errorToast(toast, "There was a problem updating the candidate");
    },
    onCompleted(data) {
      const { candidate } = data.updateCandidateOptOut;
      if (candidate) {
        const description = candidate.recordingDisabled
          ? "Future interviews will not be recorded"
          : "Future interviews will be recorded";
        successToast(toast, description);
      }
    },
  });

  const onPositionChange = (newPositionId: string, replace: boolean): void => {
    navigate(`/candidate/${candidate.id}/position/${newPositionId}`, {
      replace,
    });
  };

  // If the URL ends with /position/none, the user manually selected the "Interviews with no position" option.
  const isNoneCase = location.pathname.endsWith("/none");
  const candidatePositionOptions = useCandidatePositionOptions(candidate);

  useEffect(() => {
    if (isNoneCase) {
      return;
    }
    const validOptions = candidatePositionOptions.filter(
      (opt) => (opt?.count || 0) > 0
    );
    if (!positionId && validOptions.length) {
      onPositionChange(validOptions[0].key, true);
    }
  }, [isNoneCase, candidatePositionOptions, positionId]);

  const canDeleteCandidates = currentUser.userRole?.canDeleteCandidates;
  const smartRecruitersLink = getSmartrecruitersLink(candidate, positionId);
  const atsOptInEnabled = currentUser.organization?.atsOptInEnabled;

  const linkedInLinks = candidate.candidateLinks.filter(
    (link) => link.type === CandidateLinkType.Linkedin
  );

  return (
    <>
      <EditCandidateModal candidate={candidate} {...editCandidateModal} />
      <DeleteCandidateModal candidate={candidate} {...deleteCandidateModal} />
      <ToggleCandidateDeletionModal
        candidate={candidate}
        {...toggleCandidateDeletionModal}
      />
      <CandidateHeaderBase
        candidate={candidate}
        positionId={positionId}
        candidatePositionOptions={candidatePositionOptions}
        onPositionChange={(pid) => onPositionChange(pid, false)}
        {...candidateAlertsProps}
        isExtension={isExtension}
        isSmallScreen={isSmallScreen}
        atsOptInEnabled={atsOptInEnabled}
        isAskEnabled={isAskEnabled}
        hasCompletedInterviews={hasCompletedInterviews}
        onAskClick={onAskClick}
        smartRecruitersLink={smartRecruitersLink}
        linkedInLinks={linkedInLinks}
        onOpenEditCandidate={editCandidateModal.onOpen}
        onOpenDeleteCandidate={
          canDeleteCandidates ? deleteCandidateModal.onOpen : undefined
        }
        onOpenToggleCandidateDeletion={
          canDeleteCandidates ? toggleCandidateDeletionModal.onOpen : undefined
        }
        onUpdateCandidateOptOut={() =>
          updateCandidateOptOut({
            variables: {
              id: candidate.id,
              recordingDisabled: !candidate.recordingDisabled,
            },
          })
        }
      />
    </>
  );
};

export default CandidateHeader;
