import { Checkbox, Flex, Switch, Text } from "@chakra-ui/react";
import React from "react";
import { Control, Controller } from "react-hook-form";

export type FieldValues = {
  playCallDisclaimer: boolean;
  callDisclaimerExplicitConsent: boolean;
  allowCandidateOptOut: boolean;
  allowInterviewerOptOut: boolean;
  redactEeocFamilyEnabled: boolean;
  redactEeocRaceEnabled: boolean;
  redactEeocSexEnabled: boolean;
  redactEeocHighRiskQuestionsEnabled: boolean;
  redactCompensationEnabled: boolean;
  dataRedactionEnabled: boolean;
};

export const ControlledCheckbox: React.FC<{
  name: keyof FieldValues;
  control: Control<FieldValues>;
  children: React.ReactNode;
}> = ({ name, control, children }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, ...field } }) => (
      <Checkbox
        {...field}
        isChecked={value}
        lineHeight="5"
        spacing="3.5"
        variant="blue.600"
        size="lg"
      >
        <Text as="span" fontSize="sm">
          {children}
        </Text>
      </Checkbox>
    )}
  />
);

export const ControlledSwitch: React.FC<{
  name: keyof FieldValues;
  control: Control<FieldValues>;
  children: React.ReactNode;
}> = ({ name, control, children }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, ...field } }) => (
      <Flex alignItems="center">
        <Switch {...field} isChecked={value} />
        <Text as="span" pl="3" fontWeight="400" color="gray.900" fontSize="md">
          {children}
        </Text>
      </Flex>
    )}
  />
);
