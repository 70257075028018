import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoCopyOutline } from "react-icons/io5";

import { copy } from "../../utils/clipboard";
import { Button, IconButton } from "../Buttons";
import { ConfirmModal } from "../ConfirmModal";
import { Link } from "../Link";
import SettingsNote from "../Settings/SettingsNote";
import SettingsPageContainer from "../Settings/SettingsPageContainer";
import { successToast, useToast } from "../useToast";

interface FormValues {
  oktaDomain: string;
  metadataUrl: string;
}

interface SingleSignOnSetup {
  oktaDomain: string;
  metadataUrl: string;
  scimToken: string;
}

interface SingleSignOnSettingsFormProps {
  loading: boolean;
  setupLoading: boolean;
  tokenLoading: boolean;
  data?: {
    singleSignOnSetup?: SingleSignOnSetup;
  };
  tokenData?: {
    generateToken?: {
      setup: SingleSignOnSetup;
    };
  };
  onSubmit: (values: FormValues) => void;
  onGenerateToken: () => void;
}

const SingleSignOnSettingsForm: React.FC<SingleSignOnSettingsFormProps> = ({
  loading,
  setupLoading,
  tokenLoading,
  data,
  tokenData,
  onSubmit,
  onGenerateToken,
}) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, setValue } = useForm<FormValues>({});

  useEffect(() => {
    setValue("oktaDomain", data?.singleSignOnSetup?.oktaDomain || "");
    setValue("metadataUrl", data?.singleSignOnSetup?.metadataUrl || "");
  }, [data]);

  const isLoading = loading || setupLoading;
  const scimToken =
    tokenData?.generateToken?.setup.scimToken ||
    data?.singleSignOnSetup?.scimToken;

  const isHashedToken =
    !!scimToken && scimToken === data?.singleSignOnSetup?.scimToken;

  return (
    <SettingsPageContainer heading="Single Sign On Setup">
      <Box my={8}>
        <SettingsNote mb="9">
          Creating users in Okta (with default settings) will provision a user
          account in BrightHire and email all users an invite to BrightHire.
          Most organizations DO NOT want invites to be sent when users are added
          before BrightHire training has occurred. Check with your internal
          project team or BrightHire CSM before creating users in Okta. More
          information on this setting can be found in Configuration Steps
          section of
          <Link
            href="https://help.brighthire.ai/en/articles/5870425-okta-scim-integration-guide"
            target="_blank"
          >
            {" this article."}
          </Link>
        </SettingsNote>
        <Heading as="h3" size="sm" mb={2}>
          Instructions
        </Heading>
        <Text maxW="500px" mb={2}>
          Please see the{" "}
          <Link
            target="_blank"
            href="https://help.brighthire.ai/en/articles/6501000-sso-setup"
          >
            help documentation
          </Link>
          .
        </Text>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing="8" align="start">
          <FormControl id="oktaDomain" isRequired maxW="400">
            <FormLabel>Unique Identifier or Okta Domain</FormLabel>
            <Input {...register("oktaDomain")} />
          </FormControl>
          <FormControl id="metadataUrl" isRequired maxW="400">
            <FormLabel>Metadata Url</FormLabel>
            <Input {...register("metadataUrl")} type="text" />
          </FormControl>
          <FormControl id="scimToken" isRequired>
            <FormLabel>SCIM Token</FormLabel>
            <Flex gap="2" direction="column">
              {scimToken && (
                <InputGroup w={isHashedToken ? 400 : 710}>
                  <Input
                    name="scimToken"
                    type="text"
                    value={scimToken}
                    isReadOnly
                    _focus={{ borderColor: "inherit" }}
                    onClick={(e) => {
                      const el = e.target as HTMLInputElement;
                      el?.select?.();
                    }}
                  />
                  {!isHashedToken && (
                    <InputRightElement>
                      <Tooltip label="Copy SCIM token">
                        <IconButton
                          aria-label="Copy SCIM token"
                          variant="ghost"
                          boxSize="9"
                          mr="1"
                          icon={<IoCopyOutline />}
                          onClick={() => {
                            copy(scimToken);
                            successToast(toast, "SCIM token copied");
                          }}
                        />
                      </Tooltip>
                    </InputRightElement>
                  )}
                </InputGroup>
              )}
              <Button
                alignSelf="start"
                disabled={isLoading || tokenLoading}
                variant="danger"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {scimToken ? "Regenerate" : "Generate"} SCIM token
              </Button>
              <ConfirmModal
                modalBodyText="This will regenerate your SCIM Token and you will need to update it in your SSO provider. Are you sure?"
                isOpen={isOpen}
                onCancel={() => {
                  setIsOpen(false);
                }}
                onConfirm={() => {
                  onGenerateToken();
                  setIsOpen(false);
                }}
              />
            </Flex>
          </FormControl>
          <Button type="submit" disabled={isLoading}>
            Save Changes
          </Button>
        </VStack>
      </form>
    </SettingsPageContainer>
  );
};

export default SingleSignOnSettingsForm;
