import { Center, Flex, FormLabel, Textarea } from "@chakra-ui/react";
import React from "react";

import {
  Alert,
  Button,
  LoadingIndicator,
  MarkdownOutput,
} from "../../../components";

// import useCurrentUser from "../../hooks/useCurrentUser";

const CoachingDemo: React.FC = () => {
  // const currentUser = useCurrentUser();
  // const { id } = currentUser;
  const [customInstructions, setCustomInstructions] =
    React.useState<string>("");

  const loading = false;
  const error = false;
  const taskRun = {
    markdown: "# Coaching Tips\n\n- Tip 1\n- Tip 2",
  };

  return (
    <Center maxW="1200px" mx="auto" mt={10} mb={4} alignItems="flex-start">
      <Flex direction="column" alignItems="start" minW="500px" mr="8">
        <FormLabel fontWeight="bold">Custom Instructions</FormLabel>
        <Textarea
          mb="4"
          minH="300px"
          value={customInstructions}
          onChange={(e) => setCustomInstructions(e.target.value)}
          placeholder="Enter custom instructions here..."
        />
        <Button
          variant="prismPrimary"
          mb={4}
          onClick={() => {
            // Handle button click to run coaching
          }}
        >
          Run Coaching
        </Button>
      </Flex>
      <Flex direction="column" alignItems="start" flex="1">
        <FormLabel fontWeight="bold">Results</FormLabel>
        <Center w="100%">
          {loading && <LoadingIndicator my="4" />}
          {!loading && error && (
            <Alert
              status="error"
              title="Error"
              description="An error occurred while fetching the data."
            />
          )}
          {!loading && !error && taskRun && (
            <MarkdownOutput
              markdown={taskRun.markdown}
              onClickTimestamp={() => null}
              w="100%"
              bg="gray.100"
            />
          )}
        </Center>
      </Flex>
    </Center>
  );
};

export default CoachingDemo;
