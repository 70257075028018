import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";

import Sidebar, { SidebarItem } from "../../components/Sidebar";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import { SectionHeader } from "./AnalyticsSidebar";

const AnalyticsAdvancedSidebar: React.FC<BoxProps> = ({ ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const topicTrendsEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:topic-trends"
  );
  const motivationsEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:candidate-motivations"
  );
  const questionsEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:candidate-questions"
  );
  const skillsCoverageEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:skills-report"
  );
  const compensationEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "analytics:compensation-trends"
  );

  return (
    <Sidebar data-tour-id="analytics-sidebar" borderWidth="0px" {...rest}>
      <Box mb="3" pb="3" pt="3">
        <>
          <SectionHeader
            title="Advanced Insights"
            icon={HiOutlineSparkles}
            isExpanded
          />
          <Box data-testid="analytics-sidebar-panel-advanced">
            {motivationsEnabled && (
              <SidebarItem
                text="Candidate Motivations"
                active={location.pathname.includes("candidate-motivations")}
                px="6"
                onClick={() => {
                  navigate({
                    pathname: `/insights/candidate-motivations`,
                    search: window.location.search,
                  });
                }}
              />
            )}
            {questionsEnabled && (
              <SidebarItem
                text="Candidate Questions"
                active={location.pathname.includes("candidate-questions")}
                px="6"
                onClick={() => {
                  navigate({
                    pathname: `/insights/candidate-questions`,
                    search: window.location.search,
                  });
                }}
              />
            )}
            {skillsCoverageEnabled && (
              <SidebarItem
                text="Skills Coverage"
                active={location.pathname.includes("skill-report")}
                px="6"
                onClick={() => {
                  navigate({
                    pathname: `/insights/skill-report/all`,
                    search: window.location.search,
                  });
                }}
              />
            )}
            {topicTrendsEnabled && (
              <SidebarItem
                text="Topic Trends"
                active={location.pathname.includes("topic-trends")}
                px="6"
                onClick={() => {
                  navigate({
                    pathname: `/insights/topic-trends`,
                    search: window.location.search,
                  });
                }}
              />
            )}
            {compensationEnabled && (
              <SidebarItem
                text="Compensation Trends"
                active={location.pathname.includes("compensation-trends")}
                px="6"
                onClick={() => {
                  navigate({
                    pathname: `/insights/compensation-trends`,
                  });
                }}
              />
            )}
          </Box>
        </>
      </Box>
    </Sidebar>
  );
};

export default AnalyticsAdvancedSidebar;
