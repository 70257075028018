import { ExampleTemplate } from "../types";

export const softwareSystemDesign: ExampleTemplate = {
  name: "Software System Design",
  description: "Writeup the thought process and solution.",
  prompt: `Write up a report on this software system design job interview.

Start with an h2 header to introduce the overall system design problem. Write a short paragraph explaining the problem.

Then, organize the results in topics (h2 headers) with bullets that have timestamp citations. To make the bullets readable, prefix them with a bolded category for the kind of information being conveyed in that bullet.

Topics should be in chronological order. Each topic should capture key parts of system design interviews, such as:
* The part of the problem the candidate is solving
* The questions they asked
* The solutions they proposed
* The problems they encountered
* Any hints or clues the interviewers provided help the candidate

Avoid using judgemental language, and focus on reporting the facts.`,
  output: `## System Design Problem: News Aggregator Application

The system design interview focused on creating a news aggregator application. The application allows users to monitor news articles across various categories in real-time, add or remove categories from their watchlist, and handle high data volumes efficiently. The candidate was tasked with designing a scalable and reliable architecture to meet these requirements.

## Introduction to the Problem

- **Problem Context**: The interviewers introduced the problem as a single-page application for monitoring news articles in real-time, with features to add/remove categories and handle data spikes. [7:29](?t=449)
- **Candidate's Clarification**: The candidate asked whether the category list needed to be persistent or reset every time the browser was opened. [13:03](?t=783)
- **Interviewer Guidance**: The interviewers clarified that the category list should be persistent and tied to user sessions. [13:26](?t=806)

## Initial Design and Requirements

- **Candidate's Proposal**: The candidate outlined a high-level design with a client, a news service, and a database to store user and category data. [15:30](?t=930)
- **APIs**: Proposed APIs for adding/removing categories and fetching news articles. [13:26](?t=806)
- **Scalability Question**: The candidate asked if the design could focus on a single user or needed to handle concurrent users. [9:52](?t=592)
- **Interviewer Input**: The interviewers emphasized the need for baseline scalability to handle at least 10-1,000 users. [11:09](?t=669)

## Handling Real-Time Updates

- **Real-Time Data**: The candidate proposed using server-side events for real-time updates, with an in-memory cache to store the latest articles. [23:32](?t=1412)
- **Caching Strategy**: Suggested using Redis or an equivalent in-memory cache for storing the latest articles. [24:04](?t=1444)
- **Interviewer Question**: Asked how the system would handle updates for multiple users. [28:01](?t=1681)

## Scalability and Load Management

- **High Data Volume**: The candidate introduced a callback service and Kafka queue to handle up to 33,000 article updates per second. [35:22](?t=2122)
- **Batching Updates**: Proposed using Flink to aggregate updates into batches, reducing the number of updates sent to users. [37:48](?t=2268)
- **Interviewer Follow-Up**: Asked how batching would work and whether all updates would be sent to users. [44:32](?t=2672)
- **Candidate's Response**: Explained that aggregated updates could be sent as a single JSON object, with the client filtering relevant data. [45:24](?t=2724)

## Data Consistency and Ordering

- **Out-of-Order Updates**: The candidate suggested using a priority heap in Redis to ensure the latest timestamp is always used. [41:49](?t=2509)
- **Interviewer Input**: Mentioned Redis transactions as an alternative for handling out-of-order updates. [44:03](?t=2643)

## Final Questions and Wrap-Up

- **Candidate's Questions**: Asked about the company's day-to-day activities, use of AI tools, and handling of LLM updates. [50:24](?t=3024), [52:21](?t=3141)
- **Interviewer Responses**: Described their roles, use of AI tools like Copilot, and strategies for evaluating new LLMs. [50:38](?t=3038), [56:07](?t=3367)
- **Closing Remarks**: The candidate expressed hope that the interview went well, and the interviewers thanked them for their time. [58:37](?t=3517)`,
};
