import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import {
  Button,
  CenteredCardLayout,
  LoadingIndicator,
  useToast,
} from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import LoomEmbed from "../../components/LoomEmbed";
import {
  useCandidateEmailOptOutQuery,
  useUpdateCandidateEmailOptOutMutation,
} from "../../graphql";

const CandidateOptOutPage: React.FC = () => {
  usePageTracker("candidate_email_opt_out");
  const toast = useToast();
  const { orgId, verificationCode } = useParams() as {
    orgId: string;
    verificationCode: string;
  };
  const {
    data: candidateEmailOptOutData,
    loading: candidateEmailOptOutLoading,
    error: candidateOptOutError,
  } = useCandidateEmailOptOutQuery({
    variables: { orgId, verificationCode },
  });

  const [updateCandidateEmailOptOut, { loading: candidateOptOutLoading }] =
    useUpdateCandidateEmailOptOutMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const candidateOptOut = candidateEmailOptOutData?.candidateEmailOptOut;
  const hasFaq = !!candidateOptOut?.videoUrls?.length;
  if (candidateEmailOptOutLoading) {
    return (
      <CenteredCardLayout>
        <Box maxW="600px">
          <LoadingIndicator />
        </Box>
      </CenteredCardLayout>
    );
  }

  if (candidateOptOutError) {
    return (
      <Navigate
        to={{ pathname: `/candidate-opt-out/${orgId}` }}
        state={{ error: { message: candidateOptOutError.message } }}
      />
    );
  }

  if (!candidateOptOut) return <Navigate to="/not-found" />;

  return (
    <CenteredCardLayout>
      <Box maxW="600px">
        {hasFaq ? (
          <Tabs isLazy>
            <TabList>
              <Tab>FAQ</Tab>
              <Tab data-testid="recording-opt-out">Recording Opt Out</Tab>
            </TabList>
            <TabPanels pt={4}>
              <TabPanel>
                {candidateOptOut?.videoUrls?.map((url, i) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    mb="8"
                    data-testid={url}
                  >
                    <LoomEmbed
                      src={url.replace("share", "embed")}
                      onClick={() => LogRocket.track("video-faq-play")}
                    />
                  </Box>
                ))}
              </TabPanel>
              <TabPanel>
                <RecordingOptOut
                  candidateOptOut={candidateOptOut}
                  candidateOptOutLoading={candidateOptOutLoading}
                  updateCandidateEmailOptOut={(recordingDisabled) =>
                    updateCandidateEmailOptOut({
                      variables: {
                        orgId,
                        verificationCode,
                        recordingDisabled,
                      },
                    })
                  }
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <RecordingOptOut
            candidateOptOut={candidateOptOut}
            candidateOptOutLoading={candidateOptOutLoading}
            updateCandidateEmailOptOut={(recordingDisabled) =>
              updateCandidateEmailOptOut({
                variables: {
                  orgId,
                  verificationCode,
                  recordingDisabled,
                },
              })
            }
          />
        )}
      </Box>
    </CenteredCardLayout>
  );
};

const RecordingOptOut: React.FC<{
  candidateOptOut: { recordingDisabled: boolean; organizationName: string };
  candidateOptOutLoading: boolean;
  updateCandidateEmailOptOut: (recordingDisabled: boolean) => void;
}> = ({
  candidateOptOut,
  candidateOptOutLoading,
  updateCandidateEmailOptOut,
}) =>
  candidateOptOut.recordingDisabled ? (
    <>
      <img src="/static/images/videocam-off-sharp.svg" width="45px" />
      <Heading size="md" lineHeight="lg" my="6">
        You’ve successfully opted out of BrightHire recordings.
      </Heading>
      <Text>
        None of your interviews with {candidateOptOut.organizationName} will be
        recorded by BrightHire.
      </Text>
      <Button
        mt="10"
        variant="outline"
        isLoading={candidateOptOutLoading}
        onClick={() => updateCandidateEmailOptOut(false)}
      >
        Opt back in to BrightHire
      </Button>
    </>
  ) : (
    <>
      <img src="/static/images/videocam-off-sharp.svg" width="45px" />
      <Heading size="md" lineHeight="lg" my="6">
        Would you like to opt out of BrightHire recordings for all of your
        interviews?
      </Heading>
      <Text>
        If you opt out, your interviews with {candidateOptOut.organizationName}{" "}
        will not be recorded. You can always return to this page and change your
        preference later.
      </Text>
      <Wrap mt="10" spacing="4">
        <WrapItem>
          <Button
            variant="danger"
            isLoading={candidateOptOutLoading}
            onClick={() => updateCandidateEmailOptOut(true)}
          >
            Do not record my interviews
          </Button>
        </WrapItem>
        <Wrap>
          <Button
            variant="outline"
            onClick={() => {
              window.location.href =
                "https://help.brighthire.ai/en/articles/4484370";
            }}
          >
            Allow BrightHire to record my interviews
          </Button>
        </Wrap>
      </Wrap>
    </>
  );

CandidateOptOutPage.displayName = "CandidateOptOutPage";
export default CandidateOptOutPage;
