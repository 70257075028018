import { Box, Flex, Select } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  generateDisplayImage,
  GenerateDisplayImageOptions,
} from "../../utils/notetakerDisplayImage";
import { Button } from "..";

interface NotetakerDisplayImageGeneratorProps {
  file?: File;
  onImageGenerated?: (base64Image: string) => void;
  onClear?: () => void;
}

const NotetakerDisplayImageGenerator: React.FC<
  NotetakerDisplayImageGeneratorProps
> = ({ file, onImageGenerated, onClear }) => {
  const [background, setBackground] = useState<"dark" | "light">("dark");
  const [scale, setScale] = useState<"large" | "medium" | "small">("large");
  const [displayImageUrl, setDisplayImageUrl] = useState<string | null>(null);

  const generatePreview = async (
    file: File,
    background: "dark" | "light",
    scale: "large" | "medium" | "small"
  ): Promise<string> => {
    const options: GenerateDisplayImageOptions = {
      canvasSize: { width: 1280, height: 720 },
      logoSubareaSize: { width: 960, height: 540 },
      logo: file,
      backgroundColor: background === "dark" ? "#1A202C" : "#FFFFFF",
      scale,
      maxFileSizeBytes: 75 * 1024, // 75KB
    };
    const imageUrl = await generateDisplayImage(options);
    setDisplayImageUrl(imageUrl);
    // Extract base64 data from data URL before passing to callback
    const base64Data = imageUrl.split(",")[1];
    onImageGenerated?.(base64Data);
    return imageUrl;
  };

  React.useEffect(() => {
    if (file) {
      generatePreview(file, background, scale);
    }
  }, [file]);

  if (!file) return null;

  return (
    <>
      {displayImageUrl && (
        <>
          <Box mt={4}>
            <img src={displayImageUrl} alt="Notetaker display" />
          </Box>
          <Flex mt={4} gap={4}>
            <Select
              id="displayImageBackgroundSelect"
              value={background}
              onChange={async (e) => {
                const newBackground = e.target.value as "dark" | "light";
                await generatePreview(file, newBackground, scale);
                setBackground(newBackground);
              }}
            >
              <option value="dark">Dark Background</option>
              <option value="light">Light Background</option>
            </Select>
            <Select
              id="displayImageScaleSelect"
              value={scale}
              onChange={async (e) => {
                const newScale = e.target.value as "large" | "medium" | "small";
                await generatePreview(file, background, newScale);
                setScale(newScale);
              }}
            >
              <option value="large">Large Logo</option>
              <option value="medium">Medium Logo</option>
              <option value="small">Small Logo</option>
            </Select>
            <Button type="reset" onClick={onClear} width="xs">
              Clear
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default NotetakerDisplayImageGenerator;
