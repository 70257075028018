import { ExampleTemplate } from "../types";

export const engCodingChallengeExample: ExampleTemplate = {
  name: "Software Engineer Coding Challenge",
  description: "Writeup of the solution and thought process.",
  prompt: `Write a summary of this software engineer coding challenge interview.

Organize the notes chronologically, broken up into the problems and steps of the coding challenge. 

For each problem, use an h2 header. For each major step of progress, use an h3 header. Introduce the problem at the top under the h2 header. For each major step, include the candidate's thought process, questions, and proposed solutions, within the bullet points of that step.

Include timestamp citation links at the end of each bullet. Do not include content other than the above requested problems, steps, and bullets.`,
  output: `
# Problem 1: Processing Data Entries

The candidate was tasked with processing a dataset to identify entries based on specific labels and their associated data.

## Step 1: Understanding the Problem
- The candidate clarified the format of the input, asking how to differentiate between labels like "Dr." and full names consisting of a title and a last name. A team member explained that names would always consist of two words: a title and a last name. [12:16](?t=736)
- The candidate asked whether each row was an entry and received clarification that entries were defined by label changes, not rows. [13:22](?t=802)

## Step 2: Initial Approach
- The candidate proposed iterating through the dataset to identify the start and end of each labeled entry, flagging rows as starting points and collecting data until the next label was identified. [18:05](?t=1085)
- The candidate inquired about the efficiency of processing the dataset twice versus a single pass. A team member assured them that efficiency was not a concern for this exercise. [20:43](?t=1243)

## Step 3: Refining the Approach
- The candidate decided to build a list of dictionaries, each containing a label and its data. They clarified whether a new dictionary should be created for repeated labels or if data should be appended. The team member confirmed that a new dictionary should be created for each entry. [28:15](?t=1695)
- The candidate implemented logic to identify labels by checking if the second word in a row ended with a period, but was pointed out by a team member that stripping spaces might discard important information. The candidate adjusted their approach to account for this. [40:20](?t=2420)

## Step 4: Finalizing the Solution
- The candidate added logic to close and save the current labeled entry when a new label was identified. They printed the results to verify correctness. [45:37](?t=2737)
- The team members reviewed the output and noted that while the logic worked well, it could be improved with pattern matching for greater accuracy. The candidate acknowledged this but explained that they opted for a simpler approach due to time constraints. [58:23](?t=3503)

# Problem 2: Extracting Responses

The candidate was tasked with identifying and extracting responses given by a specific individual to inquiries posed by others.

## Step 1: Understanding the Problem
- The candidate confirmed that they only needed to extract the responses, not the inquiries. [53:05](?t=3185)

## Step 2: Initial Implementation
- The candidate proposed iterating through the entries list to check if an entry ended with a question marker and if the next entry was provided by the specific individual. They implemented this logic and tested it. [54:31](?t=3271)

## Step 3: Refining the Logic
- A team member suggested resetting the "extract responses" flag at the start of each loop iteration to avoid incorrect outputs. The candidate implemented this adjustment and verified the results. [1:00:26](?t=3626)`,
};
