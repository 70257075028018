import {
  Box,
  Checkbox,
  Divider,
  ListItem,
  Select,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  errorToast,
  FormControl,
  Link,
  SettingsNote,
  SettingsPageContainer,
  successToast,
  useToast,
} from "../../../components";
import {
  CallAiSummaryFormat,
  CurrentUserFragment,
  FeatureName,
} from "../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../graphql/hooks/useFeatureFlag";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";

interface UserAiSettingsProps {
  currentUser: CurrentUserFragment;
}

type FormValues = {
  autofillGreenhouseScorecard: boolean;
  defaultAiNoteFormat: string;
};

export const UserAiSettings: React.FC<UserAiSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const advancedTemplatesEnabled = useFeatureFlagForCurrentOrImpersonatedUser(
    "custom_templates_v2:launch"
  );
  const advancedTemplatesBlacklisted =
    useFeatureFlagForCurrentOrImpersonatedUser("custom_templates_v2:blacklist");
  const showTemplateOverrideWarning =
    advancedTemplatesEnabled && !advancedTemplatesBlacklisted;

  const [updateCurrentUser, { loading }] = useUpdateCurrentUser({
    onCompleted: () => {
      successToast(toast, "Settings saved");
    },
    onError: () => {
      errorToast(toast, "There was a problem updating your settings");
    },
  });

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      autofillGreenhouseScorecard: currentUser.autofillGreenhouseScorecard,
      defaultAiNoteFormat: currentUser.defaultAiNoteFormat,
    },
  });

  const aiNotesTemplatesEnabled = useHasFeatureAccess(
    FeatureName.AiNotesTemplate
  );
  const defaultAiNoteFormatOptions = [
    { value: CallAiSummaryFormat.Qanda, label: "AI Interview Notes" },
    { value: CallAiSummaryFormat.Scorecard, label: "Scorecard Notes Template" },
  ];

  const scorecardDraftEnabled =
    currentUser.organization.allowsGreenhouseScorecardAutoDraft;
  const scorecardDraftForcedOn =
    currentUser.organization.requiresGreenhouseScorecardAutoDraft;

  return (
    <SettingsPageContainer
      heading="AI settings"
      subHeading="Choose your AI preferences within BrightHire"
    >
      <form
        onSubmit={handleSubmit((formData) => {
          updateCurrentUser({ variables: formData });
        })}
      >
        {scorecardDraftEnabled && (
          <>
            <Tooltip
              placement="bottom-start"
              ml="4"
              mt="-2"
              label={
                scorecardDraftForcedOn
                  ? "Your administrator has required this to be enabled for all BrightHire users"
                  : undefined
              }
            >
              <FormControl
                isDisabled={scorecardDraftForcedOn}
                helpText={
                  <Box fontSize="sm" lineHeight="5">
                    This will enable BrightHire to automatically draft your
                    Greenhouse scorecard with evidence from your AI-generated
                    interview notes. You&apos;ll always have the opportunity to
                    review and edit your feedback before you submit.
                  </Box>
                }
              >
                <Checkbox
                  {...register("autofillGreenhouseScorecard")}
                  defaultChecked={currentUser.autofillGreenhouseScorecard}
                >
                  Automatically draft my scorecards
                </Checkbox>
              </FormControl>
            </Tooltip>
            <SettingsNote mt="6" hideIcon>
              <UnorderedList ml="5" mb="2">
                <ListItem>
                  BrightHire will only create a draft if the scorecard is empty.
                  This ensures that your feedback is never overwritten.
                </ListItem>
                <ListItem>
                  If you&apos;ve already started filling out your scorecard, you
                  can still use the BrightHire{" "}
                  <Link
                    href="https://chromewebstore.google.com/detail/brighthire/mbokiighkhdopgedihndpibkincpcgan"
                    target="_blank"
                  >
                    browser extension
                  </Link>{" "}
                  to import your notes.
                </ListItem>
                <ListItem>
                  The interview must be scheduled in Greenhouse and have a
                  scorecard link attached to it for auto drafting to work.
                </ListItem>
              </UnorderedList>
              <Link
                href="https://help.brighthire.ai/en/articles/10000464-greenhouse-scorecard-auto-draft"
                target="_blank"
              >
                Learn more
              </Link>
            </SettingsNote>
          </>
        )}
        {scorecardDraftEnabled && aiNotesTemplatesEnabled && <Divider my="5" />}
        {aiNotesTemplatesEnabled && (
          <>
            <Text mb={2} mt={4}>
              Default AI note format
            </Text>
            <FormControl
              helpText={
                <Box fontSize="sm" lineHeight="5">
                  Select the default AI note format to use on the interview
                  details page
                  {scorecardDraftEnabled &&
                    " and when automatically drafting scorecards"}
                  .
                  <SettingsNote mt="6" hideIcon>
                    <UnorderedList ml="5" my="1">
                      <ListItem>
                        &quot;AI Interview Notes&quot; provides a Q&A format,
                        while &quot;Scorecard Notes Template&quot; aligns with
                        your scorecard structure.
                      </ListItem>
                      {showTemplateOverrideWarning && (
                        <ListItem>
                          This choice may be overridden for some interviews by
                          your organization&apos;s{" "}
                          <Link href="/templates" target="_blank">
                            custom template settings
                          </Link>
                        </ListItem>
                      )}
                    </UnorderedList>
                  </SettingsNote>
                </Box>
              }
            >
              <Select
                {...register("defaultAiNoteFormat", { required: true })}
                placeholder="Select note format..."
                aria-label="Select note format"
              >
                {defaultAiNoteFormatOptions.map((opt) => (
                  <option value={opt.value} key={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <Button type="submit" mt="12" isLoading={loading}>
          Save
        </Button>
      </form>
    </SettingsPageContainer>
  );
};
