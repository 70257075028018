import {
  Box,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Icon,
  Input,
  ListItem,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Text,
  Textarea,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  HiDotsVertical,
  HiOutlineDuplicate,
  HiOutlineInformationCircle,
} from "react-icons/hi";
import { HiArrowLeft } from "react-icons/hi2";

import {
  Alert,
  Button,
  FullscreenHeader,
  IconButton,
  MenuButton,
} from "../../../components";
import {
  CallAiSummaryTemplateDefaultFor,
  CallAiSummaryTemplateFragment,
  CallAiSummaryTemplateVisibility,
  CustomTemplateCustomType,
  CustomTemplateType,
  UserPersona,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import DeleteTemplateMenuItem from "../CallAiSummaryTemplateList/DeleteTemplateMenuItem";
import SectionsForm from "./SectionsForm";
import { CustomTemplateInput, CustomTemplateSectionInput } from "./types";

const pageHeaderHeight = "68px";

type EditCustomTemplateProps = {
  template: CallAiSummaryTemplateFragment | null;
  onClose: (templateId?: string) => void;
  onSave: (
    input: CustomTemplateInput,
    onValidation: (validationError: string | null) => void
  ) => void;
  onCopy?: (id: string) => void;
  onBack: () => void;
  customPrompt: string;
  setCustomPrompt: (prompt: string) => void;
  templateName: string;
  setTemplateName: (templateName: string) => void;
  switchToExamplePrompts: () => void;
  customType: CustomTemplateCustomType;
  setCustomType: (type: CustomTemplateCustomType) => void;
  isLegacyTemplate: boolean;
};

const EditCustomTemplate: React.FC<EditCustomTemplateProps> = ({
  template,
  onClose,
  onSave,
  onCopy,
  onBack,
  customPrompt,
  setCustomPrompt,
  templateName,
  setTemplateName,
  switchToExamplePrompts,
  customType,
  setCustomType,
  isLegacyTemplate,
}) => {
  const currentUser = useCurrentUser();
  const currentUserCanManageTemplates =
    currentUser.userRole?.canManageCustomTemplates;

  // If template is null, user is creating a new template
  // If template is not null, user is editing an existing template
  const canManageVisibility = !template
    ? currentUserCanManageTemplates
    : template.visibility === CallAiSummaryTemplateVisibility.Public ||
      template.canMakePublic;

  const [sections, setSections] = useState<CustomTemplateSectionInput[]>(
    template?.sections || []
  );
  const [visibility, setVisibility] = useState<CallAiSummaryTemplateVisibility>(
    template?.visibility || CallAiSummaryTemplateVisibility.Private
  );
  const [isCurrentUserDefault, setIsCurrentUserDefault] = useState<boolean>(
    template?.isCurrentUserDefault || false
  );
  const [defaultFor, setDefaultFor] = useState<CallAiSummaryTemplateDefaultFor>(
    template?.defaultFor || CallAiSummaryTemplateDefaultFor.None
  );
  const [defaultForPersona, setDefaultForPersona] = useState<
    UserPersona | undefined
  >(template?.defaultForPersona || undefined);
  const [defaultForKeywords, setDefaultForKeywords] = useState<string>(
    (template?.defaultForKeywords || []).join(", ")
  );
  const [defaultForMeetingType, setDefaultForMeetingType] =
    useState<CustomTemplateType>(
      template?.defaultForMeetingType || CustomTemplateType.Interview
    );

  // if the visibility is set to private and the user is not the creator,
  // the "user default" switch should be turned off and disabled.
  // This occurs when an admin edits an org visible template and toggles the
  // visibility to off. The admin will lose access as soon as they save,
  // so they should not be able to have this template as their default.
  useEffect(() => {
    if (
      visibility === CallAiSummaryTemplateVisibility.Private &&
      template?.creator.email !== currentUser.email
    ) {
      setIsCurrentUserDefault(false);
    }
  }, [visibility, template, currentUser.email]);
  const myDefaultDisabled = Boolean(
    visibility === CallAiSummaryTemplateVisibility.Private &&
      template &&
      template.creator.id !== currentUser.id
  );

  const handleMove = useCallback(
    (fromIndex: number, toIdx: number): void => {
      if (!sections) {
        return;
      }
      const item = sections[fromIndex];
      if (!item) return;
      if (toIdx >= sections.length) return;

      let toIndex = toIdx;
      const movingDown = toIndex >= fromIndex;
      if (movingDown) toIndex += 1;

      const newList = [] as Array<CustomTemplateSectionInput>;
      for (let i = 0; i < sections.length; i += 1) {
        // eslint-disable-next-line
        if (i === fromIndex) continue;
        if (i === toIndex) {
          newList.push(sections[fromIndex]);
        }
        newList.push(sections[i]);
      }
      if (toIndex === sections.length) {
        newList.push(sections[fromIndex]);
      }
      setSections(newList);
    },
    [sections]
  );

  const onAddSection = useCallback(() => {
    const id = Math.random().toString(36).substr(2, 9);
    setSections((prev) => [...prev, { id, title: "", prompt: [""] }]);
  }, []);

  const onDeleteSection = useCallback((id: string) => {
    setSections((prev) => prev.filter((section) => section.id !== id));
  }, []);

  const updateSection = (
    index: number,
    title: string,
    prompt: string
  ): void => {
    const newSections = [...sections];
    const promptArr = [prompt];
    newSections[index] = {
      ...newSections[index],
      title,
      prompt: promptArr,
    };
    setSections(newSections);
  };

  const [validating, setValidating] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  const saveTemplate = (): void => {
    setValidating(true);
    setValidationError(null);
    onSave(
      {
        id: template?.id,
        name: templateName,
        isCreatorDefault: template?.isCreatorDefault || false,
        sections,
        customType,
        customPrompt,
        visibility,
        isCurrentUserDefault,
        defaultFor,
        defaultForPersona,
        defaultForKeywords: defaultForKeywords.split(",").map((k) => k.trim()),
        defaultForMeetingType,
      },
      (validationError: string | null) => {
        setValidating(false);
        setValidationError(validationError);
      }
    );
  };

  const formLabelProps = {
    fontWeight: "semibold",
    fontSize: "md",
  };

  return (
    <Box>
      <FullscreenHeader>
        <Flex height="40px" my={3} flexDir="row" alignItems="center">
          <IconButton
            aria-label="Back"
            icon={<HiArrowLeft size="24" />}
            color="gray.900"
            variant="icon"
            onClick={onBack}
            mr={9}
          />
          <Box fontSize="xl" fontWeight="medium">
            {template ? "Edit" : "New"} AI notes template
          </Box>
          <Button
            ml="auto"
            size="prismMd"
            variant="prismTertiary"
            onClick={() => onClose(template?.id)}
          >
            Cancel
          </Button>
          <Button
            ml={6}
            size="prismMd"
            variant="prismPrimary"
            onClick={saveTemplate}
            disabled={validating}
            isLoading={validating}
          >
            Save
          </Button>
          {template?.id && onCopy && (
            <Menu variant="ghost">
              <MenuButton
                as={IconButton}
                ml={6}
                icon={<HiDotsVertical />}
                variant="ghost"
                size="sm"
              />
              <MenuList>
                <>
                  <MenuItem
                    value="duplicate"
                    onClick={() => {
                      onCopy(template.id || "");
                    }}
                    fontSize="sm"
                  >
                    <HiOutlineDuplicate size="20px" />
                    <Box flex="1" ml="2">
                      Duplicate
                    </Box>
                  </MenuItem>
                  <MenuDivider />
                  <DeleteTemplateMenuItem
                    templateId={template.id}
                    onDeleted={onClose}
                    fontSize="sm"
                  />
                </>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Box
          height={1}
          background="linear-gradient(99.01deg, #0074DD -2.93%, #591EFF 58.85%, #9644FF 126.18%)"
        />
      </FullscreenHeader>
      <TemplateFormContent>
        <TemplateFormColumn flex="4" pr={[0, 0, 8, 8]}>
          <Text color="gray.600" fontSize="sm" mb={6}>
            AI responses may be inaccurate or incomplete, so verify them before
            use. They are intended to assist in the hiring process, but should
            not be used to make hiring decisions.
          </Text>
          <FormControl mb="6">
            <FormLabel {...formLabelProps}>Template name</FormLabel>
            <Input
              placeholder="Eg, Screening Call"
              _placeholder={{ color: "gray.400" }}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              fontSize="sm"
              autoFocus
            />
          </FormControl>

          {isLegacyTemplate && (
            <FormControl mt={1} mb={8}>
              <FormLabel color="gray.800" mb={0} {...formLabelProps}>
                Template type
              </FormLabel>
              <RadioGroup
                value={customType}
                onChange={(e) => setCustomType(e as CustomTemplateCustomType)}
                fontSize="sm"
                size="prismMd"
              >
                <Flex flexDir="column">
                  <Radio
                    key={CustomTemplateCustomType.Sections}
                    value={CustomTemplateCustomType.Sections}
                    size="prismMd"
                  >
                    <Text as="span" fontWeight="medium">
                      Basic:
                    </Text>{" "}
                    Define sections and what to include in them.
                  </Radio>
                  <Radio
                    key={CustomTemplateCustomType.CustomPrompt}
                    value={CustomTemplateCustomType.CustomPrompt}
                    size="prismMd"
                  >
                    <Text as="span" fontWeight="medium">
                      Advanced:
                    </Text>{" "}
                    Directly author the AI prompt.
                    <Button
                      variant="prismSecondary"
                      size="prismSm"
                      ml={2}
                      onClick={switchToExamplePrompts}
                    >
                      Example prompts
                    </Button>
                  </Radio>
                </Flex>
              </RadioGroup>
            </FormControl>
          )}

          {customType === "SECTIONS" && (
            <SectionsForm
              sections={sections}
              updateSection={updateSection}
              onDeleteSection={onDeleteSection}
              onAddSection={onAddSection}
              handleMove={handleMove}
              headerFontSize="sm"
            />
          )}
          {validationError && (
            <Alert status="error" description="" mb={4}>
              <Box ml={-2}>
                <Text fontSize="sm">
                  This template cannot be saved because it may contain questions
                  about protected candidate information, a request for an
                  automated employment decisions, or contain inappropriate
                  content:
                </Text>
                <UnorderedList mt={3} fontSize="sm" fontWeight="medium">
                  <ListItem>{validationError}</ListItem>
                </UnorderedList>
                <Text mt={3} fontSize="sm">
                  Please reach out to support@brighthire.ai if you believe this
                  is in error.
                </Text>
              </Box>
            </Alert>
          )}

          {customType === "CUSTOM_PROMPT" && (
            <FormControl
              mt={0}
              mb="4"
              flexGrow="1"
              display="flex"
              flexDir="column"
            >
              <Flex alignItems="center" mb="2">
                <FormLabel {...formLabelProps} mb={0}>
                  AI prompt
                </FormLabel>
                {!isLegacyTemplate && (
                  <Button
                    variant="prismSecondary"
                    size="prismMd"
                    ml={4}
                    onClick={switchToExamplePrompts}
                  >
                    Example prompts
                  </Button>
                )}
              </Flex>
              <Textarea
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                fontSize="sm"
                flexGrow="1"
                resize="none"
                maxH="400px"
                placeholder="Enter an AI prompt describing the summary you want to generate, or browse the example prompts for ideas."
              />
            </FormControl>
          )}
        </TemplateFormColumn>
        <TemplateFormColumn
          flex="2"
          pl={[0, 0, 8, 8]}
          pr={[0, 0, 8, 8]}
          pt={[0, 0, 8, 8]}
          borderLeft={["0", "0", "1px solid", "1px solid"]}
          borderColor={["gray.200", "gray.200", "gray.200", "gray.200"]}
          overflowY={["inherit", "inherit", "auto", "auto"]}
        >
          {currentUserCanManageTemplates && (
            <>
              <RadioSection
                formLabel="Visibility"
                value={visibility}
                setValue={setVisibility}
                isDisabled={!canManageVisibility}
                options={[
                  {
                    value: CallAiSummaryTemplateVisibility.Private,
                    preLabel: "Private",
                    label: `Only the author (${
                      !template || template?.creator.email === currentUser.email
                        ? "you"
                        : template?.creator.email
                    }) can view and edit this template.`,
                  },
                  // TODO: Generate Public label dynamically based on org permissions
                  {
                    value: CallAiSummaryTemplateVisibility.Public,
                    preLabel: "Organization",
                    label:
                      "All org users can view and use this template. Users with template management permissions will also be able to edit it.",
                  },
                ]}
              />
              {visibility === CallAiSummaryTemplateVisibility.Public && (
                <FormControl mt={5}>
                  <TemplateFormLabel>
                    Assign template as default for others
                  </TemplateFormLabel>
                  <RadioGroup
                    value={defaultFor}
                    onChange={(e) =>
                      setDefaultFor(e as CallAiSummaryTemplateDefaultFor)
                    }
                    size="prismMd"
                  >
                    <Flex flexDir="column">
                      <Radio
                        value={CallAiSummaryTemplateDefaultFor.None}
                        size="prismMd"
                        height="10"
                        mt="0"
                      >
                        No one
                      </Radio>
                      <Radio
                        value={CallAiSummaryTemplateDefaultFor.All}
                        size="prismMd"
                        height="10"
                        mt="0"
                      >
                        Everyone
                      </Radio>
                      <RadioWithSelectWrapper>
                        <Radio
                          value={CallAiSummaryTemplateDefaultFor.Persona}
                          size="prismMd"
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            User groups
                          </span>
                        </Radio>
                        <Select
                          placeholder="Select"
                          isDisabled={
                            defaultFor !==
                            CallAiSummaryTemplateDefaultFor.Persona
                          }
                          value={defaultForPersona || undefined}
                          onChange={(e) =>
                            setDefaultForPersona(e.target.value as UserPersona)
                          }
                          ml={2}
                          fontSize="sm"
                        >
                          <option value={UserPersona.Interviewer}>
                            Interviewer
                          </option>
                          <option value={UserPersona.Recruiter}>
                            Recruiter
                          </option>
                          <option value={UserPersona.HiringManager}>
                            Hiring Manager
                          </option>
                          <option value={UserPersona.TaLeader}>
                            TA Leader
                          </option>
                          <option value={UserPersona.CoordinatorOps}>
                            Coordinator Ops
                          </option>
                        </Select>
                      </RadioWithSelectWrapper>
                      <RadioWithSelectWrapper>
                        <Radio
                          value={CallAiSummaryTemplateDefaultFor.MeetingType}
                          size="prismMd"
                        >
                          <Flex flexDir="row">
                            <span style={{ whiteSpace: "nowrap" }}>
                              Meeting type
                            </span>
                            <InfoIcon />
                          </Flex>
                        </Radio>
                        <Select
                          placeholder="Select meeting type"
                          isDisabled={
                            defaultFor !==
                            CallAiSummaryTemplateDefaultFor.MeetingType
                          }
                          value={defaultForMeetingType}
                          onChange={(e) =>
                            setDefaultForMeetingType(
                              e.target.value as CustomTemplateType
                            )
                          }
                          ml={2}
                          fontSize="sm"
                        >
                          <option value={CustomTemplateType.Interview}>
                            Interview
                          </option>
                          <option value={CustomTemplateType.Debrief}>
                            Debrief
                          </option>
                          <option value={CustomTemplateType.Intake}>
                            Intake
                          </option>
                        </Select>
                      </RadioWithSelectWrapper>
                      <Radio
                        value={CallAiSummaryTemplateDefaultFor.Keywords}
                        size="prismMd"
                        alignItems="start"
                      >
                        Meetings with any of the following keywords in title:
                      </Radio>
                      {/* Input for keywords */}
                      <Box pl={10}>
                        <Input
                          placeholder="Enter keywords, separated by commas"
                          _placeholder={{
                            color:
                              defaultFor !==
                              CallAiSummaryTemplateDefaultFor.Keywords
                                ? "gray.200"
                                : "gray.400",
                          }}
                          isDisabled={
                            defaultFor !==
                            CallAiSummaryTemplateDefaultFor.Keywords
                          }
                          value={defaultForKeywords}
                          onChange={(e) => {
                            setDefaultForKeywords(e.target.value);
                          }}
                          fontSize="sm"
                        />
                      </Box>
                    </Flex>
                  </RadioGroup>
                </FormControl>
              )}
              <Divider my={6} />
            </>
          )}
          <TemplateFormLabel>User Preferences</TemplateFormLabel>
          <Switch
            isChecked={isCurrentUserDefault}
            onChange={(e) => setIsCurrentUserDefault(e.target.checked)}
            colorScheme="prism"
            display="flex"
            alignItems="center"
            mb={4}
            fontSize="sm"
            lineHeight={1.5}
            isDisabled={myDefaultDisabled}
          >
            Make this my default template
          </Switch>
          <InfoText>
            Automatically apply this template to your AI notes when viewing
            meetings. Your other templates will still be available to choose
            from.
          </InfoText>
        </TemplateFormColumn>
      </TemplateFormContent>
    </Box>
  );
};
const TemplateFormContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const height = [
    "auto",
    "auto",
    `calc(100vh - ${pageHeaderHeight})`,
    `calc(100vh - ${pageHeaderHeight})`,
  ];

  return (
    <Flex
      overflowY="auto"
      height={height}
      flex="1"
      flexDir={["column", "column", "row", "row"]}
      pb={[8, 8, 0, 0]}
    >
      {children}
    </Flex>
  );
};

const TemplateFormColumn: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      pt={8}
      pb={4}
      height="100%"
      overflowY="auto"
      flex="1"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

const TemplateFormLabel: React.FC<{
  tooltipLabel?: string;
  children: React.ReactNode;
}> = ({ tooltipLabel, children }) => (
  <Tooltip label={tooltipLabel} placement="right" isDisabled={!tooltipLabel}>
    <FormLabel
      color="gray.600"
      fontWeight="600"
      textTransform="uppercase"
      fontSize="xs"
    >
      {children}
    </FormLabel>
  </Tooltip>
);

const RadioWithSelectWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Flex align="start" mb="10px">
    {children}
  </Flex>
);

const RadioSection: React.FC<{
  value: string;
  setValue: (value: any) => void;
  options: { value: any; label: string; preLabel?: string }[];
  tooltipLabel?: string;
  formLabel: string;
  isDisabled?: boolean;
}> = ({ value, setValue, options, tooltipLabel, formLabel, isDisabled }) => {
  return (
    <FormControl mt={6} isDisabled={isDisabled}>
      <TemplateFormLabel tooltipLabel={tooltipLabel}>
        {formLabel}
      </TemplateFormLabel>
      <RadioGroup value={value} onChange={(v) => setValue(v)} fontSize="sm">
        <Flex flexDir="column">
          {options.map((option) => (
            <Radio
              key={option.value}
              value={option.value}
              size="prismMd"
              alignItems="flex-start"
            >
              {option.preLabel && (
                <Text as="span" fontWeight="medium">
                  {`${option.preLabel}: `}
                </Text>
              )}
              {option.label}
            </Radio>
          ))}
        </Flex>
      </RadioGroup>
    </FormControl>
  );
};

const InfoText: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Text fontSize="sm" color="gray.600" fontWeight="400" mb="4">
    {children}
  </Text>
);

const InfoIcon = (): JSX.Element => (
  <Flex ml="2" width={4} height={4} alignItems="center">
    <Tooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="sm"
      shouldWrapChildren
      fontWeight="normal"
      placement="left"
      label={
        <>
          Add intake@brighthire.ai or debrief@brighthire.ai to the calendar
          event as an attendee to designate the meeting type.
        </>
      }
    >
      <Icon boxSize={4} color="gray.500" as={HiOutlineInformationCircle} />
    </Tooltip>
  </Flex>
);

export default EditCustomTemplate;
