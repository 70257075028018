import React from "react";

import { SettingsPageContainer, useToast } from "../../../components";
import {
  FeatureName,
  OrganizationFragment,
  useUpdateOrganizationGoogleMeetMutation,
} from "../../graphql";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";
import GoogleMeetSettingsForm from "./GoogleMeetSettingsForm";

interface GoogleMeetSettingsProps {
  organization: OrganizationFragment;
}

const GoogleMeetSettings: React.FC<GoogleMeetSettingsProps> = ({
  organization,
}) => {
  const toast = useToast();
  const [updateGoogleMeetSettings, { loading, error }] =
    useUpdateOrganizationGoogleMeetMutation();

  const hasVirtualOnsiteAccess = useHasFeatureAccess(
    FeatureName.VirtualOnsiteSplitting
  );

  return (
    <SettingsPageContainer heading="Google Meet notetaker">
      <GoogleMeetSettingsForm
        organizationName={organization.name}
        googleMeetEnabled={organization.googleMeetEnabled}
        googleMeetRecordingDisclaimer={
          organization.googleMeetRecordingDisclaimer
        }
        googleMeetRecordingDisclaimerType={
          organization.googleMeetRecordingDisclaimerType
        }
        googleMeetDisplayImageBucketPath={
          organization.googleMeetDisplayImageBucketPath
        }
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        defaultDisplayName={organization.googleMeetDisplayName}
        showVirtualOnsiteSetting={hasVirtualOnsiteAccess}
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledGoogleMeet}
        isLoading={loading}
        error={error}
        onSubmit={(formData) =>
          updateGoogleMeetSettings({ variables: formData })
            .then((result) => {
              if (result.data?.updateOrganizationGoogleMeet?.organization) {
                toast({
                  status: "success",
                  title: "Google Meet Settings",
                  description: "Saved!",
                });
              }
            })
            .catch((error) => {
              toast({
                status: "error",
                title: "Google Meet Settings",
                description:
                  error?.message ?? "There was a problem - please try again",
              });
            })
        }
      />
    </SettingsPageContainer>
  );
};

export default GoogleMeetSettings;
