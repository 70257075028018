import { Text } from "@chakra-ui/react";
import React from "react";

import {
  errorToast,
  LoadingIndicator,
  useToast,
  ZoomSettings,
} from "../../../../components";
import { useUserZoomSettingsQuery } from "../../../graphql";

export const ZoomNativeUserSettings: React.FC = () => {
  const toast = useToast();
  const { data: zoomNativeData, loading: zoomNativeLoading } =
    useUserZoomSettingsQuery({
      onError: () => {
        errorToast(
          toast,
          "There was a problem fetching your Zoom account settings"
        );
      },
    });

  const settingsJson = zoomNativeData?.currentUser?.zoomSettings;
  const zoomUserType = zoomNativeData?.currentUser?.zoomUserType;

  return (
    <>
      {zoomNativeLoading ? (
        <LoadingIndicator mt="20" />
      ) : settingsJson ? (
        <>
          <Text mb={8} fontSize="sm" color="placeholder">
            These values show whether your Zoom settings are configured
            correctly to work with BrightHire.
          </Text>
          <ZoomSettings
            zoomUserType={zoomUserType}
            settingsJson={settingsJson}
          />
        </>
      ) : (
        <Text fontSize="sm">
          {/* cspell:disable-next-line */}
          We couldn&apos;t find a Zoom user that matches your email address.
        </Text>
      )}
    </>
  );
};
