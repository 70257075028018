import { FeatureName } from "../graphql";
import useCurrentUser from "./useCurrentUser";

/** Returns whether the given feature is visible to the current user */
export const useHasFeatureAccess = (feature: FeatureName): boolean => {
  const { organization } = useCurrentUser();
  const featureAccess = organization.featureAccess?.find(
    (item) => item.name === feature
  );
  return featureAccess?.isVisible ?? false;
};
