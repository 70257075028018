import { Box, Flex, HStack, Select, useBoolean } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { Alert, Button, SearchInput } from "../../../components";
import {
  useNumberSearchParam,
  useSearchParam,
} from "../../../hooks/useSearchParam";
import { getAllowedLimitValues } from "../../../utils/pagination";
import CallGuideList from "../../components/CallGuideList/CallGuideList";
import EditCallGuidesModal from "../../components/CallGuideMenu/EditGuidesModal";
import {
  CallGuideListItemFragment,
  CurrentUserCallGuidesQuery,
  CurrentUserSharedCallGuidesQuery,
  useCurrentUserCallGuidesLazyQuery,
  useCurrentUserSharedCallGuidesLazyQuery,
} from "../../graphql";
import AssignedGuidesTable from "./AssignedGuidesTable";

const DEFAULT_LIMIT = 50;

const GuidesList: React.FC<{
  category: "my" | "shared" | "assigned";
  active: boolean;
}> = ({ category, active }) => {
  const location = useLocation();
  const [query, setQuery] = useSearchParam("q");
  const [limit, setLimit] = useNumberSearchParam("limit", DEFAULT_LIMIT);
  const limits = useMemo((): number[] => {
    return getAllowedLimitValues(limit ?? DEFAULT_LIMIT);
  }, [limit, location.pathname]);

  const [getCallGuides, callGuidesQuery] = useCurrentUserCallGuidesLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [getSharedCallGuides, sharedCallGuidesQuery] =
    useCurrentUserSharedCallGuidesLazyQuery({
      fetchPolicy: "cache-and-network",
    });

  const { called, loading, error, data } =
    category === "my" ? callGuidesQuery : sharedCallGuidesQuery;

  useEffect(() => {
    if (active && (query || limit)) {
      const variables = { query, limit };

      if (category === "my") {
        getCallGuides({ variables });
      } else if (category === "shared") {
        getSharedCallGuides({ variables });
      }
    }
  }, [active, category, query, limit]);

  let guides: CallGuideListItemFragment[] = [];
  if (category === "my") {
    const d = data as CurrentUserCallGuidesQuery;
    guides = d?.currentUser?.callGuides ?? [];
  } else if (category === "shared") {
    const d = data as CurrentUserSharedCallGuidesQuery;
    guides = d?.currentUser?.sharedCallGuides ?? [];
  }

  const showLimit = category !== "assigned";

  const [selectedGuides, setSelectedGuides] = useState<string[]>([]);
  const [editModalVisible, setEditModalVisible] = useBoolean();

  const search = (
    <Flex py={4} flexFlow="row nowrap" width="100%">
      <SearchInput
        height="40px"
        pr={4}
        maxWidth="450px"
        defaultValue={query}
        placeholder="Search guide, position, candidate, or owner name"
        onSearch={(updatedQuery: string) => {
          if (updatedQuery !== query) {
            setQuery(updatedQuery);
          }
        }}
      />
      <Flex alignItems="center">
        <Button
          leftIcon={<HiOutlinePencil />}
          colorScheme="blue"
          variant="outline"
          size="sm"
          disabled={!selectedGuides.length}
          onClick={() => {
            setEditModalVisible.on();
          }}
          mr={4}
        >
          Edit guide{selectedGuides.length === 1 ? "" : "s"}
        </Button>
        <HStack hidden={!showLimit}>
          <Box px={2} whiteSpace="nowrap">
            Max results
          </Box>
          <Select
            defaultValue={limit ?? DEFAULT_LIMIT}
            size="sm"
            bg="white"
            width="120px"
            borderRadius="md"
            onChange={(e: React.SyntheticEvent<HTMLSelectElement, Event>) => {
              setLimit(parseInt(e.currentTarget.value, 10));
            }}
          >
            {limits.map((l) => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </Select>
        </HStack>
      </Flex>
    </Flex>
  );

  if (error)
    return (
      <>
        {search}
        <Alert
          status="error"
          title="Error loading guides"
          description={error.message}
        />
      </>
    );

  return (
    <>
      <EditCallGuidesModal
        isOpen={editModalVisible}
        onClose={setEditModalVisible.off}
        guideIds={selectedGuides}
      />
      <Box mt="4">{search}</Box>

      {category === "assigned" ? (
        <AssignedGuidesTable query={(query || "").toLocaleLowerCase()} />
      ) : (
        <CallGuideList
          callGuides={guides}
          onSelectionChange={setSelectedGuides}
          loading={!called || loading}
          showPosition
          showSharing={category === "my"}
          showOwner={category === "shared"}
        />
      )}
    </>
  );
};

export default GuidesList;
