import { useMemo } from "react";

import {
  dateRange6MonthsAgo,
  SelectDateRangeState,
} from "../../../../components/SelectDateRange/SelectDateRange";
import {
  useEnumSearchParam,
  useSearchParam,
  useSelectDateRangeParams,
  useStringArrayParam,
} from "../../../../hooks/useSearchParam";
import { formatDateRange } from "../../../../utils/datetime";
import {
  AnalyticsDimension,
  CompensationExtractionCategory,
  CompensationExtractionCategoryType,
  CompensationExtractionRateType,
} from "../../../graphql";
import { MultiSelect, SingleSelect } from "../types";

export type CompensationCategoryOption =
  | CompensationExtractionCategoryType
  | "ALL";

export const FRIENDLY_CATEGORY_NAMES: Record<
  CompensationExtractionCategory,
  string
> = {
  INTERVIEWER_STATED: "Interviewer stated",
  CANDIDATE_CURRENT: "Candidate current",
  CANDIDATE_EXPECTED: "Candidate expected",
};

const RATE_OPTIONS = [
  { label: "Annual Salary", value: CompensationExtractionRateType.Annual },
  { label: "Hourly Rate", value: CompensationExtractionRateType.Hourly },
];

export type CompensationTrendsConfig = {
  primaryDimension: SingleSelect<AnalyticsDimension>;
  secondaryDimension: SingleSelect<AnalyticsDimension>;
  positions: MultiSelect;
  departments: MultiSelect;
  interviewers: MultiSelect;
  stages: MultiSelect;
  category: SingleSelect<CompensationCategoryOption>;
  currency: SingleSelect<string>;
  rate: SingleSelect<CompensationExtractionRateType>;
  dateRange: {
    value: SelectDateRangeState;
    displayValue: string;
    setValue(value: SelectDateRangeState | undefined): void;
  };
  filters: {
    positions: string[];
    departments: string[];
    interviewers: string[];
    stages: string[];
  };
  enabledFilters: {
    positions: boolean;
    departments: boolean;
    interviewers: boolean;
    stages: boolean;
  };
};

/**
 * Manages the state of user-selectable analytics configurations such as
 * filters, dimensions, and chart settings.
 *
 * Only manages the config, but not the query execution.
 */
const useCompensationTrendsConfig = (
  defaultDateRange = dateRange6MonthsAgo
): CompensationTrendsConfig => {
  const [positions, setPositions] = useStringArrayParam("positions");
  const [departments, setDepartments] = useStringArrayParam("departments");
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    defaultDateRange()
  );
  const [category, setCategory] =
    useEnumSearchParam<CompensationCategoryOption>("category", "ALL");
  const [currency, setCurrency] = useSearchParam("currency", "");
  const [rate, setRate] = useSearchParam(
    "rate",
    CompensationExtractionRateType.Annual
  );

  const filters = {
    positions: positions || [],
    departments: departments || [],
    interviewers: [],
    stages: [],
  };

  const displayDateRangeValue = useMemo(() => {
    if (dateRange) {
      return formatDateRange(dateRange.start, dateRange.end);
    }
    return "";
  }, [dateRange]);

  return {
    primaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    secondaryDimension: {
      value: AnalyticsDimension.None,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setValue: () => {},
      options: [],
    },
    category: {
      value: category || "ALL",
      setValue: setCategory,
      options: [],
    },
    currency: {
      value: currency || "",
      setValue: setCurrency,
      options: [],
    },
    rate: {
      value: rate as CompensationExtractionRateType,
      setValue: setRate,
      options: RATE_OPTIONS,
    },
    interviewers: {
      values: [],
      setValues: () => 0,
    },
    stages: {
      values: [],
      setValues: () => 0,
    },
    positions: {
      values: positions,
      setValues: setPositions,
    },
    departments: {
      values: departments,
      setValues: setDepartments,
    },
    dateRange: {
      value: dateRange,
      displayValue: displayDateRangeValue,
      setValue: setDateRange,
    },
    filters,
    enabledFilters: {
      positions: true,
      departments: true,
      interviewers: false,
      stages: false,
    },
  };
};

export default useCompensationTrendsConfig;
