import {
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useState } from "react";
import { HiOutlineSparkles } from "react-icons/hi2";

import { useSendGAEvent } from "../../../../../utils/googleAnalytics";
import {
  CallBetaFragment,
  FeatureName,
  TranscriptionStatus,
} from "../../../../graphql";
import useFeatureFlag from "../../../../graphql/hooks/useFeatureFlag";
import { useHasFeatureAccess } from "../../../../hooks/useHasFeatureAccess";
import { Notes } from "./Notes/Notes";
import { Summary } from "./Summary/Summary";
import SummaryComingSoon from "./Summary/SummaryComingSoon";

type AiTabProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "transcriptionStatus"
    | "aiNotesCustomFormat"
    | "aiNotesSupportedFormats"
    | "duration"
  >;
  hasLabeledCandidate: boolean;
  onClickTimestamp(t: number): void;
};

const TAB_NAMES = ["Notes", "Summary"];

const TAB_STYLES: TabProps = {
  _hover: {
    backgroundColor: "gray.100",
  },
  _selected: {
    color: "purple.600 !important",
    fontWeight: "600",
    border: "3px solid",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    borderColor: "purple.600 !important",
    borderRadius: "none",
  },
  background: "white",
  color: "purple.600",
  whiteSpace: "nowrap",
  borderRadius: "none",
  h: "10",
  px: "2",
  flex: "1",
  maxW: "115px",
  fontWeight: "500",
};

const DISABLED_STYLES: TabProps = {
  isDisabled: true,
  pointerEvents: "none",
  userSelect: "none",
};

const AiTab: React.FC<AiTabProps> = ({
  call,
  hasLabeledCandidate,
  onClickTimestamp,
}) => {
  const sendGAEvent = useSendGAEvent();
  const [tabIndex, setTabIndexState] = useState(0);
  const writeupEnabled = useFeatureFlag("ai-summary:writeup");
  const summaryEnabled = useHasFeatureAccess(FeatureName.AiSummary);
  const setTabIndex = (idx: number): void => {
    if (idx !== tabIndex) {
      setTabIndexState(idx);
      sendGAEvent(
        "sidebar_tab_change",
        "call_review",
        TAB_NAMES[idx],
        undefined,
        {
          callId: call.id,
        }
      );
      LogRocket.track("ai-notes-tab-change", { tab: TAB_NAMES[idx] });
    }
  };

  return (
    <Tabs
      display="flex"
      flexDir="column"
      height="100%"
      index={tabIndex}
      onChange={setTabIndex}
      isLazy
      lazyBehavior="keepMounted"
    >
      <TabList
        alignItems="center"
        bg="white"
        borderRadius="0"
        borderBottom="1px"
        display="flex"
        justifyContent="flex-start"
        paddingBottom="2px"
        overflow="hidden"
        mx="5"
      >
        <Tab {...TAB_STYLES} data-testid="ai-notes-tab" key="ai-notes">
          <Icon mr={1} as={HiOutlineSparkles} />
          {TAB_NAMES[0]}
        </Tab>
        <Tab
          {...TAB_STYLES}
          {...(!summaryEnabled ? DISABLED_STYLES : {})}
          data-testid="ai-summary-tab"
          key="ai-summary"
        >
          <Icon mr={1} as={HiOutlineSparkles} />
          {TAB_NAMES[1]}
        </Tab>
      </TabList>
      <TabPanels overflowY="auto" flex="1" px="5">
        <TabPanel key="ai-notes">
          <Notes
            call={call}
            hasLabeledCandidate={hasLabeledCandidate}
            onClickTimestamp={onClickTimestamp}
          />
        </TabPanel>
        <TabPanel key="ai-summary" pb="5">
          {!writeupEnabled && <SummaryComingSoon />}
          {writeupEnabled && (
            <Summary
              callId={call.id}
              transcriptAvailable={
                call.transcriptionStatus === TranscriptionStatus.Completed
              }
              callDuration={call.duration}
              onClickTimestamp={onClickTimestamp}
            />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default AiTab;
