import React from "react";

import { SettingsPageContainer, useToast } from "../../../components";
import {
  FeatureName,
  OrganizationFragment,
  useUpdateOrganizationTeamsNotetakerMutation,
} from "../../graphql";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";
import MsftTeamsSettingsForm from "./MsftTeamsSettingsForm";

interface TeamsNotetakerSettingsProps {
  organization: OrganizationFragment;
}

const MsftTeamsSettings: React.FC<TeamsNotetakerSettingsProps> = ({
  organization,
}) => {
  const toast = useToast();
  const hasVirtualOnsiteAccess = useHasFeatureAccess(
    FeatureName.VirtualOnsiteSplitting
  );

  const [updateTeamsNotetakerSettings, { loading, error }] =
    useUpdateOrganizationTeamsNotetakerMutation();

  return (
    <SettingsPageContainer heading="Microsoft Teams notetaker">
      <MsftTeamsSettingsForm
        organizationName={organization.name}
        teamsNotetakerEnabled={organization.teamsNotetakerEnabled}
        teamsNotetakerDisclaimer={organization.teamsNotetakerDisclaimer}
        teamsNotetakerDisclaimerType={organization.teamsNotetakerDisclaimerType}
        teamsNotetakerDisplayImageBucketPath={
          organization.teamsNotetakerDisplayImageBucketPath
        }
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        defaultDisplayName={organization.teamsNotetakerDisplayName}
        showVirtualOnsiteSetting={hasVirtualOnsiteAccess}
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledMsTeams}
        isLoading={loading}
        error={error}
        onSubmit={(formData) => {
          return updateTeamsNotetakerSettings({ variables: formData })
            .then((result) => {
              if (result.data?.updateOrganizationTeamsNotetaker?.organization) {
                toast({
                  status: "success",
                  title: "Microsoft Teams Notetaker Settings",
                  description: "Saved!",
                });
              }
            })
            .catch((error) => {
              toast({
                status: "error",
                title: "Microsoft Teams Notetaker Settings",
                description:
                  error?.message ?? "There was a problem - please try again",
              });
            });
        }}
      />
    </SettingsPageContainer>
  );
};

export default MsftTeamsSettings;
