import {
  Checkbox,
  FormControl,
  FormHelperText,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import {
  Alert,
  Button,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationAtsSettingsMutation,
} from "../../graphql";

interface AtsSettingsProps {
  currentUser: CurrentUserFragment;
}

export interface FormData {
  postNotesToAts: boolean;
}

const AtsSettings: React.FC<AtsSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const [updateOrganizationSettings, { loading, error }] =
    useUpdateOrganizationAtsSettingsMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationAtsSettings?.currentUser) {
          toast({
            status: "success",
            title: "Organization Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Organization Settings",
          description: "There was a problem - please try again",
        });
      },
    });

  const { postNotesToAts } = currentUser.organization;

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      postNotesToAts,
    },
  });

  return (
    <SettingsPageContainer heading="ATS settings">
      <form
        autoComplete="on"
        onSubmit={handleSubmit((formData) => {
          updateOrganizationSettings({ variables: formData });
        })}
      >
        {error?.graphQLErrors?.map(({ message }, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Alert key={i} status="error" description={message} />
        ))}
        <VStack spacing="8" align="start">
          <FormControl id="postNotesToAts">
            <Checkbox
              {...register("postNotesToAts")}
              defaultChecked={postNotesToAts}
            >
              Post interviewer notes and reactions to ATS Activity Feed
            </Checkbox>
            <FormHelperText>
              After each interview, BrightHire will post the interviewer&apos;s
              name, a recording link, and the interview notes and reactions to
              the candidate profile on the ATS. Unchecking this option will
              remove the notes and reactions from being posted to the ATS.
            </FormHelperText>
          </FormControl>
          <Button type="submit" isLoading={loading} data-testid="save">
            Save
          </Button>
        </VStack>
      </form>
    </SettingsPageContainer>
  );
};

export default AtsSettings;
