import { useEffect, useState } from "react";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import useInterviewAssistant from "../../../utils/popup";
import {
  AddBrightHireToInterviewsMutation,
  InterviewAssistantEntryPoint,
  ScheduledInterviewListItemFragment,
  useAddBrightHireToInterviewsMutation,
} from "../../graphql";
import { getErrorContent } from "../../pages/integrations/getErrorContent";
import { ButtonAction, LaunchInterviewButton } from "./types";

type UseScheduledInterviewLaunchButtonParams = {
  scheduledInterview: ScheduledInterviewListItemFragment;
  interviewAssistantEntryPoint?: InterviewAssistantEntryPoint;
  showUpdating?: "updating" | "updateFailed";
  onAddBrightHire?(results: AddBrightHireToInterviewsMutation): void;
  onAddBrightHireManuallyError?(msg: string): void;
};

type UseScheduledInterviewLaunchButtonReturn = {
  /** Button properties for the Launch Interview button */
  button: LaunchInterviewButton;
  /** Handles opening the IA for an in-person interview */
  onStartInPerson(): void;
  /** Reason why the interview can't be launched */
  cantLaunchInterviewReason: string | null;
  /** TBD if this is actually used anywhere... */
  zoomUrl: string;
};

/**
 * Returns data on the scheduled interview launch button state, and manages
 * the `AddBrightHireToInterviews` mutation
 */
export const useScheduledInterviewLaunchButton = ({
  scheduledInterview,
  interviewAssistantEntryPoint,
  showUpdating,
  onAddBrightHire,
  onAddBrightHireManuallyError,
}: UseScheduledInterviewLaunchButtonParams): UseScheduledInterviewLaunchButtonReturn => {
  const { openInPersonIA, openInterviewAssistant } = useInterviewAssistant();
  const sendGAEvent = useSendGAEvent();
  const [isUpdating, setIsUpdating] = useState(false);

  /**
   * Kick off import if the scheduled interview has a zoom ID set already from
   * among its attached meetings. If it doesn't we shouldn't get here anyway but
   * error fallback included just in case
   */
  const addBrightHireManually = (): void => {
    setButton({ action: ButtonAction.LOADING });
    if (
      scheduledInterview?.zoomId ||
      scheduledInterview?.googleMeetMeetingUrl ||
      scheduledInterview?.teamsMeetingUrl
    ) {
      setIsUpdating(true);
      sendGAEvent("add_bh", "calling", "single", scheduledInterview?.id);
      addBrightHire({
        variables: {
          scheduledInterviewIds: [scheduledInterview?.id],
          source: "listitem",
        },
      });
    } else {
      const message = getErrorContent("Meeting not found");
      setButtonDetails(ButtonAction.ERROR);
      onAddBrightHireManuallyError?.(`${message.primary} ${message.secondary}`);
    }
  };

  /**
   * Set up the entire button in one go
   * @param state The action state of the button
   */
  const getButtonDetails = (
    state: ButtonAction,
    message?: string
  ): LaunchInterviewButton => {
    const btn: LaunchInterviewButton = {
      action: state,
      text: "Start interview",
      variant: "solid",
    };

    // Add button tooltip content & function to execute for various states
    switch (state) {
      case ButtonAction.ADD:
        btn.text = "Add BrightHire";
        btn.execute = () => {
          addBrightHireManually();
        };
        break;
      case ButtonAction.ERROR:
        btn.tooltip = message ?? "not found";
        btn.text = "Not available";
        btn.variant = "disabled";
        break;
      case ButtonAction.EXCLUDED:
        btn.tooltip = "BrightHire excluded";
        btn.text = "Not available";
        btn.variant = "disabled";
        break;
      case ButtonAction.GOOGLE_MEET:
        btn.execute = () =>
          openInterviewAssistant({
            path: "/interview-assistant/integrations/googleMeet",
            googleMeetMeetingId,
            googleMeetMeetingUrl,
            entryPoint: interviewAssistantEntryPoint ?? "",
          });
        break;
      case ButtonAction.TEAMS:
        btn.execute = () =>
          openInterviewAssistant({
            path: "/interview-assistant/integrations/teams",
            teamsNotetakerMeetingId,
            teamsNotetakerMeetingUrl,
            entryPoint: interviewAssistantEntryPoint ?? "",
          });
        break;
      case ButtonAction.PHONE:
        btn.execute = () => {
          openInterviewAssistant({
            phoneNumber:
              scheduledInterview?.phoneNumber ||
              scheduledInterview?.candidate?.defaultPhoneNumber,
            candidateFirstName: scheduledInterview?.candidate?.firstName,
            candidateLastName: scheduledInterview?.candidate?.lastName,
            candidateId: scheduledInterview?.candidate?.id,
            positionId: scheduledInterview?.position?.id,
            callGuideId: scheduledInterview.callGuide?.id,
            greenhouseScorecardLink: scheduledInterview.greenhouseLink,
            leverInterviewLink: scheduledInterview.leverInterviewId
              ? `https://hire.lever.co/interviews/${scheduledInterview.leverInterviewId}`
              : null,
            scheduledInterviewId: scheduledInterview.id,
            smartrecruitersInterviewLink:
              scheduledInterview.smartrecruitersInterviewLink,
            name: scheduledInterview.name,
          });
        };
        break;
      case ButtonAction.UPDATING:
        btn.text = "Updating";
        btn.variant = "disabled";
        btn.tooltip =
          "We are completing some final checks on this interview. Please refresh the page in a few minutes to verify.";
        break;
      case ButtonAction.UPDATE_FAILED:
        btn.text = "Update failed";
        btn.variant = "disabled";
        btn.tooltip =
          "Unfortunately, we were not able to add BrightHire to this interview. Please reach out for assistance.";
        break;
      case ButtonAction.ZOOM:
        if (scheduledInterview.zoomAppAutoOpenEnabled) {
          btn.url = scheduledInterview.zoomMeetingUrl ?? zoomUrl;
        } else {
          btn.execute = () =>
            openInterviewAssistant({
              path: "/interview-assistant/integrations/zoom",
              joinUrl: scheduledInterview.zoomMeetingUrl ?? zoomUrl,
              entryPoint: interviewAssistantEntryPoint ?? "",
            });
        }
        break;
      case ButtonAction.OPT_IN:
        btn.execute = () =>
          window.open(
            scheduledInterview?.optInUrl ||
              "https://app.brighthire.ai/launch/interview"
          );
        break;
    }
    return btn;
  };

  /**
   * Get the button state given an action type
   * @param state The action state of the button
   */
  const setButtonDetails = (state: ButtonAction, message?: string): void => {
    const btn = getButtonDetails(state, message);
    setButton(btn);
  };

  const [zoomUrl, setZoomUrl] = useState(
    scheduledInterview?.zoomMeetingUrl ?? scheduledInterview?.zoomJoinUrl ?? ""
  );

  const [googleMeetMeetingId, setGoogleMeetMeetingId] = useState(
    scheduledInterview.googleMeetMeetings.length > 0
      ? scheduledInterview.googleMeetMeetings[0].id
      : ""
  );

  const [googleMeetMeetingUrl, setGoogleMeetMeetingUrl] = useState(
    scheduledInterview?.googleMeetMeetingUrl ?? new Date().toISOString()
  );

  const [teamsNotetakerMeetingId, setTeamsNotetakerMeetingId] = useState(
    scheduledInterview.teamsNotetakerMeetings.length > 0
      ? scheduledInterview.teamsNotetakerMeetings[0].id
      : ""
  );

  const [teamsNotetakerMeetingUrl, setTeamsNotetakerMeetingUrl] = useState(
    scheduledInterview?.teamsMeetingUrl ?? new Date().toISOString()
  );

  const [button, setButton] = useState(getButtonDetails(ButtonAction.PHONE));

  // Import video meeting
  const [addBrightHire] = useAddBrightHireToInterviewsMutation({
    // Set the join URL for the button to launch the IA with it
    // If meeting was not successfully imported, explain why
    onCompleted: (meetingData) => {
      const results = meetingData?.addBrightHireToInterviews;
      const interviews = results?.scheduledInterviews;
      const errors = results?.errors;
      const interview = interviews?.length
        ? (interviews.find((i) => {
            return i.id === scheduledInterview.id;
          }) as ScheduledInterviewListItemFragment)
        : undefined;
      onAddBrightHire?.(meetingData);
      if (interview) {
        handleButtonSetupForVideoMeetings(interview);
      } else {
        const err = errors?.length
          ? errors[0].message ?? "Something went wrong"
          : "Something went wrong";
        handleButtonSetupForVideoMeetings(undefined, err);
      }
    },
    // Set error state
    onError: (err) => {
      const message = getErrorContent(err.message);
      setButtonDetails(ButtonAction.ERROR, message.secondary);
    },
  });

  /**
   * Set up the button following an update operation
   * @param interview The scheduled interview affected
   */
  const handleButtonSetupForVideoMeetings = (
    interview?: ScheduledInterviewListItemFragment,
    message?: string
  ): void => {
    // If we have an interview but no identified video meeting
    if (interview && !interview.isVideo) {
      return;
    }

    // Set up some details
    let action: ButtonAction | undefined;
    let errorMessage: string | undefined;
    let status = showUpdating;

    // If the interview is set to import, don't show updating status anymore
    if (interview?.isImported) {
      setIsUpdating(false);
      status = undefined;
    }

    // Find out what state the button should be in
    if (status && status === "updateFailed") {
      action = ButtonAction.UPDATE_FAILED;
    } else if (status && status === "updating") {
      action = ButtonAction.UPDATING;
    } else if (message) {
      errorMessage = message;
      action = ButtonAction.ERROR;
    } else if (!interview?.isExcluded && !interview?.isImported && isUpdating) {
      action = ButtonAction.UPDATING;
    } else if (
      !interview?.isExcluded &&
      !interview?.isImported &&
      !interview?.canAddBrighthire
    ) {
      action = ButtonAction.ERROR;
      errorMessage = interview?.canAddBrighthireReason ?? "not found";
    } else if (!interview?.isExcluded && !interview?.isImported) {
      if (
        interview.scheduledStart &&
        new Date(interview.scheduledStart) < new Date()
      ) {
        action = ButtonAction.ERROR;
        errorMessage =
          "BrightHire cannot join meetings that have already begun";
      } else {
        action = ButtonAction.ADD;
      }
    } else if (interview?.isExcluded) {
      action = ButtonAction.EXCLUDED;
    } else if (interview?.optInUrl) {
      action = ButtonAction.OPT_IN;
    } else if (interview?.isImported && interview?.zoomId) {
      action = ButtonAction.ZOOM;
    } else if (interview?.isImported && interview?.googleMeetMeetingUrl) {
      action = ButtonAction.GOOGLE_MEET;
    } else if (interview?.isImported && interview?.teamsMeetingUrl) {
      action = ButtonAction.TEAMS;
    }

    // Set the button state
    if (action) {
      if (action === ButtonAction.ZOOM) {
        setZoomUrl(interview?.zoomMeetingUrl ?? interview?.zoomJoinUrl ?? "");
      } else if (action === ButtonAction.GOOGLE_MEET) {
        setGoogleMeetMeetingId(
          interview?.googleMeetMeetings?.length ?? -1 > 0
            ? interview?.googleMeetMeetings[0].id ?? ""
            : ""
        );
        setGoogleMeetMeetingUrl(interview?.googleMeetMeetingUrl ?? "");
      } else if (action === ButtonAction.TEAMS) {
        setTeamsNotetakerMeetingId(
          interview?.teamsNotetakerMeetings?.length ?? -1 > 0
            ? interview?.teamsNotetakerMeetings[0].id ?? ""
            : ""
        );
        setTeamsNotetakerMeetingUrl(interview?.teamsMeetingUrl ?? "");
      }
      setButtonDetails(action, errorMessage);
    }
  };

  /** INITIAL BUTTON SETUP and further updates. When the interview gets updated,
   * update the button too
   */
  useEffect(() => {
    // Phone calls are the initial state of the button
    handleButtonSetupForVideoMeetings(scheduledInterview);
  }, [scheduledInterview, showUpdating]);

  const cantLaunchInterviewReason = scheduledInterview.canLaunchMeetingReason;

  return {
    button,
    zoomUrl,
    cantLaunchInterviewReason: cantLaunchInterviewReason
      ? getErrorContent(cantLaunchInterviewReason).secondary
      : null,
    onStartInPerson: () => {
      openInPersonIA(scheduledInterview);
    },
  };
};
