import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PageContent, PageTitle } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { DefaultLayout } from "../../layouts";
import CreateTemplateButton from "./CreateTemplateButton";
import TemplatesList from "./TemplatesList";

const StyledTab: typeof Tab = (props) => <Tab pb="2.5" mb="0" {...props} />;

const TemplatesPage: React.FC = () => {
  usePageTracker("templates");
  const navigate = useNavigate();
  const location = useLocation();

  const pickTabIndex = (pathname: string): number => {
    if (pathname.includes("shared")) {
      return 1;
    }
    return 0;
  };

  const [activeTab, setActiveTab] = useState<number>(
    pickTabIndex(location.pathname)
  );

  const handleChangeTab = (index: number): void => {
    if (index === 0) {
      navigate(
        { pathname: "/templates", search: location.search },
        { replace: true }
      );
    } else if (index === 1) {
      navigate(
        { pathname: "/templates/shared", search: location.search },
        { replace: true }
      );
    }
    setActiveTab(index);
  };

  return (
    <DefaultLayout>
      <PageContent>
        <Flex alignItems="center" justifyContent="space-between">
          <PageTitle mb="20px" titleText="AI Notes Templates" />
          <CreateTemplateButton />
        </Flex>
        <Tabs isLazy index={activeTab} onChange={handleChangeTab}>
          <TabList borderBottom="1px solid" borderColor="gray.200">
            <StyledTab>My Templates</StyledTab>
            <StyledTab>Shared Templates</StyledTab>
          </TabList>

          <TabPanels>
            <TabPanel data-testid="my-call-ai-summary-templates-panel">
              <TemplatesList category="my" active={activeTab === 0} />
            </TabPanel>
            <TabPanel data-testid="shared-call-ai-summary-templates-panel">
              <TemplatesList category="shared" active={activeTab === 1} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageContent>
    </DefaultLayout>
  );
};

export default TemplatesPage;
