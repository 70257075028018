import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import {} from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";

import {
  LoadingIndicator,
  SettingsPageContainer,
} from "../../../../components";
import { useOrganizationDataRetentionQuery } from "../../../graphql";
import { useFeatureFlagForCurrentOrImpersonatedUser } from "../../../graphql/hooks/useFeatureFlag";
import DataRetentionCandidatesPage from "./DataRetentionCandidatesPage";
import DataRetentionCandidatesPageBeta from "./DataRetentionCandidatesPageBeta";
import DataRetentionInputsPage from "./DataRetentionInputsPage";
import DataRetentionInterviewsPage from "./DataRetentionInterviewsPage";

const DataRetentionSettings: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading } = useOrganizationDataRetentionQuery();
  const coolNewCandidatePageEnabled =
    useFeatureFlagForCurrentOrImpersonatedUser("candidate_retention_v2");
  const path: { [id: string]: number } = {};
  path["/settings/data-retention"] = 0;
  path["/settings/data-retention/candidates"] = 1;
  path["/settings/data-retention/interviews"] = 2;

  const handleChangeTab = (index: number): void => {
    if (index === 0) {
      navigate({ pathname: "/settings/data-retention" }, { replace: true });
    } else if (index === 1) {
      navigate(
        { pathname: "/settings/data-retention/candidates" },
        { replace: true }
      );
    } else {
      navigate(
        { pathname: "/settings/data-retention/interviews" },
        { replace: true }
      );
    }
  };

  if (loading || !data?.currentUser?.organization) return <LoadingIndicator />;
  const organization = data?.currentUser?.organization;
  const CandidatePage = coolNewCandidatePageEnabled
    ? DataRetentionCandidatesPageBeta
    : DataRetentionCandidatesPage;
  return (
    <SettingsPageContainer heading="Data retention" maxW="100%">
      <Tabs index={path[location.pathname]} onChange={handleChangeTab} isLazy>
        <Flex flexDirection="column">
          <TabList mx={0} w="100%">
            <Tab>Settings</Tab>
            <Tab>Candidates</Tab>
            <Tab>Interviews</Tab>
          </TabList>
          <TabPanels
            height="100%"
            display="flex"
            flexFlow="column nowrap"
            flex={1}
          >
            <TabPanel
              display="flex"
              flexFlow="column nowrap"
              py={0}
              order={0}
              height="100%"
              flex={1}
              key="interview-sidebar"
              justifyContent="space-between"
            >
              <DataRetentionInputsPage organization={organization} />
            </TabPanel>
            <TabPanel>
              <CandidatePage organization={organization} />
            </TabPanel>
            <TabPanel>
              <DataRetentionInterviewsPage organization={organization} />
            </TabPanel>
          </TabPanels>
        </Flex>
      </Tabs>
    </SettingsPageContainer>
  );
};

export default DataRetentionSettings;
