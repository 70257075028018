import { Box, Checkbox, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  errorToast,
  FormControl,
  successToast,
  useToast,
} from "../../../../components";
import useUpdateCurrentUser from "../../../graphql/hooks/useUpdateCurrentUser";

type ZoomAppAutoOpenUserSettingProps = {
  userHasZoomAppAutoOpenEnabled: boolean;
  orgRequiresZoomAppAutoOpen: boolean;
};

type FormValues = {
  zoomAppAutoOpenEnabled: boolean;
};

export const ZoomAppAutoOpenUserSetting: React.FC<
  ZoomAppAutoOpenUserSettingProps
> = ({ userHasZoomAppAutoOpenEnabled, orgRequiresZoomAppAutoOpen }) => {
  const toast = useToast();

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      zoomAppAutoOpenEnabled: userHasZoomAppAutoOpenEnabled,
    },
  });

  const [updateCurrentUser, { loading: updateCurrentUserLoading }] =
    useUpdateCurrentUser({
      onCompleted: () => {
        successToast(toast, "Settings saved");
      },
      onError: () => {
        errorToast(toast, "There was a problem saving your settings");
      },
    });

  return (
    <Box
      as="form"
      onSubmit={handleSubmit((values) =>
        updateCurrentUser({ variables: values })
      )}
    >
      <FormControl
        isDisabled={orgRequiresZoomAppAutoOpen}
        helpText={
          <>
            BrightHire can automatically open the Interview Assistant in Zoom
            when you join a Zoom meeting for an interview.
          </>
        }
      >
        <Tooltip
          placement="bottom-start"
          shouldWrapChildren
          ml="4"
          mt="1"
          label={
            orgRequiresZoomAppAutoOpen
              ? "Your administrator has required this to be enabled for all BrightHire users"
              : undefined
          }
        >
          <Checkbox {...register("zoomAppAutoOpenEnabled")}>
            Auto-open Interview Assistant in Zoom
          </Checkbox>
        </Tooltip>
      </FormControl>
      {!orgRequiresZoomAppAutoOpen && (
        <Button type="submit" mt="7" isLoading={updateCurrentUserLoading}>
          Save
        </Button>
      )}
    </Box>
  );
};
