import { Box, Divider, Heading, Text } from "@chakra-ui/react";
import React from "react";

import {
  Alert,
  Link,
  LoadingIndicator,
  RouterLink,
  SettingsNote,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import {
  CurrentUserFragment,
  FeatureName,
  useOrgZoomNotetakerSettingsQuery,
  useUpdateOrganizationZoomNotetakerMutation,
  useZoomHealthQuery,
} from "../../graphql";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";
import ZoomBotRecordingAuth from "./ZoomBotRecordingAuth";
import ZoomNotetakerSettingsForm from "./ZoomNotetakerSettingsForm";

interface ZoomNotetakerSettingsProps {
  currentUser: CurrentUserFragment;
}

const ZoomNotetakerSettings: React.FC<ZoomNotetakerSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();

  const settingsQuery = useOrgZoomNotetakerSettingsQuery();
  const orgZoomSettings = settingsQuery.data?.currentUser?.organization;

  const [updateZoomNotetakerSettings, settingMutation] =
    useUpdateOrganizationZoomNotetakerMutation();

  const { organization } = currentUser;
  const hasVirtualOnsiteAccess = useHasFeatureAccess(
    FeatureName.VirtualOnsiteSplitting
  );

  if (!currentUser.userRole?.canManageIntegrationSettings) {
    return (
      <SettingsPageContainer heading="Zoom">
        <SettingsNote>Access denied</SettingsNote>
      </SettingsPageContainer>
    );
  }

  if (!orgZoomSettings) {
    return (
      <SettingsPageContainer heading="Zoom">
        {settingsQuery.loading && <LoadingIndicator mt="25%" />}
        {settingsQuery.error && (
          <Alert
            mb={8}
            status="error"
            description={settingsQuery.error.message}
          />
        )}
      </SettingsPageContainer>
    );
  }

  return (
    <SettingsPageContainer heading="Zoom">
      <Heading as="h4" fontSize="md" mb="7">
        Zoom notetaker
      </Heading>
      <ZoomNotetakerSettingsForm
        organizationName={organization.name}
        zoomNotetakerEnabled={orgZoomSettings.zoomNotetakerEnabled}
        sendCancelRecordingChatMessage={
          orgZoomSettings.sendCancelRecordingChatMessage
        }
        zoomAppAutoOpenRule={orgZoomSettings.zoomAppAutoOpenRule}
        zoomNotetakerDisclaimer={orgZoomSettings.zoomNotetakerDisclaimer}
        zoomNotetakerDisclaimerType={
          orgZoomSettings.zoomNotetakerDisclaimerType
        }
        zoomNotetakerDisplayImageBucketPath={
          orgZoomSettings.zoomNotetakerDisplayImageBucketPath
        }
        defaultDisplayName={orgZoomSettings.zoomNotetakerDisplayName}
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        showVirtualOnsiteSetting={hasVirtualOnsiteAccess}
        virtualOnsiteEnabled={orgZoomSettings.virtualOnsiteEnabledZoom}
        isLoading={settingsQuery.loading || settingMutation.loading}
        error={settingsQuery.error ?? settingMutation.error}
        onSubmit={(formData) => {
          return updateZoomNotetakerSettings({ variables: formData })
            .then((result) => {
              if (result.data?.updateOrganizationZoomNotetaker?.organization) {
                toast({
                  status: "success",
                  title: "Zoom Notetaker Settings",
                  description: "Saved!",
                });
              }
            })
            .catch((error) => {
              toast({
                status: "error",
                title: "Zoom Notetaker Settings",
                description:
                  error?.message ?? "There was a problem - please try again",
              });
            });
        }}
      />
      <Box mb="8">
        <ZoomBotRecordingAuth currentUser={currentUser}>
          <Divider my="10" />
          <Heading as="h4" fontSize="md" mb="7">
            BrightHire Notetaker Zoom App installation
          </Heading>
        </ZoomBotRecordingAuth>
      </Box>
      <LocalRecordingSettingHealthCheck
        authorized={currentUser.organization.isZoomBotAuthorized}
      />
    </SettingsPageContainer>
  );
};

const LocalRecordingSettingHealthCheck: React.FC<{ authorized: boolean }> = ({
  authorized,
}) => {
  const { data } = useZoomHealthQuery();

  const badUsers = data?.badUsers;

  return (
    <>
      {authorized && badUsers?.length ? (
        <>
          <Heading as="h4" fontSize="md" mb="7">
            Configuration Issues
          </Heading>
          <Box ml="8">
            <Text mb={4}>
              The following users have an issue which prevents any meetings
              which they are the host of from being recorded. In order to fix
              this issue, follow the steps described
              <Link
                href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0063640#h_20df6ea9-fc54-4ab1-a469-018b6abe7eb0"
                target="_blank"
              >
                {" here "}
              </Link>
              to enable the Local Recording setting in Zoom.
            </Text>
            <ul>
              {badUsers.map((user) => (
                <li key={user.id}>
                  <RouterLink to={`/settings/users/info/${user.id}`}>
                    {user.email}
                  </RouterLink>
                  &nbsp;- {user.name}
                </li>
              ))}
            </ul>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default ZoomNotetakerSettings;
