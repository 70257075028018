import { useToast } from "@chakra-ui/react";
import React from "react";

import { SingleSignOnSettingsForm } from "../../../components";
import {
  CurrentUserFragment,
  SingleSignOnSetupDocument,
  useGenerateTokenMutation,
  useSetupSingleSignOnMutation,
  useSingleSignOnSetupQuery,
} from "../../graphql";

interface SingleSignOnSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormValues {
  oktaDomain: string;
  metadataUrl: string;
}

interface SingleSignOnSetup extends FormValues {
  scimToken: string;
}

const SingleSignOnSettings: React.FC<SingleSignOnSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const [generateToken, { data: tokenData, loading: tokenLoading }] =
    useGenerateTokenMutation();
  const { data, loading } = useSingleSignOnSetupQuery();
  const [setupSingleSignOn, { loading: setupSingleSignOnLoading }] =
    useSetupSingleSignOnMutation({
      update: (cache, { data }) => {
        const settings = data?.setupSingleSignOn?.setup;
        if (settings) {
          cache.writeQuery({
            query: SingleSignOnSetupDocument,
            data: {
              SingleSignOnSetup: settings,
            },
          });
        }
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to setup SingleSignOn: ${err.message}`,
          status: "error",
        });
      },
    });

  const onSubmit = (formValues: FormValues): void => {
    setupSingleSignOn({
      variables: {
        oktaDomain: formValues.oktaDomain,
        metadataUrl: formValues.metadataUrl,
      },
    });
  };

  return (
    <SingleSignOnSettingsForm
      loading={loading}
      setupLoading={setupSingleSignOnLoading}
      tokenLoading={tokenLoading}
      data={
        data as {
          singleSignOnSetup?: SingleSignOnSetup;
        }
      }
      tokenData={
        tokenData as {
          generateToken?: {
            setup: SingleSignOnSetup;
          };
        }
      }
      onSubmit={onSubmit}
      onGenerateToken={generateToken}
    />
  );
};

export default SingleSignOnSettings;
