import { debriefExample } from "./debrief";
import { engCodingChallengeExample } from "./engCodingChallenge";
import { executiveSummaryExample } from "./executiveSummary";
import { hiringManagerScreenExample } from "./hiringManagerScreen";
import { hiringManagerSummary } from "./hiringManagerSummary";
import { intakeExample } from "./intake";
import { recruitingScreenExample } from "./recruitingScreen";
import { softwareSystemDesign } from "./softwareSystemDesign";

export const allExamples = [
  recruitingScreenExample,
  hiringManagerSummary,
  executiveSummaryExample,
  hiringManagerScreenExample,
  intakeExample,
  debriefExample,
  engCodingChallengeExample,
  softwareSystemDesign,
];
