import { Box, Flex, Text, Tooltip, useToast } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";

import { Button, IconButton } from "../../../../../../components";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import {
  CallAiSummaryFormat,
  CallAiSummaryProcessingStatus,
  CallBetaFragment,
  FeatureName,
  TranscriptionStatus,
  useCallAiSummaryLazyQuery,
  useUpdateCallAiSummaryFormatMutation,
} from "../../../../../graphql";
import useFeatureFlag from "../../../../../graphql/hooks/useFeatureFlag";
import { useHasFeatureAccess } from "../../../../../hooks/useHasFeatureAccess";
import useMouseHasMoved from "../../../../../hooks/useMouseHasMoved";
import { useUrlState } from "../../../../../pages/home/useTabUrlState";
import {
  AiNotesNoCandidate,
  AiNotesNoTranscript,
} from "../../../../AiNotes/AiNotesErrors";
import FormatSelector, { FormatSelectorProps } from "./NotesFormatSelector";
import NotesOtherFormats from "./NotesOtherFormats";
import NotesQandAFormat from "./NotesQAFormat";

type NotesProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "transcriptionStatus"
    | "aiNotesSupportedFormats"
    | "aiNotesCustomFormat"
  >;
  hasLabeledCandidate: boolean;
  onClickTimestamp(t: number): void;
};

export const Notes: React.FC<NotesProps> = ({
  call,
  hasLabeledCandidate,
  onClickTimestamp,
}) => {
  const toast = useToast();
  const canUseOtherAISummaryFormats = call.aiNotesSupportedFormats.length > 0;
  const copyNotesRef = useRef<(() => void) | null>(null);
  const registerCopyNotes = useCallback(
    (f: () => void) => {
      copyNotesRef.current = f;
    },
    [copyNotesRef]
  );
  const debugEnabled = useFeatureFlag("ai_notes:debug");
  const callId = call.id;

  const [fetchSummary, aiSummary] = useCallAiSummaryLazyQuery({
    onCompleted: (data) => {
      // Qanda never returns with data for this call - if it is invoked it
      // would regenerate loop forever.
      if (data.callAiSummary == null && format !== CallAiSummaryFormat.Qanda) {
        regenerate(false);
      }
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error getting Notes",
        status: "error",
        position: "top",
      });
    },
  });
  const [generateSummary] = useUpdateCallAiSummaryFormatMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: "Error generating summary",
        status: "error",
        position: "top",
      });
    },
  });

  const [currentUrlCustomId, setUrlCustomId] = useUrlState({
    key: "customTemplateId",
  });
  // This order matters -- Check if a template was shared by link first
  const [customTemplateId, setCustomTemplateId] = useState<string | null>(
    currentUrlCustomId ?? call.aiNotesCustomFormat?.customTemplateId ?? null
  );
  useEffect(() => {
    if (currentUrlCustomId !== customTemplateId) {
      setUrlCustomId(customTemplateId);
    }
  }, [customTemplateId]);

  const initialFormat = (): CallAiSummaryFormat => {
    if (currentUrlCustomId) {
      return CallAiSummaryFormat.Custom;
    }
    return call.aiNotesCustomFormat.format;
  };
  const [format, setFormat] = useState<CallAiSummaryFormat>(initialFormat());

  const aiSummaryStatus = aiSummary.data?.callAiSummary?.status;
  const aiSummaryFormat = aiSummary.data?.callAiSummary?.format;
  const aiSummaryProcessingStartedAt =
    aiSummary.data?.callAiSummary?.processingStartedAt;
  const aiSummaryCustomId = aiSummary.data?.callAiSummary?.customTemplateId;

  useEffect(() => {
    if (!canUseOtherAISummaryFormats) {
      aiSummary.stopPolling();
      return;
    }
    if (format === CallAiSummaryFormat.Qanda) {
      aiSummary.stopPolling();
      return;
    }

    // Need to check format and custom template changes to trigger a re-render
    if (aiSummaryFormat === format && customTemplateId === aiSummaryCustomId) {
      if (
        aiSummaryStatus &&
        [
          CallAiSummaryProcessingStatus.Completed,
          CallAiSummaryProcessingStatus.Failed,
        ].includes(aiSummaryStatus)
      ) {
        aiSummary.stopPolling();
        return;
      }
    }

    if (!call.aiNotesSupportedFormats.includes(format)) {
      aiSummary.stopPolling();
      return;
    }

    fetchSummary({
      variables: {
        callId: call.id,
        format,
        customTemplateId,
      },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  }, [
    canUseOtherAISummaryFormats,
    format,
    customTemplateId,
    currentUrlCustomId,
    fetchSummary,
    aiSummary.stopPolling,
    aiSummaryStatus,
    aiSummaryFormat,
    aiSummaryProcessingStartedAt,
    call.aiNotesSupportedFormats,
    call.id,
  ]);
  const mouseHasMoved = useMouseHasMoved();
  useEffect(() => {
    LogRocket.track("ai-notes-tab-view");
    sendGAEvent("ai_notes_tab_view", "call_review");
  }, [mouseHasMoved]);
  const sendGAEvent = useSendGAEvent();
  const hideFormatSelector =
    useHasFeatureAccess(FeatureName.AiNotesTemplate) === false;
  const transcriptAvailable =
    call.transcriptionStatus === TranscriptionStatus.Completed;

  const switchFormat = (
    newFormat: CallAiSummaryFormat,
    customTemplateId?: string | null,
    forceRegenerate?: boolean
  ): void => {
    generateSummary({
      variables: {
        callId,
        format: newFormat,
        customTemplateId,
        targetSpeakerTags: [],
        customTopics: [],
        forceRegenerate,
      },
    }).then(() => {
      setCustomTemplateId(customTemplateId || null);
      setFormat(newFormat);
      sendGAEvent(
        "ai_notes_format_selected",
        "call_review",
        newFormat,
        format,
        {
          callId,
          format: newFormat,
        }
      );
      LogRocket.track(`ai-notes-format-selected-${newFormat.toLowerCase()}`);
    });
  };

  const commonWrapperProps = {
    copyNotesRef,
    format,
    customTemplateId,
    availableFormats: call.aiNotesSupportedFormats,
    switchFormat,
    callId: call.id,
    hideFormatSelector,
  };

  if (!transcriptAvailable) {
    return (
      <NotesWrapper {...commonWrapperProps} hideFormatSelector>
        <AiNotesNoTranscript />
      </NotesWrapper>
    );
  }

  const retryFetchSummary = (): void => {
    fetchSummary({
      variables: { callId: call.id, format, customTemplateId },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  };

  const regenerate = (forceRegenerate = true): void => {
    generateSummary({
      variables: {
        callId: call.id,
        format,
        customTemplateId,
        targetSpeakerTags: [],
        customTopics: [],
        forceRegenerate,
      },
    }).then(() => {
      retryFetchSummary();
    });
  };

  if (
    !hasLabeledCandidate &&
    [CallAiSummaryFormat.Qanda, CallAiSummaryFormat.Scorecard].includes(format)
  ) {
    return (
      <NotesWrapper {...commonWrapperProps}>
        <AiNotesNoCandidate
          canUseOtherAISummaryFormats={canUseOtherAISummaryFormats}
        />
      </NotesWrapper>
    );
  }

  if (
    format !== CallAiSummaryFormat.Qanda &&
    call.aiNotesSupportedFormats.includes(format)
  ) {
    if (aiSummary.data != null) {
      return (
        <NotesWrapper
          onRetry={retryFetchSummary}
          debugEnabled={debugEnabled}
          regenerate={regenerate}
          {...commonWrapperProps}
        >
          <NotesOtherFormats
            data={aiSummary.data}
            callId={call.id}
            onClickTimestamp={onClickTimestamp}
            loading={aiSummary.loading}
            registerCopyNotes={registerCopyNotes}
            onRetry={retryFetchSummary}
          />
        </NotesWrapper>
      );
    }
    return <NotesWrapper {...commonWrapperProps} />;
  }

  return (
    <NotesWrapper {...commonWrapperProps}>
      <NotesQandAFormat
        call={call}
        onClickTimestamp={onClickTimestamp}
        registerCopyNotes={registerCopyNotes}
      />
    </NotesWrapper>
  );
};

type NotesWrapperProps = Pick<
  FormatSelectorProps,
  | "availableFormats"
  | "format"
  | "customTemplateId"
  | "switchFormat"
  | "callId"
  | "onRetry"
> & {
  hideFormatSelector?: boolean;
  copyNotesRef: React.MutableRefObject<(() => void) | null>;
  debugEnabled?: boolean;
  regenerate?: () => void;
};

const NotesWrapper: React.FC<React.PropsWithChildren<NotesWrapperProps>> = ({
  children,
  hideFormatSelector,
  availableFormats,
  format,
  customTemplateId,
  switchFormat,
  callId,
  copyNotesRef,
  onRetry,
  debugEnabled,
  regenerate,
}) => {
  return (
    <Flex
      data-tour-id="ai-notes-tab-content"
      flexDir="column"
      h="100%"
      position="relative"
    >
      {!hideFormatSelector && (
        <FormatSelector
          availableFormats={availableFormats}
          format={format}
          customTemplateId={customTemplateId}
          switchFormat={switchFormat}
          callId={callId}
          onRetry={onRetry}
          copyButton={
            <Tooltip label="Copy all notes" placement="bottom-start">
              <IconButton
                data-tour-id="ai-notes-copy-button"
                icon={<IoCopyOutline size={20} />}
                aria-label="Copy all notes"
                size="sm"
                p="2"
                variant="ghost"
                flexShrink={0}
                visibility={copyNotesRef.current == null ? "hidden" : "inherit"}
                onClick={() => {
                  copyNotesRef.current?.();
                }}
              />
            </Tooltip>
          }
        />
      )}
      <Box flex="1" overflow="auto">
        {debugEnabled && regenerate && (
          <Flex
            bg="gray.50"
            borderRadius="8px"
            mb="2"
            justify="end"
            alignItems="center"
          >
            <Text fontSize="xs">
              Visible only if debug feature flag is enabled
            </Text>
            <Button variant="ghost" size="xs" onClick={() => regenerate()}>
              Regenerate
            </Button>
          </Flex>
        )}
        {children}
        <Box id="ai-notes-feedback" />
      </Box>
    </Flex>
  );
};
