import {
  HiOutlineChartBar,
  HiOutlineChatAlt2,
  HiOutlineSparkles,
  HiOutlineThumbUp,
} from "react-icons/hi";
import { IconType } from "react-icons/lib";

import { canViewOrgInsights } from "../../../utils/permissions";
import { AnalyticsDimension, AtsDataState, MetricName } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import useEnabledMetrics from "./useEnabledMetrics";

export type NavMetricGroup = {
  title: string;
  metrics: MetricName[];
  icon: IconType;
  badge?: string;
  defaultSegmentation?: AnalyticsDimension;
};

/**
 * Groups the enabled metrics for use in the sidebar
 */
const useSidebarConfig = (atsDataState: AtsDataState): NavMetricGroup[] => {
  const currentUser = useCurrentUser();
  const enabledMetrics = new Set(useEnabledMetrics(atsDataState));

  if (!canViewOrgInsights(currentUser)) {
    return [];
  }

  const ifEnabled = (metrics: MetricName[]): MetricName[] =>
    metrics.filter((metric) => enabledMetrics.has(metric));

  const interviewActivityMetrics = ifEnabled([
    MetricName.AgendaSet,
    MetricName.CandidateTalkRatio,
    MetricName.Interactivity,
    MetricName.LongestMonologue,
    MetricName.OnTimeInterviews,
    MetricName.PassRate,
    MetricName.ProblematicQuestions,
    MetricName.CandidateQuestionOpportunity,
    MetricName.QuestionsAsked,
    MetricName.SpeakingRate,
  ]);

  const overallMetrics = ifEnabled([
    MetricName.TotalInterviews,
    MetricName.TotalInterviewTime,
  ]);
  const interviewFeedbackMetrics = ifEnabled([
    MetricName.ScorecardCompletionRate,
    MetricName.ScorecardCompletionTime,
  ]);
  const experimentalMetrics = ifEnabled([
    MetricName.TopicTrends,
    MetricName.QuestionTrends,
  ]);

  return [
    {
      title: "Overall Metrics",
      metrics: overallMetrics,
      icon: HiOutlineChartBar,
    },
    {
      title: "Interview Activity",
      metrics: interviewActivityMetrics,
      icon: HiOutlineChatAlt2,
    },
    {
      title: "Interview Feedback",
      metrics: interviewFeedbackMetrics,
      icon: HiOutlineThumbUp,
    },
    {
      title: "Experimental",
      metrics: experimentalMetrics,
      icon: HiOutlineSparkles,
    },
  ].filter((group) => group.metrics.length > 0);
};

export default useSidebarConfig;
