import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { Button, CenteredCardLayout, useToast } from "../../../components";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import { isValidPhoneNumber } from "../../../utils/phone";
import {
  phoneExtension as phoneExtensionRegex,
  phoneNumber as phoneNumberRegex,
} from "../../../utils/regex";
import { formatPhoneNumber } from "../../../utils/string";
import {
  useCurrentUserQuery,
  useVerifyPhoneNumberMutation,
} from "../../graphql";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import useSignOut from "../../hooks/useSignOut";

interface FormData {
  phoneNumber: string;
  phoneNumberExtension: string;
}

const RegisterPhonePage: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  usePageTracker("verify_phone_number");
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  let redirectTo = from ?? "/";
  if (!redirectTo.startsWith("/")) {
    redirectTo = "/";
  }
  const [verifying, setVerifying] = useState(false);
  const toast = useToast();

  const {
    data: currentUserData,
    startPolling,
    stopPolling,
  } = useCurrentUserQuery({
    onCompleted: ({ currentUser }) => {
      if (currentUser?.phoneNumberVerified) {
        stopPolling();
      }
    },
  });
  const currentUser = currentUserData?.currentUser;
  const intlCallingEnabled = currentUser?.isInternationalCallingEnabled;
  const phoneNumPlaceholder = intlCallingEnabled
    ? "+xx xxxxx-xxxxx"
    : "(xxx) xxx-xxxx";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      phoneNumber: currentUser?.phoneNumber || "",
      phoneNumberExtension: currentUser?.phoneNumberExtension ?? "",
    },
  });
  const [phoneNumber, phoneNumberExtension] = watch([
    "phoneNumber",
    "phoneNumberExtension",
  ]);

  const [updateCurrentUser, { loading: updateCurrentUserLoading }] =
    useUpdateCurrentUser({
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const [verify, { data, loading }] = useVerifyPhoneNumberMutation({
    onCompleted: ({ verifyPhoneNumber }) => {
      if (!verifyPhoneNumber) {
        return;
      }
      setVerifying(true);
      startPolling(1000);
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const onSubmit = handleSubmit(
    ({ phoneNumber: pn, phoneNumberExtension: pne }) => {
      sendGAEvent("phone_validation", "on_boarding", "initiate");
      verify({ variables: { phoneNumber: pn, phoneNumberExtension: pne } });
    }
  );

  const onSignOut = useSignOut({ currentUser });

  const validationCode = data?.verifyPhoneNumber?.validationCode ?? null;

  return (
    <CenteredCardLayout
      onSignOut={onSignOut}
      containerProps={{ alignItems: "center" }}
    >
      <Flex pt="8" flexDirection="column" alignItems="center" maxW="400px">
        <Heading as="h1" fontSize="28px" fontWeight="normal" mb={4}>
          Verify Phone
        </Heading>

        {verifying ? (
          <>
            <Box mb={10}>
              Calling{" "}
              <strong>
                {formatPhoneNumber(phoneNumber, phoneNumberExtension)}
              </strong>
              {validationCode && (
                <Box mt={2}>
                  Validation Code: <strong>{validationCode}</strong>
                </Box>
              )}
            </Box>
            <Flex>
              <Button
                variant="outline"
                isLoading={updateCurrentUserLoading}
                mr={4}
                data-testid="skip"
                onClick={() => {
                  sendGAEvent("phone_validation", "on_boarding", "skip");
                  updateCurrentUser({
                    variables: {
                      phoneNumberSkipped: true,
                    },
                  });
                }}
              >
                Skip
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  sendGAEvent("phone_validation", "on_boarding", "initiate");
                  verify({
                    variables: {
                      phoneNumber,
                      phoneNumberExtension,
                    },
                  });
                }}
                isDisabled={loading}
              >
                Try again
              </Button>
            </Flex>
          </>
        ) : (
          <form onSubmit={onSubmit}>
            <Flex align="left" alignItems="center" direction="column">
              <Box mb={4}>
                {
                  "Verifying your phone displays your own phone number as the Caller ID when making BrightHire calls."
                }
              </Box>
              <Box mb={4}>
                {
                  "Enter your number and click Verify to receive an automated verification call."
                }
              </Box>
              {!intlCallingEnabled && (
                <Box mb={4}>
                  {"This feature is only available for US phone numbers."}
                </Box>
              )}
              <Flex mb={6}>
                <FormControl
                  id="phoneNumber"
                  isInvalid={errors.phoneNumber !== undefined}
                >
                  <FormLabel>Phone number</FormLabel>
                  <Input
                    type="tel"
                    {...register("phoneNumber", {
                      validate: {
                        isValid: (value) => {
                          if (intlCallingEnabled) {
                            return (
                              isValidPhoneNumber(value || "") ||
                              "Invalid phone number"
                            );
                          }
                          return (
                            phoneNumberRegex.test(value || "") ||
                            "Invalid phone number"
                          );
                        },
                      },
                    })}
                    aria-describedby="phone-helper-text"
                    placeholder={phoneNumPlaceholder}
                  />
                  {errors.phoneNumber !== undefined && (
                    <FormErrorMessage>
                      {errors.phoneNumber.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl
                  id="phoneNumberExtension"
                  ml={4}
                  width="100px"
                  isInvalid={errors.phoneNumberExtension !== undefined}
                >
                  <FormLabel>Extension</FormLabel>
                  <Input
                    {...register("phoneNumberExtension", {
                      pattern: {
                        value: phoneExtensionRegex,
                        message: "Invalid extension",
                      },
                    })}
                  />
                  {errors.phoneNumberExtension !== undefined && (
                    <FormErrorMessage>
                      {errors.phoneNumberExtension.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>
              <Flex>
                <Button
                  variant="outline"
                  isLoading={updateCurrentUserLoading}
                  mr={4}
                  data-testid="skip"
                  onClick={() => {
                    sendGAEvent("phone_validation", "on_boarding", "skip");
                    updateCurrentUser({
                      variables: {
                        phoneNumberSkipped: true,
                      },
                    });
                  }}
                >
                  Skip
                </Button>
                <Button type="submit" isLoading={loading} data-testid="verify">
                  Verify
                </Button>
              </Flex>
            </Flex>
          </form>
        )}
      </Flex>
    </CenteredCardLayout>
  );
};

export default RegisterPhonePage;
