import { Text } from "@chakra-ui/react";
import React from "react";

import { PageTitle, useTheme } from "../../../components";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { DefaultPageLayout } from "../../layouts";
import CoachingDemo from "./CoachingDemo";

const CoachingPage: React.FC = () => {
  const theme = useTheme();
  const { pagePadding } = theme.sizes;
  usePageTracker("coaching");

  return (
    <DefaultPageLayout p={pagePadding}>
      <PageTitle mb="5" titleText="Interviewer Coaching" />
      <Text fontSize="sm" mb="4" color="gray.600">
        Get coaching tips based on up to 10 recent interviews.
      </Text>
      <CoachingDemo />
    </DefaultPageLayout>
  );
};

export default CoachingPage;
