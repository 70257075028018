import { Flex, Heading, Radio, RadioGroup, Text } from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Button,
  CenteredCardLayout,
  errorToast,
  useToast,
} from "../../../components";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import useCurrentUser from "../../hooks/useCurrentUser";

type ZoomAppAutoOpenProps = {
  onSignOut: () => void;
};

export const ZoomAppAutoOpen: React.FC<ZoomAppAutoOpenProps> = ({
  onSignOut,
}) => {
  const currentUser = useCurrentUser();
  const toast = useToast();

  const [zoomAppAutoOpenEnabled, setZoomAppAutoOpenEnabled] = useState(
    !!currentUser.zoomAppAutoOpenEnabled
  );

  const [updateCurrentUser, { loading: updateCurrentUserLoading }] =
    useUpdateCurrentUser({
      onError: () => {
        errorToast(toast, "There was a problem saving your settings");
      },
    });

  return (
    <CenteredCardLayout
      onSignOut={onSignOut}
      containerProps={{ alignItems: "center" }}
    >
      <Flex
        pt="8"
        flexDirection="column"
        alignItems="center"
        maxW="500"
        textAlign="center"
      >
        <Heading as="h1" fontSize="xl" fontWeight="normal" mb={4}>
          Automatically open the Interview Assistant in Zoom
        </Heading>
        <Text fontSize="sm" mb={8}>
          Would you like to automatically open the Interview Assistant in Zoom
          meetings that BrightHire is scheduled to join and that you are
          assigned as an interviewer to?
        </Text>
        <RadioGroup
          onChange={(value) => setZoomAppAutoOpenEnabled(value === "yes")}
          value={zoomAppAutoOpenEnabled ? "yes" : "no"}
        >
          <Flex flexDirection="column" gap={2}>
            <Radio value="yes">Yes, enable auto open</Radio>
            <Radio value="no">No, do not enable auto open</Radio>
          </Flex>
        </RadioGroup>
        <Button
          mt={8}
          isLoading={updateCurrentUserLoading}
          onClick={() =>
            updateCurrentUser({ variables: { zoomAppAutoOpenEnabled } })
          }
        >
          Save
        </Button>
      </Flex>
    </CenteredCardLayout>
  );
};
