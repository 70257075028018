import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  Alert,
  Button,
  Link,
  LoadingIndicator,
  SettingsPageContainer,
  StatusWithText,
  useToast,
} from "../../../components";
import {
  EventImportKeyword,
  GoogleAuthButton,
  MsftAuthButton,
} from "../../components";
import {
  useCurrentUserCalendarQuery,
  useUpdateCalendarSettingsMutation,
} from "../../graphql";

const CalendarSettings: React.FC = () => {
  const toast = useToast();
  const [isPolling, setIsPolling] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const msftAuth = searchParams.get("msft_auth");
  const { data, loading, stopPolling, refetch } = useCurrentUserCalendarQuery();
  const currentUser = data?.currentUser;
  const zoomSetupComplete =
    currentUser?.organization?.isZoomAuthorized ||
    currentUser?.organization?.zoomNotetakerEnabled ||
    false;
  const googleMeetEnabled =
    currentUser?.organization?.googleMeetEnabled || false;
  const videoMeetingEnabled = zoomSetupComplete || googleMeetEnabled;
  const calendars = currentUser?.calendars || [];

  const successToast = (): void => {
    toast({
      status: "success",
      title: "Integrations",
      description:
        "Your Calendar events will appear on the home screen momentarily.",
    });
  };

  useEffect(() => {
    if (msftAuth) {
      if (msftAuth === "success") {
        successToast();
      } else if (msftAuth === "failure") {
        toast({
          status: "error",
          title: "Integrations",
          description: "There was a problem authorizing calendar access",
        });
      }
      searchParams.delete("msft_auth");
      setSearchParams(searchParams, { replace: true });
    }
  });

  const primaryCalendar = calendars.find((cal) => {
    return cal.primary;
  });

  useEffect(() => {
    stopPolling();
    setIsPolling(false);
  }, [primaryCalendar]);

  const [
    updateCalendarSettings,
    {
      error: updateCalendarSettingsError,
      loading: updateCalendarSettingsLoading,
    },
  ] = useUpdateCalendarSettingsMutation({
    onCompleted: (data) => {
      if (data?.updateCalendarSettings?.currentUser) {
        toast({
          status: "success",
          title: "Update Calendar Settings",
          description: "Success!",
        });
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: "Update Calendar Settings",
        description: "There was a problem - please try again",
      });
    },
  });
  const { register, handleSubmit, setValue } = useForm<{
    videoMeetingImportStrategy: string;
  }>({});
  const submitCalendarSettings = handleSubmit((values) => {
    if (!primaryCalendar) return;
    updateCalendarSettings({
      variables: {
        id: primaryCalendar.id,
        shouldImportVideoMeeting: values.videoMeetingImportStrategy,
      },
    });
  });

  useEffect(() => {
    setValue(
      "videoMeetingImportStrategy",
      primaryCalendar?.videoMeetingImportStrategy || "NONE"
    );
  }, [primaryCalendar]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (currentUser?.isCalendarAuthorized) {
    return (
      <SettingsPageContainer
        heading="Calendar sync"
        subHeading="Sync your calendar to ensure BrightHire seamlessly joins your interviews."
      >
        <Box mb={6}>
          <StatusWithText
            passed={currentUser.isGcalAuthorized}
            label="Google calendar"
            warn
          />
          <StatusWithText
            passed={currentUser.isMsftCalAuthorized}
            label="Microsoft calendar"
            warn
          />
          {!currentUser.isGcalAuthorized && (
            <Flex w="96">
              <GoogleAuthButton
                label="Sync your Google calendar"
                onError={({ message }) => {
                  toast({
                    title: "Error",
                    description: message,
                    status: "error",
                  });
                }}
                onAuth={() => {
                  refetch();
                }}
                feature="calendar"
              />
            </Flex>
          )}
          {!currentUser.isMsftCalAuthorized && (
            <Flex w="96">
              <MsftAuthButton
                label="Sync your Microsoft calendar"
                onError={({ message }) => {
                  toast({
                    title: "Error",
                    description: message,
                    status: "error",
                  });
                }}
                onAuth={() => {
                  refetch();
                }}
                feature="calendar"
              />
            </Flex>
          )}
          <Heading as="h2" fontSize="md" fontWeight="semibold" mt={6} mb={4}>
            My Calendars
          </Heading>
          <UnorderedList>
            {calendars?.map((calendar) => {
              return (
                <ListItem
                  fontWeight={calendar.primary ? "semibold" : "normal"}
                  key={calendar.id}
                  mb="2"
                  lineHeight="5"
                  _last={{ mb: "0" }}
                >
                  {calendar.name}
                </ListItem>
              );
            })}
          </UnorderedList>
        </Box>
        {isPolling && <LoadingIndicator />}
        {primaryCalendar && (
          <Flex mt="8" mb="8">
            {updateCalendarSettingsError && (
              <Alert
                status="error"
                title="Error"
                description={updateCalendarSettingsError.message}
              />
            )}
            <form onSubmit={submitCalendarSettings}>
              <Heading as="h2" fontSize="md" fontWeight="semibold" mb={4}>
                Video Meeting Import Settings
              </Heading>
              <StatusWithText
                passed={zoomSetupComplete}
                label={
                  zoomSetupComplete
                    ? "Zoom Setup Complete"
                    : "Zoom Setup Incomplete, please contact your Administrator."
                }
              />
              <StatusWithText
                passed={googleMeetEnabled}
                label={
                  googleMeetEnabled
                    ? "Google Meet Enabled"
                    : "Google Meet Not Enabled, please contact your Administrator."
                }
              />
              {videoMeetingEnabled && (
                <>
                  <FormControl id="videoMeetingImportStrategy" mb={6}>
                    <FormLabel>
                      {!primaryCalendar.canSetVideoMeetingImportStrategy
                        ? "An organization administrator has set this up for you. Please contact them to change this setting."
                        : "Use the settings below to have BrightHire automatically join and record Zoom or Google Meet interviews scheduled on your calendar. "}
                    </FormLabel>
                    <RadioGroup
                      name="videoMeetingImportStrategy"
                      defaultValue={primaryCalendar.videoMeetingImportStrategy}
                      mb={4}
                    >
                      <Stack spacing="4">
                        <Radio
                          {...register("videoMeetingImportStrategy")}
                          value="TAGGED"
                          isDisabled={
                            !primaryCalendar.canSetVideoMeetingImportStrategy
                          }
                        >
                          <b>Some</b>: Meetings with #brighthire in the event
                          description
                        </Radio>
                        <Radio
                          {...register("videoMeetingImportStrategy")}
                          value="NONE"
                          isDisabled={
                            !primaryCalendar.canSetVideoMeetingImportStrategy
                          }
                        >
                          <b>None</b>: You will need to{" "}
                          <Link href="https://help.brighthire.ai/en/articles/4439267-zoom-recording-meetings">
                            manually add BrightHire
                          </Link>
                          .
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                  <Button
                    type="submit"
                    mb={2}
                    isLoading={updateCalendarSettingsLoading}
                    isDisabled={
                      !primaryCalendar.canSetVideoMeetingImportStrategy
                    }
                  >
                    Save
                  </Button>
                </>
              )}
            </form>
          </Flex>
        )}
        <Divider my="5" />
        <EventImportKeyword w={650} h={350} />
      </SettingsPageContainer>
    );
  }

  return (
    <SettingsPageContainer heading="Calendar sync">
      <Flex flexDirection="column" alignItems="flex-start" w="auto">
        <Text mb="4">You currently have no synced calendars.</Text>
        <Flex w="128">
          <GoogleAuthButton
            label="Sync your Google calendar"
            onError={({ message }) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
              });
            }}
            onAuth={() => {
              refetch();
            }}
            feature="calendar"
          />
        </Flex>
        <Flex w="128" mt={2}>
          <MsftAuthButton
            label="Sync your Microsoft calendar"
            onError={({ message }) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
              });
            }}
            onAuth={() => {
              refetch();
            }}
            feature="calendar"
          />
        </Flex>
      </Flex>
    </SettingsPageContainer>
  );
};

export default CalendarSettings;
