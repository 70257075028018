import { Box, Flex, Progress, Text } from "@chakra-ui/react";
import React from "react";

import {
  InviteUsersButton,
  LoadingIndicator,
  SearchInput,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { UserList } from "../../components";
import {
  PlanUserRolePermission,
  useInviteUsersMutation,
  useOrgUsersQuery,
  UserRoleName,
  UserRolePermission,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

const UsersListPage: React.FC = () => {
  const toast = useToast();
  const currentUser = useCurrentUser();
  const [query, setQuery] = useSearchParam("q");
  const { loading: orgUsersLoading, data } = useOrgUsersQuery({
    variables: {
      query,
      deleted: false,
      signUpCompleted: false,
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to load user data: ${err.message}`,
        status: "error",
      });
    },
  });
  const [inviteUsers] = useInviteUsersMutation({
    update(cache, { data: inviteUsersData }) {
      const newUsers = inviteUsersData?.inviteUsers?.users;
      if (newUsers) {
        cache.modify({
          id: cache.identify(currentUser.organization),
          fields: {
            users: (prevUsers) => {
              return [...prevUsers, ...newUsers];
            },
          },
        });
      }
    },
  });

  const users = data?.currentUser?.organization?.users || [];
  const usedFreemiumSeats = users.length;
  const totalFreemiumSeats =
    data?.currentUser?.organization?.freemiumSeatCount ?? 0;
  const availableFreemiumSeats = totalFreemiumSeats - usedFreemiumSeats;
  const isFreemium = !!data?.currentUser?.organization?.freemium;

  const canManageUserRoles = currentUser.userRole?.permissions?.includes(
    UserRolePermission.ManageUserRoles
  );
  const roles = canManageUserRoles
    ? currentUser.organization.userRoles
    : currentUser.organization.userRoles.filter(
        (r) => r.name === UserRoleName.Basic
      );
  // const canInviteUsers =
  //   (!isFreemium || usedFreemiumSeats < totalFreemiumSeats) &&
  //   (currentUser.userRole?.permissions?.includes(
  //     UserRolePermission.ManageHiringTeam
  //   ) ||
  //     currentUser.memberships.reduce((acc, membership) => {
  //       return (
  //         acc ||
  //         membership.role.permissions.includes(
  //           PositionRolePermission.ManageHiringTeam
  //         )
  //       );
  //     }, false));
  const canManageHiringTeam = currentUser.userRole?.canManageHiringTeam;
  const canManageAnyPositionHiringTeam = currentUser.memberships.some(
    (membership) => membership.role.canManageHiringTeam
  );
  let canInviteUsers = canManageHiringTeam || canManageAnyPositionHiringTeam;
  if (isFreemium && availableFreemiumSeats === 0) {
    canInviteUsers = false;
  }

  const planSkuEnabled = data?.currentUser?.organization?.planEnabled;
  const canManagePlanUserRoles =
    currentUser.planUserRole?.permissions?.includes(
      PlanUserRolePermission.ManageUserRoles
    );
  const planUserRoles = [
    {
      id: "",
      name: "NO_ACCESS",
      permissions: [],
      formattedPermissions: "No access to BrightHire Plan",
      formattedName: "No access",
    },
    ...(currentUser.organization.planUserRoles || []),
  ];
  const planRoles = canManagePlanUserRoles
    ? planUserRoles
    : planUserRoles.filter((r) => r.name === "NO_ACCESS");

  return (
    <SettingsPageContainer
      minW="max(900px, 100%)"
      heading={
        <Flex alignItems="start" justifyContent="space-between">
          <Text>Users list</Text>
          {isFreemium && (
            <Box>
              <Text fontSize="md" fontWeight="normal">
                <strong>{usedFreemiumSeats}</strong> of{" "}
                <strong>{totalFreemiumSeats}</strong> seats used
              </Text>
              <Progress
                value={usedFreemiumSeats}
                max={totalFreemiumSeats}
                size="sm"
                colorScheme={availableFreemiumSeats === 0 ? "red" : "blue"}
              />
            </Box>
          )}
        </Flex>
      }
      subHeading="View and manage all active and invited users."
    >
      <Flex alignItems="center" justifyContent="space-between" mb="4">
        <SearchInput
          onSearch={setQuery}
          defaultValue={query}
          placeholder="Search names, emails"
        />
        <InviteUsersButton
          inviteUsers={(users) => {
            return inviteUsers({ variables: { users } }).then((res) => {
              if (res.errors) {
                throw res.errors[0].message;
              }
            });
          }}
          roles={roles}
          includePlanRoleField={planSkuEnabled}
          planRoles={planRoles}
          disabled={!canInviteUsers}
          maxInvites={
            isFreemium ? totalFreemiumSeats - usedFreemiumSeats : undefined
          }
        />
      </Flex>
      {orgUsersLoading ? (
        <LoadingIndicator />
      ) : (
        <UserList
          users={users}
          canDisableUsers={canManageUserRoles}
          planSkuEnabled={planSkuEnabled}
        />
      )}
    </SettingsPageContainer>
  );
};

export default UsersListPage;
