import { useEffect, useState } from "react";

import {
  CandidateSummaryTemplatesQuery,
  CandidateSummaryTemplateStatus,
  useCandidateSummaryTemplatesQuery,
} from "../../../graphql";
import { useUrlState } from "../../../pages/home/useTabUrlState";

type CandidateSummaryTemplate = NonNullable<
  CandidateSummaryTemplatesQuery["candidateSummaryTemplates"]
>[number];

const MAX_POLLING_SECONDS = 2 * 60 * 1000;

type CandidateSummaryTemplatesHookResult = {
  templates: CandidateSummaryTemplate[];
  templatesLoading: boolean;
  selectedTemplateId: string;
  selectedTemplate: CandidateSummaryTemplate | undefined;
  selectedTemplateReadyToUse: boolean;
  selectedTemplateInProgress: boolean;
  handleChangeTemplate: (templateId: string) => void;
};

const useCandidateSummaryTemplates = (
  candidateId: string,
  positionId: string | undefined
): CandidateSummaryTemplatesHookResult => {
  const [currentUrlTemplateId, setUrlTemplateId] = useUrlState({
    key: "templateId",
  });

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>(
    currentUrlTemplateId ?? ""
  );

  const handleChangeTemplate = (templateId: string): void => {
    if (templateId !== selectedTemplateId) {
      setSelectedTemplateId(templateId);
      setUrlTemplateId(templateId);
    }
  };

  const templatesQuery = useCandidateSummaryTemplatesQuery({
    variables: { candidateId, positionId },
    skip: !candidateId,
    fetchPolicy: "network-only",
  });

  const templates = templatesQuery.data?.candidateSummaryTemplates || [];

  // Select the first template if none is selected
  useEffect(() => {
    if (selectedTemplateId === "" && templates[0]) {
      handleChangeTemplate(templates[0].id);
    }
  }, [templates]);

  const selectedTemplate = templates.find(
    (template) => template.id === selectedTemplateId
  );

  // Manage polling for template status updates
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (
      selectedTemplate?.processingStatus ===
      CandidateSummaryTemplateStatus.InProgress
    ) {
      templatesQuery.startPolling(5000);
      timeoutId = setTimeout(() => {
        templatesQuery.stopPolling();
      }, MAX_POLLING_SECONDS);
    } else {
      templatesQuery.stopPolling();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      templatesQuery.stopPolling();
    };
  }, [selectedTemplate?.processingStatus]);

  // If the selected template is not in the list of loaded templates, select
  // the position default, org default, or first template in the list.
  useEffect(() => {
    if (
      selectedTemplateId &&
      templates &&
      templates.length > 0 &&
      !selectedTemplate
    ) {
      const positionDefaultTemplate = templates.find(
        (template) => template.isPositionDefault
      );
      if (positionDefaultTemplate) {
        handleChangeTemplate(positionDefaultTemplate.id);
        return;
      }
      const orgDefaultTemplate = templates.find(
        (template) => template.isOrgDefault
      );
      if (orgDefaultTemplate) {
        handleChangeTemplate(orgDefaultTemplate.id);
        return;
      }
      handleChangeTemplate(templates[0].id);
    }
  }, [selectedTemplateId, templates, selectedTemplate, handleChangeTemplate]);

  // Legacy templates have no status and are complete
  const selectedTemplateReadyToUse =
    (selectedTemplate && !selectedTemplate.processingStatus) ||
    selectedTemplate?.processingStatus ===
      CandidateSummaryTemplateStatus.Completed;

  return {
    templates,
    templatesLoading: templatesQuery.loading,
    selectedTemplateId,
    selectedTemplate,
    selectedTemplateReadyToUse,
    handleChangeTemplate,
    selectedTemplateInProgress:
      selectedTemplate?.processingStatus ===
      CandidateSummaryTemplateStatus.InProgress,
  };
};

export default useCandidateSummaryTemplates;
