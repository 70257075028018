import { Flex, Icon } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { HiOutlineSparkles } from "react-icons/hi";

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen";
import {
  useExternalRecordingTrackerSegmentLazyQuery,
  useExternalRecordingTrackersQuery,
} from "../../graphql";
import { useExternalShareParams } from "../../pages/external-share/useExternalShareParams";
import { MediaPlayerInterface } from "../Interview/useMediaPlayer";
import { SidebarTab, SidebarTabs, TrackersTab, Transcript } from "../Recording";
import { ExternalRecordingAiNotesTab } from "./ExternalRecordingAiNotesTab";
import { ExternalRecording, isExternalCall } from "./types";

export enum ExternalRecordingTab {
  TRANSCRIPT = "transcript",
  POIS = "trackers",
  AI_NOTES = "ai_notes",
}

type ExternalRecordingSidebarProps = {
  recording: ExternalRecording;
  currentTab: ExternalRecordingTab;
  player: MediaPlayerInterface;
  autoScrollEnabled: boolean;
  setAutoScrollEnabled(enabled: boolean): void;
  onTabChange?(t: ExternalRecordingTab): void;
  onSeek(t: number): void;
  onUserChangeTab?(): void;
  isVideoVisible?: boolean;
  hideVideo(): void;
  focusVideo(): void;
  isVideoCall?: boolean;
};

const ExternalRecordingSidebar: React.FC<ExternalRecordingSidebarProps> = ({
  recording,
  currentTab,
  player,
  autoScrollEnabled,
  isVideoCall,
  isVideoVisible,
  hideVideo,
  focusVideo,
  setAutoScrollEnabled,
  onTabChange,
  onUserChangeTab,
  onSeek,
}) => {
  const { shareId, shareType, index } = useExternalShareParams();
  const isExternalCallShare = isExternalCall(recording);
  const isSmallScreen = useIsSmallScreen();
  // Prepare external recording POIs
  const trackersQuery = useExternalRecordingTrackersQuery({
    variables: { id: shareId, shareType, index },
  });
  const [getTrackerSegments, trackerSegmentsQuery] =
    useExternalRecordingTrackerSegmentLazyQuery();

  // Tab change logic
  useEffect(() => {
    if (isSmallScreen) {
      onTabChange?.(ExternalRecordingTab.TRANSCRIPT);
    } else if (currentTab === ExternalRecordingTab.TRANSCRIPT) {
      if (isExternalCallShare) {
        onTabChange?.(ExternalRecordingTab.AI_NOTES);
      } else {
        onTabChange?.(ExternalRecordingTab.POIS);
      }
    }
  }, [isSmallScreen, isExternalCallShare]);
  return (
    <SidebarTabs
      currentTab={currentTab}
      onTabChange={(newTab: ExternalRecordingTab) => {
        if (newTab !== currentTab) onUserChangeTab?.();
        onTabChange?.(newTab);
      }}
    >
      {isSmallScreen && (
        <SidebarTab
          id={ExternalRecordingTab.TRANSCRIPT}
          displayName="Transcript"
        >
          <Transcript
            transcript={recording.transcript}
            speakers={recording.speakers}
            player={player}
            autoScrollEnabled={autoScrollEnabled}
            setAutoScrollEnabled={setAutoScrollEnabled}
            isVideoCall={isVideoCall}
            isVideoVisible={isVideoVisible}
            hideVideo={hideVideo}
            focusVideo={focusVideo}
            loading={false}
          />
        </SidebarTab>
      )}

      {isExternalCallShare && (
        <SidebarTab
          id={ExternalRecordingTab.AI_NOTES}
          displayName={
            <Flex alignItems="center" color="purple.600" gap="1">
              <Icon
                as={HiOutlineSparkles}
                transform="scaleX(-1)"
                color="purple.600"
                boxSize="4"
              />
              AI Notes
            </Flex>
          }
        >
          <ExternalRecordingAiNotesTab
            hasTranscript={recording.transcript.length > 0}
            hasCandidate={recording.speakers.some((s) => s.isCandidate)}
            onSeek={onSeek}
          />
        </SidebarTab>
      )}

      <SidebarTab id={ExternalRecordingTab.POIS} displayName="POIs">
        <TrackersTab
          onSeek={onSeek}
          onTrackerGroupChange={(trackerGroupId) => {
            if (trackerGroupId) {
              getTrackerSegments({
                variables: { id: shareId, shareType, index, trackerGroupId },
              });
            }
          }}
          onTrackerKeywordChange={(trackerKeywordId) => {
            if (trackerKeywordId) {
              getTrackerSegments({
                variables: { id: shareId, shareType, index, trackerKeywordId },
              });
            }
          }}
          trackers={
            trackersQuery?.data?.externalShareView?.externalRecording
              .pointsOfInterest
          }
          trackerSegments={
            trackerSegmentsQuery.data?.externalShareView?.externalRecording
              ?.poiSegments
          }
          speakers={
            trackersQuery.data?.externalShareView?.externalRecording?.speakers
          }
          keywordsFetchData={trackersQuery}
          segmentsFetchData={trackerSegmentsQuery}
          speakerMenuDisabled
          doesCallHaveTranscript={recording.transcript.length > 0}
          transcriptLanguageCode={recording.transcriptLanguageCode ?? "en"}
        />
      </SidebarTab>
    </SidebarTabs>
  );
};

export default ExternalRecordingSidebar;
