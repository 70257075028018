import { MutableRefObject, useEffect, useRef } from "react";

const useResizeObserver = <T extends HTMLElement>(options?: {
  skip?: boolean;
  onResize?(el: T): void;
}): { ref: MutableRefObject<T | null> } => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (options?.skip) return;
    if (!ref.current) return;

    const el = ref.current;
    const obs = new ResizeObserver(() => options?.onResize?.(el));

    obs.observe(el);
    return () => obs.disconnect();
  }, [ref.current, options?.skip]);

  return { ref };
};

export default useResizeObserver;
