import {
  Box,
  ButtonGroup,
  Flex,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {
  HiDotsVertical,
  HiOutlineChatAlt2,
  HiOutlineDuplicate,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { IconButton, MenuButton } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallAiSummaryTemplateListItemFragment,
  useDuplicateAiSummaryTemplateMutation,
} from "../../graphql";
import DeleteTemplateMenuItem from "./DeleteTemplateMenuItem";
import EditTemplateButton from "./EditTemplateButton";

type CallAiSummaryTemplateActionsProps = {
  template: CallAiSummaryTemplateListItemFragment;
};

const CallAiSummaryTemplateActions: React.FC<
  CallAiSummaryTemplateActionsProps
> = ({ template }) => {
  // TODO: Discussed during requirements is to open the edit modal for a new
  // template when it is copied. This is not implemented yet.

  const toast = useToast();
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();

  const [copyTemplate] = useDuplicateAiSummaryTemplateMutation({
    // Using refetch instead of cache update to avoid complexity of sorting
    refetchQueries: ["CurrentUserMyCallSummaryTemplates"],
    onCompleted: (data) => {
      const templateId = data.duplicateAiSummaryTemplate?.template?.id;
      sendGAEvent(
        "ai_notes_custom_template_duplicate",
        "call_ai_templates",
        undefined,
        undefined,
        { templateId }
      );
      toast({
        title: "Template copied to My Templates",
        status: "success",
      });
    },
  });

  return (
    <Flex width="100%" alignItems="center" justifyContent="end">
      <ButtonGroup variant="ghost" size="md" ml="auto">
        {template.canEdit && <EditTemplateButton templateId={template.id} />}
        <Tooltip label="Copy template">
          <IconButton
            aria-label="Copy template"
            variant="ghost"
            size="sm"
            color="gray.900"
            icon={<HiOutlineDuplicate size="24px" strokeWidth="1.2" />}
            onClick={() => {
              copyTemplate({ variables: { id: template.id } });
            }}
          />
        </Tooltip>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HiDotsVertical size="16px" />}
            variant="ghost"
            size="sm"
            color="gray.900"
          />
          <MenuList>
            <MenuItem
              value="example"
              onClick={() => {
                navigate(`/search?template_id=${template.id}`);
              }}
            >
              <HiOutlineChatAlt2 size="20px" />
              <Box flex="1" ml="2">
                Go to example interview
              </Box>
            </MenuItem>
            {template.canDelete && (
              <>
                <MenuDivider />
                <DeleteTemplateMenuItem templateId={template.id} />
              </>
            )}
          </MenuList>
        </Menu>
      </ButtonGroup>
    </Flex>
  );
};

export default CallAiSummaryTemplateActions;
