import {
  Table,
  TableCellProps,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import React from "react";

import { RouterLink } from "../../../../components";
import { getFormatterForCurrency } from "../../../../utils/currency";
import { CompensationTrendsExample } from "../../../graphql";
import AnalyticsReportHero from "../AnalyticsReportHero";
import { TdWhite, ThGray } from "../AnalyticsReportTable";
import { FRIENDLY_CATEGORY_NAMES } from "./useCompensationTrendsConfig";

const TABLE_CELL_PROPS: TableCellProps = {
  px: 4,
  minW: "100px",
  textAlign: "left",
};

type AnalyticsCompensationTrendsTableProps = {
  tableData: CompensationTrendsExample[];
};

const AnalyticsCompensationTrendsTable: React.FC<
  AnalyticsCompensationTrendsTableProps
> = ({ tableData }) => {
  const formatRangeOrNull = (
    row: CompensationTrendsExample,
    rate: "annual" | "hourly"
  ): string | null => {
    if (row.rate === rate) {
      const formatter = getFormatterForCurrency(row?.currency);
      if (row.exactAmount) {
        return formatter.format(row.exactAmount);
      }
      if (row.minAmount && row.maxAmount)
        return `${formatter.format(row.minAmount)} to ${formatter.format(
          row.maxAmount
        )}`;
      if (row.minAmount) return `${formatter.format(row.minAmount)} and up`;
      if (row.maxAmount) return `up to ${formatter.format(row.maxAmount)}`;
    }
    return null;
  };

  return (
    <TableContainer>
      <Table variant="simple" whiteSpace="normal">
        <Thead>
          <Tr>
            <ThWrapper width="12.5%">Timestamp</ThWrapper>
            <ThWrapper width="12.5%">Interview</ThWrapper>
            <ThWrapper width="12.5%">Candidate</ThWrapper>
            <ThWrapper width="12.5%">Position</ThWrapper>
            <ThWrapper width="35%">Summary</ThWrapper>
            <ThWrapper width="12.5%" paddingLeft="4">
              Category
            </ThWrapper>
            <ThWrapper width="15%">Salary Range</ThWrapper>
            <ThWrapper width="15%">Hourly Range</ThWrapper>
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((row) => (
            <Tr
              key={row.compensationExtractionId}
              _last={{ borderColor: "transparent" }}
            >
              <TdWrapper paddingLeft="4">
                <Text color="gray.800" fontWeight="500">
                  {row.startTime &&
                    format(parseISO(row.startTime), "MMM dd, yyyy")}
                </Text>
              </TdWrapper>
              <TdWrapper>
                <RouterLink
                  to={`/interview/${row.callId}?t=${row.firstSegmentStartTime}`}
                  color="blue.600"
                  fontWeight="500"
                  fontSize="sm"
                  target="_blank"
                >
                  {row.callName || "Interview"}
                </RouterLink>
              </TdWrapper>
              <TdWrapper>
                <RouterLink
                  to={`/candidate/${row.candidateId}`}
                  color="blue.600"
                  fontWeight="500"
                  fontSize="sm"
                  target="_blank"
                >
                  {row.candidateFullName || "Candidate"}
                </RouterLink>
              </TdWrapper>
              <TdWrapper>
                {row.positionId && (
                  <RouterLink
                    to={`/position/${row.positionId}`}
                    color="blue.600"
                    fontWeight="500"
                    fontSize="sm"
                    target="_blank"
                  >
                    {row.positionName || ""}
                  </RouterLink>
                )}
                {!row.positionId && ""}
              </TdWrapper>
              <TdWrapper>
                <Text>{row.summary}</Text>
              </TdWrapper>
              <TdWrapper>
                <Text>{FRIENDLY_CATEGORY_NAMES[row.category]}</Text>
              </TdWrapper>
              <TdWrapper>
                <Text>{formatRangeOrNull(row, "annual")}</Text>
              </TdWrapper>
              <TdWrapper>
                <Text>{formatRangeOrNull(row, "hourly")}</Text>
              </TdWrapper>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {!tableData.length && <AnalyticsReportHero />}
    </TableContainer>
  );
};

const ThWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <ThGray {...TABLE_CELL_PROPS} {...props}>
    {children}
  </ThGray>
);

const TdWrapper: React.FC<
  {
    children: React.ReactNode;
  } & TableCellProps
> = ({ children, ...props }) => (
  <TdWhite {...TABLE_CELL_PROPS} {...props} borderColor="inherit">
    {children}
  </TdWhite>
);

export default AnalyticsCompensationTrendsTable;
