import {
  ButtonGroup,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  StyleProps,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { HiChevronDown } from "react-icons/hi";
import { MdInfo } from "react-icons/md";

import {
  Button,
  errorToast,
  IconButton,
  LoadingIndicator,
  MenuButton,
} from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { ScheduledInterviewListItemFragment } from "../../graphql";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";
import useCurrentUser from "../../hooks/useCurrentUser";
import {
  ButtonAction,
  useScheduledInterviewLaunchButton,
} from "../../hooks/useScheduledInterviewLaunchButton";

type LaunchButtonProps = {
  scheduledInterview: ScheduledInterviewListItemFragment;
  size?: "sm" | "md" | "lg";
} & StyleProps;

export const ScheduledInterviewLaunchButton: React.FC<LaunchButtonProps> = ({
  scheduledInterview,
  size = "sm",
  w,
  h,
  mt,
  ...styles
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();
  const isExtension = useIsExtension();

  const { button, onStartInPerson, zoomUrl, cantLaunchInterviewReason } =
    useScheduledInterviewLaunchButton({
      scheduledInterview,
      onAddBrightHireManuallyError: (message) => {
        errorToast(toast, message);
      },
    });

  const inPersonEnabled = currentUser.organization.allowInPersonMeetings;

  const trigger =
    button.variant === "disabled" ? (
      <Button isDisabled {...styles}>
        {button.text}
      </Button>
    ) : (
      <Button
        variant={button.variant}
        onClick={button.execute}
        data-zoom-url={button.action === ButtonAction.ZOOM ? zoomUrl : ""}
        h={h}
        {...styles}
      >
        {button.text}
      </Button>
    );

  if (button.action === ButtonAction.NONE) return null;

  return button.action === ButtonAction.LOADING ? (
    <LoadingIndicator size={size} />
  ) : (
    <>
      {cantLaunchInterviewReason && (
        <Tooltip
          id={`scheduled-${
            scheduledInterview?.candidate?.id ?? "unknown"
          }-launch-info`}
          placement="auto"
          label={<span>{cantLaunchInterviewReason}</span>}
        >
          <Icon as={MdInfo} mr={3} color="gray.400" />
        </Tooltip>
      )}
      <ButtonGroup
        size={size}
        isAttached
        w={w}
        mt={mt}
        maxW={isExtension ? undefined : "250"}
      >
        <Tooltip
          id={`scheduled-${
            scheduledInterview?.candidate?.id ?? "unknown"
          }-launch`}
          placement="auto"
          label={button.tooltip}
        >
          {trigger}
        </Tooltip>
        {inPersonEnabled && button.variant !== "disabled" && (
          <Menu placement="bottom-end">
            <MenuButton
              variant={button.variant}
              borderLeftWidth="1px"
              as={IconButton}
              aria-label="Start as in-person interview"
              icon={<Icon as={HiChevronDown} boxSize="4" />}
              h={h}
            />
            <MenuList py="1">
              <MenuItem
                fontSize="sm"
                onClick={() => {
                  onStartInPerson();
                  LogRocket.track("in-person-start-from-scheduled");
                  sendGAEvent(
                    "launch",
                    "in_person",
                    "From scheduled interview (candidate page)"
                  );
                }}
              >
                Start as in-person interview
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </ButtonGroup>
    </>
  );
};
