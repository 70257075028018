import {
  Box,
  Flex,
  Input,
  InputGroup,
  ListItem,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useState } from "react";

import {
  Button,
  SettingsNote,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import {
  useCreateExcludeStageMutation,
  useDeleteExcludeStageMutation,
  useExcludeStagesQuery,
} from "../../graphql";

const ExcludeStageSettings: React.FC = () => {
  const toast = useToast();

  const [excludeText, setExcludeText] = useState<string>("");

  const { data: excludeStages, refetch } = useExcludeStagesQuery();
  const [createExcludeStage, { loading: createLoading }] =
    useCreateExcludeStageMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          description: "Updating previously scheduled interviews",
          status: "success",
        });
        refetch();
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });
  const [deleteExcludeStage, { loading: deleteLoading }] =
    useDeleteExcludeStageMutation({
      onCompleted: () => {
        toast({
          title: "Success",
          description: "Updating previously scheduled interviews",
          status: "success",
        });
        refetch();
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const handleSubmit = (): void => {
    createExcludeStage({
      variables: { excludeText },
    });
    setExcludeText("");
  };

  return (
    <SettingsPageContainer
      maxW="600"
      heading="Exclude stages from BrightHire"
      subHeading="If you choose to exclude any stages, BrightHire will not record video interviews that contain the stage name(s) you specify."
    >
      <SettingsNote>
        Notes:
        <UnorderedList mb={2}>
          <ListItem>
            Any stage you would like excluded must be entered verbatim.
          </ListItem>
          <ListItem>Stage names are not case sensitive.</ListItem>
          <ListItem>
            BrightHire interviews scheduled by inviting recorder@brighthire.ai
            will override the exclusion list and record automatically.
          </ListItem>
        </UnorderedList>
        Example:
        <UnorderedList mb={2}>
          <ListItem>Excluded stage: &#34;culture final round&#34;</ListItem>
          <ListItem>
            Interview stage: &#34;Engineering - Culture Final Round&#34;
          </ListItem>
          <ListItem>Result: Does not record</ListItem>
        </UnorderedList>
        Example:
        <UnorderedList>
          <ListItem>Excluded stage: &#34;culture final round&#34;</ListItem>
          <ListItem>
            Interview stage: &#34;Product Final Round - Culture&#34;
          </ListItem>
          <ListItem>Result: Does record</ListItem>
        </UnorderedList>
      </SettingsNote>
      <Box />
      <Box maxW={520}>
        <InputGroup mt="8">
          <Input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            autoFocus
            fontSize="sm"
            mr="2"
            placeholder="Please enter a stage you want to exclude. Example: culture"
            disabled={createLoading || deleteLoading}
            value={excludeText}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setExcludeText(event.currentTarget.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <Button
            type="submit"
            px="6"
            onClick={handleSubmit}
            isLoading={createLoading || deleteLoading}
          >
            Submit
          </Button>
        </InputGroup>
        <Flex direction="column" wrap="wrap" mb="4" mt="8">
          {excludeStages?.excludeStages.map((excludeStage) => (
            <Tag
              key={excludeStage.id}
              size="lg"
              mb="3"
              py="4"
              px="6"
              backgroundColor="gray.50"
              borderRadius="lg"
            >
              <TagLabel>
                <Text fontSize="sm" overflowX="hidden" textOverflow="ellipsis">
                  {excludeStage.excludeText}
                </Text>
              </TagLabel>
              <TagCloseButton
                ml="auto"
                onClick={() => {
                  deleteExcludeStage({
                    variables: { id: excludeStage.id },
                  });
                }}
              />
            </Tag>
          ))}
        </Flex>
      </Box>
    </SettingsPageContainer>
  );
};

export default ExcludeStageSettings;
