import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

import useDebounce from "../../hooks/useDebounce";

type SearchInputProps = {
  onSearch: (query: string) => void;
  inputHeight?: string;
  height?: string;
} & InputProps;

const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  defaultValue,
  placeholder = "Search...",
  inputHeight = "sm",
  height = "8",
  ...rest
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.target.value);
  };
  const [query, setQuery] = useState(defaultValue ?? "");
  const debouncedQuery = useDebounce<string>(query as string, 350);
  useEffect(() => {
    onSearch(debouncedQuery);
  }, [debouncedQuery]);

  return (
    <InputGroup h={height}>
      <InputLeftElement
        display="flex"
        alignItems="center"
        h={height}
        p="0"
        color="placeholder"
      >
        <FiSearch />
      </InputLeftElement>
      <Input
        size={inputHeight}
        maxWidth="250px"
        onChange={onChange}
        type="search"
        value={query}
        placeholder={placeholder}
        h={height}
        borderRadius="md"
        {...rest}
      />
    </InputGroup>
  );
};

export default SearchInput;
