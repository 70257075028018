import { Box, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiArrowDownTray } from "react-icons/hi2";
import { Navigate } from "react-router-dom";

import { IconButton, LoadingIndicator } from "../../../../components";
import { formatISODate } from "../../../../utils/datetime";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CompensationExtractionCategoryType,
  useAnalyticsCompensationTrendsExamplesCsvLazyQuery,
  useAnalyticsCompensationTrendsExamplesQuery,
  useAnalyticsCompensationTrendsQuery,
  UserRoleName,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import AnalyticsFilters from "../AnalyticsFilters";
import AnalyticsInfoModal from "../AnalyticsInfoModal";
import { ReportContainer } from "../AnalyticsReport";
import AnalyticsReportHero from "../AnalyticsReportHero";
import AnalyticsReportTableSkeleton from "../AnalyticsReportTableSkeleton";
import LabeledChartSelect from "../LabeledChartSelect";
import { CommonQueryVariables } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import AnalyticsCompensationTrendsChart from "./AnalyticsCompensationTrendsChart";
import AnalyticsCompensationTrendsTable from "./AnalyticsCompensationTrendsTable";
import useCompensationTrendsConfig from "./useCompensationTrendsConfig";

const CATEGORY_OPTIONS = [
  { label: "Candidates and Interviewers", value: "ALL" },
  {
    label: "Candidates only",
    value: CompensationExtractionCategoryType.Candidate,
  },
  {
    label: "Interviewers only",
    value: CompensationExtractionCategoryType.Interviewer,
  },
];

const AnalyticsCompensationTrends: React.FC = () => {
  const sendGAEvent = useSendGAEvent();
  const queryConfig = useCompensationTrendsConfig();
  const currentUser = useCurrentUser();
  const { selectedOrgId } = useAnalyticsContext();
  const isAdmin = currentUser.userRole?.name === UserRoleName.SiteAdmin;

  const queryVariables: CommonQueryVariables = {
    dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
    organizationId: selectedOrgId,
    ...queryConfig.filters,
  };

  const { data: chartResult, loading: chartLoading } =
    useAnalyticsCompensationTrendsQuery({
      variables: {
        ...queryVariables,
        requestedOrganizationId: selectedOrgId,
        ...(queryConfig.currency.value !== "" && {
          currency: queryConfig.currency.value,
        }),
        rate: queryConfig.rate.value,
      },
      skip: !queryConfig.category.value,
    });
  const chartData = chartResult?.compensationTrends?.data || [];
  const currencyOptions =
    chartResult?.compensationTrends?.currencyOptions || [];
  const chartReady = !chartLoading;
  const chartCallCount = chartResult?.compensationTrends?.totalCallCount || 0;
  const chartCandidateCount =
    chartResult?.compensationTrends?.totalCandidateCount || 0;
  const chartSampleSize = `Sample: ${chartCallCount} interviews with ${chartCandidateCount} candidates.`;
  const noChartData = chartReady && chartData.length === 0;

  const { loading: tableLoading, data: tableResult } =
    useAnalyticsCompensationTrendsExamplesQuery({
      variables: {
        ...queryVariables,
        requestedOrganizationId: selectedOrgId,
        ...(queryConfig.category.value !== "ALL" && {
          category: queryConfig.category.value,
        }),
        ...(queryConfig.currency.value !== "" && {
          currency: queryConfig.currency.value,
        }),
      },
      skip: !queryConfig.category.value,
    });
  const tableData = tableResult?.compensationTrendsExamples?.data || [];
  const tableReady = !tableLoading;
  const tableCallCount =
    tableResult?.compensationTrendsExamples?.totalCallCount || 0;
  const tableCandidateCount =
    tableResult?.compensationTrendsExamples?.totalCandidateCount || 0;
  const tableSampleSize = tableReady
    ? `Showing first ${tableData.length} examples from ${tableCallCount} interviews with ${tableCandidateCount} candidates. Download CSV for all examples.`
    : null;

  const [fetchCsv, { loading: csvLoading }] =
    useAnalyticsCompensationTrendsExamplesCsvLazyQuery({
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data?.compensationTrendsExamplesCsv?.url) {
          const url = data?.compensationTrendsExamplesCsv?.url;
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = url;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      },
    });

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
        py="4"
        px="8"
      >
        <Flex flexDir="row" alignItems="baseline">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            Compensation Trends
          </Text>
          <AnalyticsInfoModal dangerousHTMLString="The Compensation Trends report analyzes interview transcripts to identify compensation and benefits discussions between candidates and interviewers. The examples section shows summarized excerpts of these compensation mentions." />
        </Flex>
        <Flex ml="auto">
          <Flex minW="148px">
            <AnalyticsDateSelect
              state={queryConfig.dateRange.value}
              onSelect={queryConfig.dateRange.setValue}
            />
          </Flex>
        </Flex>
      </Flex>
      <Box px="8" borderTop="1px" borderBottom="1px" borderColor="gray.200">
        <Flex flex="1" flexDir="row" alignItems="center" py="5">
          <Text fontSize="sm" color="gray.600" mr="2">
            Filters
          </Text>
          <Flex flex={{ base: "1", md: "0.6" }}>
            <AnalyticsFilters
              queryConfig={queryConfig}
              queryVariables={{
                ...queryVariables,
                forCompensation: true,
              }}
            />
          </Flex>
        </Flex>
      </Box>
      <Box mt="6" px="8">
        <LabeledChartSelect
          data-testid="analytics-compensation-trends--currency-menu"
          flexDir="row"
          alignItems="center"
          placeholder={queryConfig.currency.value || "All currencies"}
          label="Currency"
          singleSelect={{
            ...queryConfig.currency,
            options: [
              { value: "", label: "All currencies" },
              ...currencyOptions,
            ],
          }}
          customSelectStyles={{
            container: (provided: any) => ({
              ...provided,
              width: "250px",
            }),
          }}
          customLabelStyles={{
            fontSize: "sm",
            color: "gray.600",
          }}
        />
      </Box>
      <Box mt="6" px="8">
        <ReportContainer>
          <Flex flexDir="row" justifyContent="space-between" alignItems="start">
            <Flex flexDir="row" alignItems="center" mr="4">
              <Text fontSize="lg" fontWeight="600" color="gray.800" mr="4">
                Compensation Breakdown
              </Text>
              <LabeledChartSelect
                data-testid="analytics-compensation-trends--rate-menu"
                flexDir="row"
                alignItems="center"
                placeholder=""
                label=""
                singleSelect={{
                  ...queryConfig.rate,
                }}
                customSelectStyles={{
                  container: (provided: any) => ({
                    ...provided,
                    width: "200px",
                  }),
                }}
              />
            </Flex>
            <Text
              color="gray.600"
              fontWeight="400"
              fontSize="sm"
              whiteSpace="nowrap"
            >
              {queryConfig.dateRange.displayValue}
            </Text>
          </Flex>
          <Box>
            {chartLoading && <LoadingIndicator />}
            {noChartData && <AnalyticsReportHero image />}
            {!noChartData && chartReady && (
              <>
                <Text fontSize="sm" color="gray.600" mt="-3" mb="5">
                  {chartSampleSize}
                </Text>
                <AnalyticsCompensationTrendsChart
                  data={chartData}
                  currency={queryConfig.currency.value}
                />
              </>
            )}
          </Box>
        </ReportContainer>
      </Box>
      <Box px="8" mt="6" pb="8">
        <ReportContainer>
          <Flex flexDir="row" justifyContent="space-between" alignItems="start">
            <Flex flexDir="row" alignItems="center" mr="4">
              <Text fontSize="lg" fontWeight="600" color="gray.800" mr="4">
                Compensation Examples
              </Text>
              <LabeledChartSelect
                data-testid="analytics-topic-trends--speaker-menu"
                flexDir="row"
                alignItems="center"
                placeholder="All compensation"
                label=""
                singleSelect={{
                  ...queryConfig.category,
                  options: CATEGORY_OPTIONS,
                }}
                customSelectStyles={{
                  container: (provided: any) => ({
                    ...provided,
                    width: "250px",
                  }),
                }}
              />
            </Flex>
            <Tooltip label="Download full CSV results">
              <IconButton
                aria-label="Download full CSV results"
                icon={<Icon as={HiArrowDownTray} boxSize="5" />}
                isLoading={csvLoading || tableLoading}
                variant="ghost"
                onClick={() => {
                  sendGAEvent(
                    "download_compensation_trend_results",
                    "analytics"
                  );
                  fetchCsv({
                    variables: {
                      ...queryVariables,
                      requestedOrganizationId: selectedOrgId,
                      ...(queryConfig.category.value !== "ALL" && {
                        category: queryConfig.category.value,
                      }),
                      ...(queryConfig.currency.value !== "" && {
                        currency: queryConfig.currency.value,
                      }),
                    },
                  });
                }}
                onFocus={(e) => e.preventDefault()}
                hidden={!tableLoading && !tableData.length}
              />
            </Tooltip>
          </Flex>
          <Box mt="3" width="100%">
            {tableLoading && <AnalyticsReportTableSkeleton />}
            {tableReady && (
              <>
                {tableSampleSize && (
                  <Text fontSize="sm" color="gray.600" mt="-3" mb="5">
                    {tableSampleSize}
                  </Text>
                )}
                <AnalyticsCompensationTrendsTable tableData={tableData} />
              </>
            )}
          </Box>
        </ReportContainer>
      </Box>
    </>
  );
};

export default AnalyticsCompensationTrends;
