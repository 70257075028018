import { Box, ButtonProps } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import React, { useEffect, useState } from "react";

import { Button } from "../../../../../../components";

type EmphasizedButtonProps = {
  label: string;
  onClick: () => void;
} & ButtonProps;

const gradient =
  "linear-gradient(99.01deg, #0074DD -2.93%, #591EFF 58.85%, #9644FF 126.18%)";

// Define the shimmer animation
const shimmerKeyframes = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const EmphasizedButton: React.FC<EmphasizedButtonProps> = ({
  label,
  onClick,
  ...buttonProps
}) => {
  const [showShimmer, setShowShimmer] = useState(false);

  // Trigger the animation after component mount
  useEffect(() => {
    // Small delay to ensure the animation is visible after render
    const timer = setTimeout(() => {
      setShowShimmer(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box position="relative" overflow="hidden" width="100%" mt={2} mb={1}>
      <Button
        width="100%"
        size="prismLg"
        variant="prismPrimary"
        onClick={onClick}
        fontSize="sm"
        borderRadius="md"
        fontWeight="normal"
        background={gradient}
        _hover={{
          background: gradient,
        }}
        _active={{
          background: gradient,
        }}
        {...buttonProps}
      >
        {label}
      </Button>
      {showShimmer && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          pointerEvents="none"
          sx={{
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to right, transparent, rgba(255,255,255,0.3), transparent)",
              animation: `${shimmerKeyframes} 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards`,
            },
          }}
        />
      )}
    </Box>
  );
};

export default EmphasizedButton;
