import {
  Checkbox,
  FormControl,
  FormHelperText,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import { Button, SettingsPageContainer, useToast } from "../../../components";
import {
  CurrentUserFragment,
  FeatureName,
  useUpdateOrganizationAiSettingsMutation,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import { useHasFeatureAccess } from "../../hooks/useHasFeatureAccess";

interface AiSettingsProps {
  currentUser: CurrentUserFragment;
}

interface FormData {
  allowAiCustomTemplates: boolean;
  pasteAiNotesInScorecard: boolean;
  askEnabled: boolean;
}

interface AiSettingsFormProps {
  allowAiCustomTemplates: boolean;
  pasteAiNotesInScorecard: boolean;
  askEnabled: boolean;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
}

const AiSettingsForm: React.FC<AiSettingsFormProps> = ({
  allowAiCustomTemplates,
  pasteAiNotesInScorecard,
  askEnabled,
  isLoading,
  onSubmit,
}) => {
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      pasteAiNotesInScorecard,
      allowAiCustomTemplates,
      askEnabled,
    },
  });

  const aiNotesTemplateEnabled = useHasFeatureAccess(
    FeatureName.AiNotesTemplate
  );

  const interviewAskEnabledForUser = useFeatureFlag("ai-tabs:ask");
  const interviewAskEnabledForOrg = useFeatureFlag("ai-tabs:ask:for_orgs");
  const interviewAskEnabled =
    useHasFeatureAccess(FeatureName.InterviewAsk) &&
    (interviewAskEnabledForOrg || interviewAskEnabledForUser);

  const candidateAskEnabledForUser = useFeatureFlag("candidate:ask");
  const candidateAskEnabledForOrg = useFeatureFlag("candidate:ask:for_orgs");
  const candidateAskEnabled =
    useHasFeatureAccess(FeatureName.CandidateAsk) &&
    (candidateAskEnabledForOrg || candidateAskEnabledForUser);

  // show the askEnabled toggle if any of the feature flags are enabled
  const showAskEnabledToggle = interviewAskEnabled || candidateAskEnabled;

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
      })}
    >
      <VStack spacing="8" align="start">
        <FormControl id="pasteAiNotesInScorecard">
          <Checkbox {...register("pasteAiNotesInScorecard")}>
            Include AI notes when pasting interview notes to scorecard
          </Checkbox>
          <FormHelperText>
            This sends matched AI notes to complete scorecard questions for
            filling out Greenhouse Scorecards. Defaults to disabled.
          </FormHelperText>
        </FormControl>
        {aiNotesTemplateEnabled && (
          <FormControl id="allowAiCustomTemplates">
            <Checkbox {...register("allowAiCustomTemplates")}>
              Allow custom AI note templates for recordings
            </Checkbox>
            <FormHelperText>
              Checking this will allow users to build custom templates with free
              form text boxes that extract notes from the transcript using AI.
            </FormHelperText>
          </FormControl>
        )}
        {showAskEnabledToggle && (
          <FormControl id="askEnabled">
            <Checkbox {...register("askEnabled")}>
              Enable AI Ask features
            </Checkbox>
            <FormHelperText>
              Checking this will enable the AI Ask feature on the interview and
              candidate pages.
            </FormHelperText>
          </FormControl>
        )}
        <Button type="submit" isLoading={isLoading} data-testid="save">
          Save
        </Button>
      </VStack>
    </form>
  );
};

const AiSettings: React.FC<AiSettingsProps> = ({ currentUser }) => {
  const toast = useToast();

  const [updateOrganizationSettings, { loading: orgSettingsLoading }] =
    useUpdateOrganizationAiSettingsMutation({
      onCompleted: (data) => {
        if (data.updateOrganizationAiSettings?.organization) {
          toast({
            status: "success",
            title: "Organization Settings",
            description: "Success!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Organization Settings",
          description: "There was a problem - please try again",
        });
      },
    });

  return (
    <SettingsPageContainer
      heading="AI"
      subHeading="Configure AI based features in BrightHire."
    >
      <AiSettingsForm
        allowAiCustomTemplates={currentUser.organization.allowAiCustomTemplates}
        pasteAiNotesInScorecard={
          currentUser.organization.pasteAiNotesInScorecard
        }
        askEnabled={currentUser.organization.askEnabled}
        isLoading={orgSettingsLoading}
        onSubmit={(formData) =>
          updateOrganizationSettings({ variables: formData })
        }
      />
    </SettingsPageContainer>
  );
};

export default AiSettings;
