import { Box, FormLabel } from "@chakra-ui/react";
import React from "react";

type NotetakerDisplayImagePreviewProps = {
  imageFilename: string | null | undefined;
};

const NotetakerDisplayImagePreview: React.FC<
  NotetakerDisplayImagePreviewProps
> = ({ imageFilename }) => {
  if (!imageFilename) {
    return null;
  }

  const DEFAULT_BUCKET = "notetaker_display_images";

  // Extract bucket and filename if path includes bucket, otherwise use defaults
  const [bucket, filename] = imageFilename.includes("/")
    ? [imageFilename.split("/")[0], imageFilename.split("/")[1]]
    : [DEFAULT_BUCKET, imageFilename];

  return (
    <Box mt={4}>
      <FormLabel>Display Image Preview</FormLabel>
      <img src={`https://storage.googleapis.com/${bucket}/${filename}`} />
    </Box>
  );
};

export default NotetakerDisplayImagePreview;
