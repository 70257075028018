import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  LoadingIndicator,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import { useOptInSetupQuery, useUpdateOptInSetupMutation } from "../../graphql";

interface FormValues {
  whoIsPageText: string;
  yesNoPageText: string;
  alwaysCandidate: boolean;
  heroImageUrl: string;
  learnMoreLink: string;
}

const OptInLandingPageSettings: React.FC = () => {
  const toast = useToast();
  const { data, loading: queryLoading } = useOptInSetupQuery({
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to load settings.",
        status: "error",
      });
    },
  });

  const [updateOptInSetupMutation, { loading: mutationLoading }] =
    useUpdateOptInSetupMutation({
      onError: () => {
        toast({
          title: "Error",
          description: "Failed to save settings.",
          status: "error",
        });
      },
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      whoIsPageText: data?.optInSetup?.whoIsPageText,
      yesNoPageText: data?.optInSetup?.yesNoPageText,
      alwaysCandidate: data?.optInSetup?.alwaysCandidate,
      heroImageUrl: data?.optInSetup?.heroImageUrl,
      learnMoreLink: data?.optInSetup?.learnMoreLink,
    },
  });
  const formWatchValues = watch();
  const onSubmit = handleSubmit((formValues) => {
    updateOptInSetupMutation({
      variables: {
        whoIsPageText: formValues.whoIsPageText || "",
        yesNoPageText: formValues.yesNoPageText,
        alwaysCandidate: formValues.alwaysCandidate,
        heroImageUrl: formValues.heroImageUrl,
        learnMoreLink: formValues.learnMoreLink,
      },
      onCompleted: (data) => {
        if (data.updateOptInSetup.success) {
          toast({
            title: "Success",
            description: "Updated Opt In Settings",
            status: "success",
          });
        }
      },
    });
  });

  if (queryLoading || mutationLoading || !data) {
    return (
      <>
        <SettingsPageContainer heading="Opt-in landing page" />
        <LoadingIndicator mt="10%" />
      </>
    );
  }

  return (
    <SettingsPageContainer heading="Opt-in landing page">
      <form onSubmit={onSubmit}>
        <VStack spacing={6} alignItems="flex-start">
          <Box>
            <Text mb={2}>Opt In URL Stub</Text>
            <Textarea
              fontSize="sm"
              placeholder="Query path & params (do not include root url)"
              readOnly
              width={600}
              background="gray.100"
              value={data?.optInSetup?.optInUrlStub}
            />
          </Box>
          <FormControl id="alwaysCandidate" mb={4}>
            <Checkbox
              {...register("alwaysCandidate")}
              // defaultChecked={data?.optInSetup?.alwaysCandidate}
            >
              Skip interviewer/candidate selection page. Default to Candidate.
            </Checkbox>
            <FormHelperText>
              This setting will default to selecting candidate on the
              interviewer/candidate selection page. The candidate will be taken
              directly to the second page of opt-in.
            </FormHelperText>
          </FormControl>
          <FormControl
            id="whoIsPageText"
            isInvalid={errors.whoIsPageText !== undefined}
            isDisabled={formWatchValues.alwaysCandidate}
          >
            <FormLabel>
              Your interviewers and candidates will see the following text when
              they land on the interviewer/candidate selection page of opt-in.
              This text is customizable.
            </FormLabel>
            <Textarea
              width={600}
              fontSize="sm"
              {...register("whoIsPageText")}
              defaultValue={data?.optInSetup?.whoIsPageText}
            />
          </FormControl>
          <FormControl
            id="yesNoPageText"
            isInvalid={errors.yesNoPageText !== undefined}
          >
            <FormLabel>
              Your interviewers and candidates will see the following text when
              they land on the recording yes/no page of opt-in. This text is
              customizable.
            </FormLabel>
            <Textarea
              width={600}
              fontSize="sm"
              {...register("yesNoPageText")}
              defaultValue={data?.optInSetup?.yesNoPageText}
            />
          </FormControl>
          <FormControl
            id="learnMoreLink"
            isInvalid={errors.learnMoreLink !== undefined}
          >
            <FormLabel>
              Your interviewers and candidates will a learn more link when they
              land on the recording yes/no page of opt-in. This link is
              customizable.
            </FormLabel>
            <Textarea
              width={600}
              fontSize="sm"
              {...register("learnMoreLink")}
              defaultValue={data?.optInSetup?.learnMoreLink}
            />
          </FormControl>
          <FormControl
            id="heroImageUrl"
            isInvalid={errors.heroImageUrl !== undefined}
          >
            <FormLabel>
              Your interviewers and candidates will a hero image when they land
              on the recording yes/no page of opt-in. This image is customizable
              by providing a public URL to an image or reaching out to support
              and sending them the image.
            </FormLabel>
            <Textarea
              width={600}
              fontSize="sm"
              {...register("heroImageUrl")}
              defaultValue={data?.optInSetup?.heroImageUrl}
            />
          </FormControl>
          <Button
            type="submit"
            isLoading={mutationLoading}
            data-testid="opt-in-settings-submit"
          >
            Save
          </Button>
        </VStack>
      </form>
    </SettingsPageContainer>
  );
};

export default OptInLandingPageSettings;
