function getDataURLSize(dataURL: string): number {
  const base64 = dataURL.split(",")[1];
  // Approximate size in bytes (every 3 bytes are encoded as 4 base64 chars).
  return (base64.length * 3) / 4;
}

function reduceQualityToFitSize(
  canvas: HTMLCanvasElement,
  maxFileSizeBytes: number
): string {
  let quality = 1;
  let dataURL = canvas.toDataURL("image/jpeg", quality);
  let size = getDataURLSize(dataURL);

  while (size > maxFileSizeBytes && quality > 0.1) {
    quality -= 0.1;
    dataURL = canvas.toDataURL("image/jpeg", quality);
    size = getDataURLSize(dataURL);
    if (size <= maxFileSizeBytes) {
      return dataURL;
    }
  }

  // If no acceptable quality is found, return the smallest possible file
  return canvas.toDataURL("image/jpeg", quality);
}

async function loadImageFromFile(file: File): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error("Failed to load image"));
      img.src = reader.result as string;
    };
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };
  });
}

export interface GenerateDisplayImageOptions {
  canvasSize: { width: number; height: number };
  logoSubareaSize: { width: number; height: number };
  logo: File;
  backgroundColor: string;
  scale: "large" | "medium" | "small";
  maxFileSizeBytes?: number;
}

export async function generateDisplayImage({
  canvasSize,
  logoSubareaSize,
  logo,
  backgroundColor,
  scale,
  maxFileSizeBytes,
}: GenerateDisplayImageOptions): Promise<string> {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  canvas.width = canvasSize.width;
  canvas.height = canvasSize.height;
  // Enable image smoothing for better quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";
  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  let logoImage: HTMLImageElement;
  try {
    logoImage = await loadImageFromFile(logo);
  } catch (error) {
    throw new Error("Failed to load image");
  }

  // Resize logo to fit within logoSubareaSize
  let scalingFactor = Math.min(
    logoSubareaSize.width / logoImage.width,
    logoSubareaSize.height / logoImage.height
  );

  switch (scale) {
    case "medium":
      scalingFactor *= 0.75;
      break;
    case "small":
      scalingFactor *= 0.5;
      break;
  }

  // Calculate the scaled dimensions and center coordinates
  const scaledWidth = logoImage.width * scalingFactor;
  const scaledHeight = logoImage.height * scalingFactor;
  const x = (canvas.width - scaledWidth) / 2;
  const y = (canvas.height - scaledHeight) / 2;

  ctx.drawImage(logoImage, x, y, scaledWidth, scaledHeight);
  if (maxFileSizeBytes) {
    return reduceQualityToFitSize(canvas, maxFileSizeBytes);
  }

  return canvas.toDataURL("image/jpeg", 1);
}
