import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import Select from "react-select";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { SingleSelect } from "./types";
import { useAnalyticsSelectTheme } from "./useAnalyticsSelectTheme";

// The generic typing is so that the props are consistently all
// either number or string.
type LabeledChartSelectProps<T> = {
  label: string;
  singleSelect: SingleSelect<T>;
  testid?: string;
  customSelectStyles?: any;
  customLabelStyles?: any;
  placeholder?: string;
} & BoxProps;

const LabeledChartSelect = <T extends number | string | undefined>({
  label,
  singleSelect,
  testid,
  customSelectStyles,
  customLabelStyles,
  placeholder,
  ...boxProps
}: LabeledChartSelectProps<T>): ReactElement => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const sendGAEvent = useSendGAEvent();
  return (
    <Flex alignItems="center" {...boxProps}>
      <Text
        display="inline-block"
        fontSize="xs"
        mr="2"
        color="gray.500"
        mb={boxProps?.flexDir !== "row" ? "1" : undefined}
        {...customLabelStyles}
      >
        {label}
      </Text>
      <Box flexGrow={1} data-testid={testid}>
        <Select
          theme={selectTheme}
          styles={{ ...selectStyles, ...customSelectStyles }}
          isSearchable={false}
          placeholder={placeholder}
          isDisabled={singleSelect.disabled}
          value={singleSelect.options.filter(
            (o) => o.value === singleSelect.value
          )}
          options={singleSelect.options}
          onChange={(selectedOption) => {
            if (selectedOption) {
              singleSelect.setValue(selectedOption.value);
              singleSelect.afterValueChange?.();
              sendGAEvent(
                "option_selection",
                "analytics",
                label.toLocaleLowerCase(),
                `${selectedOption.value as string}`
              );
            }
          }}
          onMenuOpen={() =>
            sendGAEvent(
              "option_selection_open",
              "analytics",
              label.toLocaleLowerCase()
            )
          }
          onMenuClose={() =>
            sendGAEvent(
              "option_selection_close",
              "analytics",
              label.toLocaleLowerCase()
            )
          }
        />
      </Box>
    </Flex>
  );
};

export default LabeledChartSelect;
