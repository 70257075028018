import { ExampleTemplate } from "../types";

export const executiveSummaryExample: ExampleTemplate = {
  name: "Executive Summary",
  description: "Concise high level summary for executives.",
  prompt: `Write a short executive summary of the candidate from this interview.
  
Write three concise paragraphs (2-3 clear sentences). Each paragraph has a header.
* candidate background
* key skills and accomplishments
* fit for role (any additional key information that highlights this candidates fit for this role)

Avoid hype and judgemental language, preferring to plainly state the key information.`,
  output: `### Candidate Background  
The candidate has a strong background in post-sales roles, focusing on managing complex rollouts and fostering customer relationships. They have worked with various companies, handling diverse projects across industries such as retail and government. Their experience includes navigating challenging client dynamics and ensuring successful project implementations.  

### Key Skills and Accomplishments  
The candidate is skilled in using tools like Gantt charts, JIRA, and Salesforce to manage numerous projects and prioritize tasks effectively. They have a proven ability to standardize processes, build scalable systems, and address customer concerns. Notably, they have successfully managed detractors in client organizations, building trust and aligning stakeholders to achieve project goals.  

### Fit for Role  
The candidate's expertise in customer onboarding, low-touch implementation processes, and managing high workloads aligns well with roles focused on customer success and implementation. Their ability to handle complex customer relationships and operational challenges positions them as a strong candidate for roles requiring strategic planning and client management.`,
};
