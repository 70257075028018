import { FeatureName } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useHasFeatureAccess } from "../../../hooks/useHasFeatureAccess";

export const useCandidateAskEnabled = (): boolean => {
  const currentUser = useCurrentUser();
  const orgSettingEnabled = Boolean(currentUser?.organization?.askEnabled);

  const userEnabled = useFeatureFlag("candidate:ask");
  const orgEnabled = useFeatureFlag("candidate:ask:for_orgs");
  const flagEnabled = userEnabled || orgEnabled;

  const hasFeatureAccess = useHasFeatureAccess(FeatureName.CandidateAsk);

  // Both organization setting and feature flags must be enabled
  return Boolean(hasFeatureAccess && orgSettingEnabled && flagEnabled);
};
