import { parsePhoneNumberWithError, PhoneNumber } from "libphonenumber-js";

import { CandidatePhoneNumber, Organization, User } from "../main/graphql";

type PhoneNumberPick = Pick<CandidatePhoneNumber, "type" | "atsType">;

export const formatPhoneType = (phoneNumber: PhoneNumberPick): string => {
  const { type } = phoneNumber;
  return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
};

type PickUserOrgPhone<T extends keyof User> = Pick<User, T> & {
  organization: Pick<Organization, "phoneNumber">;
};

/**
 * Returns the phone number that candidates should call
 * to reach the user and have BH record
 */
export const userCallbackNumber = (
  {
    voipPhoneNumber,
    organization: { phoneNumber: orgPhone },
  }: PickUserOrgPhone<"voipPhoneNumber">,
  countryCode: string | undefined,
  internationalNumbers?: Array<{
    countryCode: string;
    orgPhoneNumber: string;
  }> | null
): string => {
  if (countryCode && internationalNumbers) {
    // Find the country entry matching the countryCode
    const country = internationalNumbers.find(
      (entry) => entry.countryCode === countryCode
    );

    // If a matching country code is found, return the organization number
    if (country?.orgPhoneNumber) {
      return country?.orgPhoneNumber;
    }
  }

  if (voipPhoneNumber) {
    return voipPhoneNumber;
  }

  if (orgPhone) {
    return orgPhone;
  }

  return "(973) 629-8964";
};

export const parsePhoneNumber = (
  phoneNumberStr: string
): PhoneNumber | undefined => {
  try {
    // Remove all non-digit and non-plus characters from phone number
    let normalizedPhoneNumberStr = phoneNumberStr.replace(/[^\d+]/g, "");
    let parsedPhoneNumber;

    if (!normalizedPhoneNumberStr.startsWith("+")) {
      // Try to parse the phone number with 'US' as the default country code first
      parsedPhoneNumber = parsePhoneNumberWithError(
        normalizedPhoneNumberStr,
        "US"
      );

      // If parsing fails or the number is not valid, try in international format
      if (!parsedPhoneNumber?.isPossible()) {
        normalizedPhoneNumberStr = `+${normalizedPhoneNumberStr}`;
        parsedPhoneNumber = parsePhoneNumberWithError(normalizedPhoneNumberStr);
      }
      return parsedPhoneNumber;
    }

    // If the number starts with a '+', try to parse it as an international number first
    if (normalizedPhoneNumberStr.startsWith("+")) {
      parsedPhoneNumber = parsePhoneNumberWithError(normalizedPhoneNumberStr);

      // If parsing fails or the number is not valid, try with 'US' as the default country code
      if (!parsedPhoneNumber?.isPossible()) {
        parsedPhoneNumber = parsePhoneNumberWithError(
          normalizedPhoneNumberStr,
          "US"
        );
      }

      return parsedPhoneNumber;
    }
  } catch (e) {
    return undefined;
  }
};

export const isIntlPhoneNumber = (phoneNumberStr: string): boolean => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumberStr);

  if (!parsedPhoneNumber) {
    return false;
  }

  // Check if the parsed number is possible or from the US
  if (
    !parsedPhoneNumber.isPossible() ||
    parsedPhoneNumber.country === "US" ||
    parsedPhoneNumber.countryCallingCode === "1"
  ) {
    return false;
  }

  return true;
};

export const isValidPhoneNumber = (phoneNumberStr: string): boolean => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumberStr);

  if (!parsedPhoneNumber) {
    return false;
  }

  // Check if the parsed number is possible
  return parsedPhoneNumber.isPossible();
};

export const getOrgInternationalVoipNumber = (
  phoneNumber: string,
  internationalNumbers?: Array<{
    countryCode: string;
    orgPhoneNumber: string;
  }> | null
): string | null => {
  const phone = parsePhoneNumber(phoneNumber);
  if (!phone) {
    return null;
  }
  const countryCode = phone?.countryCallingCode;
  if (internationalNumbers) {
    const country = internationalNumbers.find(
      (number) => number.countryCode === countryCode
    );
    if (country?.orgPhoneNumber) {
      return country.orgPhoneNumber;
    }
  }
  return null;
};
