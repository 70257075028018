import { ExampleTemplate } from "../types";

export const debriefExample: ExampleTemplate = {
  name: "Candidate Debrief",
  description: "Summarize a candidate debrief meeting.",
  prompt: `This call is a candidate debrief. Organize topics with h2 headers and bullets with citations.

Organize by these topics:
* For each participant, summarize their overall feedback briefly in their own topic.
* Then, for each major topic supporting or not supporting the candidate, summarize the feedback from all meeting participants.
* Then, list any decisions made during the meeting.
* Finally, include any next steps and follow ups mentioned in the meeting.

Do not insert your own judgement, that is for the particpants to decide. Only summarize the feedback given in the meeting, and do not insert your own opinions.`,
  output: `# Participant Feedback Summaries

## Participant 1: Sarah Johnson 
- **Overall Feedback:** Sarah was impressed with the candidate's technical skills and their ability to articulate complex ideas clearly. She noted that the candidate demonstrated problem-solving abilities during hypothetical scenario discussions [10:02](?t=602).
  
## Participant 2: Michael Lee 
- **Overall Feedback:** Michael appreciated the candidate's leadership potential, citing instances where the candidate displayed initiative and a positive attitude towards team collaboration [12:30](?t=750).

## Participant 3: Jessica Rivera 
- **Overall Feedback:** Jessica expressed concern over the candidate's limited experience in the industry but acknowledged their willingness to learn and adapt quickly [14:15](?t=855).

# Key Topics Supporting the Candidate

## Technical Proficiency 
- Participants agreed the candidate exhibited strong technical knowledge relevant to the role. They were able to effectively answer technical questions and solve problems presented to them [16:40](?t=1000).

## Communication Skills
- The candidate received positive reviews for their ability to communicate clearly and effectively. This was evident through their responses and explanations during the interview process [19:25](?t=1165).

# Key Topics Not Supporting the Candidate

## Industry Experience
- Concerns were raised about the candidate’s lack of professional experience in the specific industry, which could pose challenges in adapting to the role [22:10](?t=1330).

## Time Management
- Participants noted potential issues with the candidate’s time management skills, as highlighted by their delay in providing a solution to one of the hypothetical scenarios [25:45](?t=1545).

# Next Steps and Decisions

## Decisions Made During the Meeting
- The team decided that the candidate should proceed to the next round of interviews, given their strong technical skills and communication abilities outweighing current experience gaps [30:00](?t=1800).

## Next Steps
- Schedule a follow-up interview focusing on cultural fit and understanding of the company’s industry. The candidate will receive feedback on points of concern to help them prepare [35:20](?t=2120).`,
};
