import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  LoadingIndicator,
  SettingsPageContainer,
  useToast,
} from "../../../components";
import LeverApiKeySetupForm from "../../components/LeverSettingsSetup/LeverApiKeySetupForm";
import LeverOauthSettingsSetup from "../../components/LeverSettingsSetup/LeverOauthSettingsSetup";
import {
  CurrentUserFragment,
  useLeverAuthOnRedirectMutation,
  useLeverOauthSetupQuery,
  useLeverSetupQuery,
} from "../../graphql";

interface LeverSettingsProps {
  currentUser: CurrentUserFragment;
}

const LeverSettings: React.FC<LeverSettingsProps> = ({ currentUser }) => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leverCode = searchParams.get("code");
  const leverStateToken = searchParams.get("state");
  const responseError = searchParams.get("error");
  const responseErrorDescription = searchParams.get("error_description");

  const { data, loading } = useLeverSetupQuery();
  const {
    data: oauthSetupData,
    loading: oauthSetupLoading,
    refetch: oauthSetupRefetch,
  } = useLeverOauthSetupQuery();

  const [authLever] = useLeverAuthOnRedirectMutation({
    onError: () => {
      toast({
        status: "error",
        title: "Authorizing Lever",
        description: "There was a problem - please try again",
      });
      navigate(location.pathname, { replace: true });
    },
    onCompleted: () => {
      navigate(location.pathname, { replace: true });
    },
  });

  useEffect(() => {
    if (currentUser.userRole?.canManageIntegrationSettings) {
      if (leverStateToken) {
        authLever({
          variables: {
            leverCode,
            leverStateToken,
            responseError,
            responseErrorDescription,
          },
        });
      }
    }
  }, []);

  if (loading || oauthSetupLoading || leverStateToken)
    return <LoadingIndicator />;

  return (
    <SettingsPageContainer heading="Lever">
      <Flex dir="column" mt={4} mb={2}>
        <Text fontWeight="bold" mr={2}>
          Integration Status:{" "}
        </Text>
        {data?.leverSetup?.valid || oauthSetupData?.leverOauthSetup?.valid ? (
          <Icon
            mt="2px"
            as={IoMdCheckmarkCircleOutline}
            color="green.400"
            height="5"
            width="5"
          />
        ) : (
          <Icon
            mt="2px"
            as={IoMdCloseCircleOutline}
            color="red.600"
            height="5"
            width="5"
          />
        )}
      </Flex>
      <Box>
        {!data?.leverSetup?.enabled && (
          <LeverOauthSettingsSetup
            currentUser={currentUser}
            oauthSetupRefetch={oauthSetupRefetch}
            leverOauthSetup={oauthSetupData?.leverOauthSetup}
          />
        )}
        {data?.leverSetup?.enabled && (
          <LeverApiKeySetupForm
            currentUser={currentUser}
            leverSetupData={data?.leverSetup}
          />
        )}
      </Box>
    </SettingsPageContainer>
  );
};

export default LeverSettings;
