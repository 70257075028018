/* eslint-disable camelcase */
// cspell:ignore pois
import {
  SearchForFacetValuesQueryParams,
  SearchOptions,
} from "@algolia/client-search";
import algoliasearch, { SearchClient } from "algoliasearch/lite";
import invariant from "invariant";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import { InstantSearch as InstantSearchBase } from "react-instantsearch-dom";
import { Location, useLocation, useNavigate } from "react-router-dom";

import config from "../../config";

const updateAfter = 700;

interface Range {
  min?: number;
  max?: number;
}

const createURL = (state: { [key: string]: any }): string => {
  const queryParameters: {
    q?: string;
    organization?: string | string[];
    interviewer?: string | string[];
    candidate?: string | string[];
    position?: string | string[];
    client?: string | string[];
    cues?: string | string[];
    stage?: string | string[];
    type?: string | string[];
    pois?: string | string[];
    status?: string | string[];
    rating?: string | string[];
    date?: Range;
    duration?: Range;
    talk_ratio?: Range;
    longest_monologue?: Range;
    metric?: string;
    source?: string | string[];
    template_id?: string;
  } = {};

  if (state.metric) {
    queryParameters.metric = state.metric;
  }

  if (state.source) {
    queryParameters.source = state.source;
  }

  if (state.query) {
    queryParameters.q = state.query;
  }

  if (state.refinementList) {
    const {
      organization_name: organization,
      "interviewers.name": interviewer,
      "candidate.name": candidate,
      "position.display_title": position,
      "position.client_name": client,
      cues,
      stage,
      type,
      pois,
      status,
      rating,
    } = state.refinementList;
    if (organization) {
      queryParameters.organization = organization;
    }
    if (interviewer) {
      queryParameters.interviewer = interviewer;
    }
    if (candidate) {
      queryParameters.candidate = candidate;
    }
    if (candidate) {
      queryParameters.candidate = candidate;
    }
    if (position) {
      queryParameters.position = position;
    }
    if (client) {
      queryParameters.client = client;
    }
    if (cues) {
      queryParameters.cues = cues;
    }
    if (stage) {
      queryParameters.stage = stage;
    }
    if (pois) {
      queryParameters.pois = pois;
    }
    if (status) {
      queryParameters.status = status;
    }
    if (rating) {
      queryParameters.rating = rating;
    }
    if (type) {
      queryParameters.type = type;
    }
  }

  if (state.range) {
    const {
      call_timestamp_seconds: date,
      call_duration_seconds: duration,
      interviewer_talk_time_percentage: talk_ratio,
      interviewer_longest_monologue_seconds: longest_monologue,
    } = state.range;
    if (date) {
      queryParameters.date = date;
    }
    if (duration) {
      queryParameters.duration = duration;
    }
    if (talk_ratio) {
      queryParameters.talk_ratio = talk_ratio;
    }
    if (longest_monologue) {
      queryParameters.longest_monologue = longest_monologue;
    }
  }

  if (state.template_id) {
    queryParameters.template_id = state.template_id;
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  });

  return queryString;
};

const searchStateToUrl = (
  location: Location,
  searchState: Record<string, unknown>
): string =>
  searchState ? `${location.pathname}${createURL(searchState)}` : "";

const urlToSearchState = (location: Location): Record<string, unknown> => {
  const {
    q = "",
    organization = [],
    interviewer = [],
    candidate = [],
    position = [],
    client = [],
    cues = [],
    stage = [],
    type = [],
    pois = [],
    status = [],
    rating = [],
    date = {},
    duration = {},
    talk_ratio = {},
    longest_monologue = {},
    metric,
    source = [],
    template_id,
  } = qs.parse(location.search.slice(1));
  return {
    query: q,
    page: 1,
    refinementList: {
      organization_name: organization,
      "interviewers.name": interviewer,
      "candidate.name": candidate,
      "position.display_title": position,
      "position.client_name": client,
      cues,
      type,
      stage,
      pois,
      status,
      rating,
    },
    range: {
      call_timestamp_seconds: date,
      call_duration_seconds: duration,
      interviewer_talk_time_percentage: talk_ratio,
      interviewer_longest_monologue_seconds: longest_monologue,
    },
    sortBy: config.algoliaIndex,
    metric,
    source,
    template_id,
  };
};

const searchClientProxy = (searchClient: SearchClient): any => ({
  // Don't make a search request with no query / filters
  ...searchClient,
  search(requests: any) {
    if (
      requests.every(
        ({
          params,
        }: {
          params: SearchForFacetValuesQueryParams & SearchOptions;
        }) => !params.query
      )
    ) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }
    return searchClient.search(requests);
  },
});

interface InstantSearchProps {
  allowEmptyQuery?: boolean;
  children: React.ReactNode;
  searchApiKey: string;
  updateUrl?: boolean;
}

const InstantSearch: React.FC<InstantSearchProps> = ({
  searchApiKey,
  allowEmptyQuery = false,
  updateUrl = false,
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState<Record<string, unknown>>(
    urlToSearchState(location)
  );
  const setStateTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  invariant(config.algoliaApplicationId, "Missing ALGOLIA_APPLICATION_ID");
  invariant(config.algoliaIndex, "Missing ALGOLIA_INDEX");

  // type 'any' to facilitate workaround
  let searchClient: any = algoliasearch(
    config.algoliaApplicationId,
    searchApiKey
  );

  if (!allowEmptyQuery) {
    searchClient = searchClientProxy(searchClient);
  }

  // Workaround start
  // https://github.com/algolia/algoliasearch-client-javascript/issues/1035#issuecomment-592014999
  delete searchClient.transporter.queryParameters["x-algolia-api-key"];
  const { read } = searchClient.transporter;
  searchClient.transporter.read = (request: any, requestOptions: any) => {
    return read(request, { apiKey: searchApiKey, ...requestOptions });
  };
  // Workaround end

  const onSearchStateChange = (searchState: Record<string, unknown>): void => {
    if (setStateTimeoutRef.current) {
      clearTimeout(setStateTimeoutRef.current);
    }

    setStateTimeoutRef.current = setTimeout(() => {
      navigate(searchStateToUrl(location, searchState), {
        state: searchState,
        replace: true,
      });
    }, updateAfter);

    setSearchState(searchState);
  };

  return (
    <InstantSearchBase
      searchClient={searchClient}
      indexName={config.algoliaIndex}
      searchState={updateUrl ? searchState : undefined}
      onSearchStateChange={updateUrl ? onSearchStateChange : undefined}
      createURL={updateUrl ? createURL : undefined}
    >
      {children}
    </InstantSearchBase>
  );
};

const InstantSearchTest: React.FC<InstantSearchProps> = ({ children }) => (
  <>{children}</>
);

/**
 * Wraps Algolia `<InstantSearch />` component with given `searchApiKey`
 * and optionally handles reading / writing search state to URL. Renders
 * `children` as-is
 */
export default config.appEnv === "test" ? InstantSearchTest : InstantSearch;
