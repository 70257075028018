import {
  Box,
  MenuItem,
  MenuItemProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineTrash } from "react-icons/hi";

import { ConfirmModal } from "../../../components";
import { useDeleteAiSummaryTemplateMutation } from "../../graphql";

type DeleteTemplateMenuItemProps = {
  templateId: string;
  onDeleted?: () => void;
} & MenuItemProps;

const DeleteTemplateMenuItem: React.FC<DeleteTemplateMenuItemProps> = ({
  templateId,
  onDeleted,
  ...menuItemProps
}) => {
  const toast = useToast();
  const confirmDeleteModal = useDisclosure();

  const [deleteTemplate] = useDeleteAiSummaryTemplateMutation({
    update(cache, { data }) {
      const deletedTemplate = data?.deleteAiSummaryTemplate?.template;

      if (deletedTemplate) {
        cache.evict({
          id: cache.identify(deletedTemplate),
        });
        cache.gc();
      }
    },
    onCompleted() {
      toast({
        title: "Template deleted",
        status: "success",
      });
      onDeleted?.();
    },
  });

  const handleConfirm = (): void => {
    deleteTemplate({ variables: { id: templateId } });
    confirmDeleteModal.onClose();
  };

  return (
    <>
      <MenuItem
        value="delete"
        color="red.500"
        onClick={confirmDeleteModal.onOpen}
        {...menuItemProps}
      >
        <HiOutlineTrash size="20px" />
        <Box flex="1" ml="2">
          Delete
        </Box>
      </MenuItem>
      <ConfirmModal
        modalBodyText="Are you sure you want to delete this template?"
        isOpen={confirmDeleteModal.isOpen}
        onCancel={confirmDeleteModal.onClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default DeleteTemplateMenuItem;
