import LogRocket from "logrocket";
import React from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";

const CreateTemplateButton: React.FC = () => {
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();

  return (
    <Button
      ml={4}
      variant="prismPrimary"
      size="prismMd"
      leftIcon={<HiOutlinePlus />}
      onClick={() => {
        LogRocket.track(`ai-notes-templates-create-start`);
        sendGAEvent(
          "ai_notes_custom_template_create_start",
          "call_ai_templates"
        );
        navigate("/template/new/pick");
      }}
    >
      New template
    </Button>
  );
};

export default CreateTemplateButton;
