import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CallAiSummaryTemplateFragment,
  CustomTemplateCustomType,
} from "../../graphql";
import EditCustomTemplate from "./EditCustomTemplate";
import PickExampleTemplate from "./PickExampleTemplate";
import PickOrCreateTemplate from "./PickOrCreateTemplate";
import { ExampleTemplate } from "./types";
import useCustomTemplate from "./useCustomTemplate";

type CustomTemplateProps = {
  template: CallAiSummaryTemplateFragment | null;
  fromCallId: string | null;
};

const CustomTemplate: React.FC<CustomTemplateProps> = ({
  template,
  fromCallId,
}) => {
  const sendGAEvent = useSendGAEvent();
  const {
    createCustomTemplate,
    updateCustomTemplate,
    duplicateCustomTemplate,
    navigateBack,
  } = useCustomTemplate({ fromCallId });

  const [customPrompt, setCustomPrompt] = useState(
    template?.customPrompt || ""
  );
  const [templateName, setTemplateName] = useState<string>(
    template?.name || ""
  );
  const isLegacyTemplate =
    template !== null &&
    (!template.customType ||
      template.customType === CustomTemplateCustomType.Sections);
  const [customType, setCustomType] = useState<CustomTemplateCustomType>(
    isLegacyTemplate
      ? CustomTemplateCustomType.Sections
      : CustomTemplateCustomType.CustomPrompt
  );
  const navigate = useNavigate();

  const { pathname, search } = useLocation();
  const pathAction = pathname.split("/").pop();
  const switchAction = (action: "pick" | "example" | "edit"): void => {
    sendGAEvent(
      "ai_notes_custom_template_switch_action",
      "call_ai_templates",
      action
    );
    navigate(`/template/${template?.id || "new"}/${action}${search}`);
  };
  const pickExample = (example: ExampleTemplate): void => {
    setTemplateName(example.name);
    setCustomPrompt(example.prompt);
    setCustomType(CustomTemplateCustomType.CustomPrompt);
    switchAction("edit");
  };

  const navigateBackWithFallback = (): void => {
    // Check if there's a history entry to go back to
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      // No history entry, navigate to a default route
      navigate("/");
    }
  };

  if (pathAction === "pick") {
    return (
      <PickOrCreateTemplate
        templateId={template?.id}
        onClose={navigateBack}
        onContinueToPickTemplate={() => switchAction("example")}
        onContinueToWriteTemplate={() => switchAction("edit")}
        onBack={navigateBackWithFallback}
      />
    );
  }
  if (pathAction === "example") {
    return (
      <PickExampleTemplate
        templateId={template?.id}
        onClose={() => navigateBack(template?.id)}
        onPickExample={pickExample}
        onBack={navigateBackWithFallback}
      />
    );
  }

  return (
    <EditCustomTemplate
      template={template}
      onClose={(templateId?: string) => navigateBack(templateId)}
      onSave={template ? updateCustomTemplate : createCustomTemplate}
      onCopy={(id: string) => duplicateCustomTemplate(id)}
      customPrompt={customPrompt}
      setCustomPrompt={setCustomPrompt}
      templateName={templateName}
      setTemplateName={setTemplateName}
      switchToExamplePrompts={() => switchAction("example")}
      customType={customType}
      setCustomType={setCustomType}
      isLegacyTemplate={isLegacyTemplate}
      onBack={navigateBackWithFallback}
    />
  );
};

export default CustomTemplate;
