import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  ButtonProps,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { ReactElement, useCallback } from "react";

import { Button } from "../Buttons";

interface ButtonWithConfirmDialogueProps {
  buttonProps: ButtonProps;
  headerText?: string;
  headerBody?: string | ReactElement;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onConfirm: (
    onSuccess: (msg?: string) => () => void,
    onError: (error?: Error) => void
  ) => void;
  additionalOnClick?: () => void;
}

export const ButtonWithConfirmDialogue: React.FC<
  React.PropsWithChildren<ButtonWithConfirmDialogueProps>
> = ({
  buttonProps,
  children,
  headerText = "Please confirm",
  headerBody = "Are you sure you wish to proceed?",
  cancelButtonText = "Cancel",
  confirmButtonText = "Confirm",
  onConfirm,
  additionalOnClick,
}): React.ReactElement => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const cancelRef = React.createRef<HTMLButtonElement>();

  const newOnClick = (): void => {
    if (additionalOnClick) {
      additionalOnClick();
    }
    onOpen();
  };

  const onSuccess = useCallback((description?: string) => {
    return () => {
      toast({
        position: "top",
        title: "Success",
        status: "success",
        description,
      });
    };
  }, []);

  const onError = useCallback((error?: Error) => {
    toast({
      position: "top",
      title: "Error",
      status: "error",
      description: error?.message,
    });
  }, []);

  return (
    <>
      <Button variant="danger" onClick={newOnClick} {...buttonProps}>
        {children}
      </Button>

      <AlertDialog
        motionPreset="slideInRight"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headerText}
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <AlertDialogBody>{headerBody}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {cancelButtonText}
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  onConfirm(onSuccess, onError);
                  onClose();
                }}
                ml={3}
              >
                {confirmButtonText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
