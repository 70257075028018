import { Box, Flex, FlexProps, Icon, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineVideoCamera,
} from "react-icons/hi2";

import { NoInterviewsIcon } from "../../../components";
import useCurrentBreakpoint from "../../../hooks/useCurrentBreakpoint";
import useResizeObserver from "../../../hooks/useResizeObserver";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import {
  CallVisibility,
  ScheduledInterviewListItemFragment,
} from "../../graphql";
import { useIsExtension } from "../../hooks/useAppEnvironmentContext";
import { ColumnHeaderBorder, ColumnHeading } from "./ColumnHeading";
import InterviewListItemV2 from "./InterviewListItemV2";
import SummaryEmptyStateWrapper from "./summary/SummaryEmptyStateWrapper";
import SummaryTabV2 from "./summary/SummaryTabV2";
import SummaryTooltipIcon from "./summary/SummaryTooltipIcon";
import { useCanViewCandidateSummary } from "./summary/useCanViewCandidateSummary";
import { CandidateCall } from "./types";

type InterviewTabProps = {
  calls: Array<CandidateCall>;
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  currentUserNotesOnly: boolean;
  candidateId: string;
  positionId?: string;
  summaryCallCount: number;
};

const InterviewTabV2: React.FC<InterviewTabProps> = ({
  calls,
  scheduledInterviews,
  currentUserNotesOnly,
  candidateId,
  positionId,
  summaryCallCount,
}) => {
  const summariesVisibility = useCanViewCandidateSummary(positionId || null);

  const [interviewsExpandedIfPossible, setInterviewsExpandedIfPossible] =
    useState(true);
  const currentBreakpoint = useCurrentBreakpoint();
  let interviewsExpanded =
    !["lg", "md", "sm", "base"].includes(currentBreakpoint) &&
    interviewsExpandedIfPossible;
  if (!summariesVisibility.summariesEnabled) {
    interviewsExpanded = true;
  }
  const { windowHeight } = useWindowDimensions();
  const [totalWidth, setTotalWidth] = useState(0);
  const { ref: containerRef } = useResizeObserver<HTMLDivElement>({
    skip: !summariesVisibility.summariesEnabled,
    onResize: (el) => setTotalWidth(el.offsetWidth),
  });
  const recordingsColumnMinWidth = 257;
  const debriefColumnMinWidth = 420;

  // We manually calculate the expanded width of the debrief so it animates
  // smoothly (without constantly re-wrapping text as its width changes)
  const debriefExpandedWidth =
    totalWidth -
    recordingsColumnMinWidth - // recordings column width
    16 - // gap between columns
    2; // border on column
  const debriefExpandedWidthStr = `${debriefExpandedWidth}px`;

  const isExtension = useIsExtension();

  if (["base", "sm"].includes(currentBreakpoint) || isExtension) {
    return (
      <Flex flexDir="column" gap={4}>
        {summariesVisibility.summariesEnabled &&
          !summariesVisibility.loading &&
          summariesVisibility.canViewSummaries && (
            <CollapsibleColumnWrapper
              title="Topics &amp; Summary"
              defaultExpanded={!isExtension}
              headerTooltip={
                <SummaryTooltipIcon
                  iconProps={{ display: "flex", mt: "0", mb: "2px" }}
                />
              }
            >
              {summaryCallCount === 0 ? (
                <SummaryNotAvailableForCalls />
              ) : (
                <SummaryTabV2
                  candidateId={candidateId}
                  positionId={positionId}
                  notesExpanded={false}
                  toggleNotes={() =>
                    setInterviewsExpandedIfPossible((expanded) => !expanded)
                  }
                  innerExpandedWidth={debriefExpandedWidthStr}
                  numCalls={calls.length}
                />
              )}
            </CollapsibleColumnWrapper>
          )}
        <CollapsibleColumnWrapper title="Recordings" defaultExpanded>
          <RecordingsColumn
            scheduledInterviews={scheduledInterviews}
            calls={calls}
            currentUserNotesOnly={currentUserNotesOnly}
            interviewsExpanded
          />
        </CollapsibleColumnWrapper>
      </Flex>
    );
  }

  return (
    <Flex
      ref={containerRef}
      flexDir="row"
      gap={4}
      height="100%" // TODO: "undefined" when no content
      maxH={parseInt(windowHeight) - 238}
    >
      <ColumnWrapper
        flex={
          interviewsExpanded ? "1 1 100%" : `0 1 ${recordingsColumnMinWidth}px`
        }
        minWidth={recordingsColumnMinWidth}
        transition="flex-basis 0.3s"
        pb={0}
      >
        <RecordingsColumn
          scheduledInterviews={scheduledInterviews}
          calls={calls}
          currentUserNotesOnly={currentUserNotesOnly}
          interviewsExpanded={interviewsExpanded}
        />
      </ColumnWrapper>

      {summariesVisibility.summariesEnabled && (
        <ColumnWrapper
          flex={
            interviewsExpanded ? `0 1 ${debriefColumnMinWidth}px` : "1 1 100%"
          }
          minWidth={debriefColumnMinWidth}
          transition="flex-basis 0.3s"
        >
          {!summariesVisibility.loading && (
            <>
              {!summariesVisibility.canViewSummaries ? (
                <SummaryNotAvailableForUser />
              ) : summaryCallCount === 0 ? (
                <SummaryNotAvailableForCalls />
              ) : (
                <SummaryTabV2
                  candidateId={candidateId}
                  positionId={positionId}
                  notesExpanded={!interviewsExpanded}
                  toggleNotes={() =>
                    setInterviewsExpandedIfPossible((expanded) => !expanded)
                  }
                  innerExpandedWidth={debriefExpandedWidthStr}
                  numCalls={calls.length}
                />
              )}
            </>
          )}
        </ColumnWrapper>
      )}
    </Flex>
  );
};

const RecordingsColumn: React.FC<{
  scheduledInterviews: Array<ScheduledInterviewListItemFragment>;
  calls: Array<CandidateCall>;
  currentUserNotesOnly: boolean;
  interviewsExpanded: boolean;
}> = ({
  scheduledInterviews,
  calls,
  currentUserNotesOnly,
  interviewsExpanded,
}) => {
  const currentBreakpoint = useCurrentBreakpoint();

  return (
    <Flex flexDir="column" flex="1 1 auto" overflow="hidden">
      {!["base", "sm"].includes(currentBreakpoint) && (
        <Box px={6}>
          <ColumnHeading iconType={HiOutlineVideoCamera} title="Recordings" />
          <ColumnHeaderBorder />
        </Box>
      )}
      <Flex direction="column" overflow="auto" px={6}>
        {scheduledInterviews.length > 0 &&
          scheduledInterviews.map((scheduledInterview, i) => {
            return (
              <InterviewListItemV2
                data-intercom="completed-interview"
                key={scheduledInterview.id}
                scheduledInterview={scheduledInterview}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
              />
            );
          })}
        {calls.length > 0 &&
          calls.map((call, i) => {
            return (
              <InterviewListItemV2
                data-intercom="completed-interview"
                key={call.id}
                call={call}
                listPosition={i + 1}
                currentUserNotesOnly={currentUserNotesOnly}
                showVisibility={[
                  CallVisibility.Private,
                  CallVisibility.Restricted,
                ].includes(call.visibility)}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

const ColumnWrapper: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      h="fit-content"
      maxH="100%"
      border="1px"
      borderColor="gray.100"
      borderRadius="2xl"
      py={4}
      overflow="hidden"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

const CollapsibleColumnWrapper: React.FC<
  {
    title: string;
    children: React.ReactNode;
    defaultExpanded?: boolean;
    headerTooltip?: React.ReactElement;
    betaTag?: React.ReactElement;
  } & FlexProps
> = ({
  title,
  defaultExpanded,
  children,
  headerTooltip,
  betaTag,
  ...flexProps
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded || false);
  return (
    <Flex
      flexDir="column"
      border="1px"
      borderColor="gray.100"
      borderRadius="lg"
      py={5}
      {...flexProps}
    >
      <Flex flexDir="row" alignItems="center" px={6}>
        <Text fontSize="md" fontWeight="600" color="gray.800">
          {title}
        </Text>
        {headerTooltip}
        {betaTag}
        <Icon
          boxSize={6}
          color="blue.600"
          marginLeft="auto"
          cursor="pointer"
          as={expanded ? HiOutlineChevronUp : HiOutlineChevronDown}
          onClick={(e) => {
            setExpanded(!expanded);
          }}
          userSelect="none"
        />
      </Flex>
      {expanded && (
        <>
          <ColumnHeaderBorder mt={5} mx={6} />
          {children}
        </>
      )}
    </Flex>
  );
};

const SummaryNotAvailableForUser: React.FC = () => {
  return (
    <SummaryEmptyStateWrapper px="10">
      <NoInterviewsIcon mb="5" maxW="300px" />
      <Text
        fontSize="16px"
        mt="2"
        fontWeight={600}
        color="gray.200"
        textAlign="center"
      >
        You do not have permissions to see the Candidate Summary for this
        candidate.
      </Text>
    </SummaryEmptyStateWrapper>
  );
};

const SummaryNotAvailableForCalls: React.FC = () => {
  return (
    <SummaryEmptyStateWrapper px="10">
      <NoInterviewsIcon mb="5" maxW="300px" />
      <Text align="center" maxW="300px" textAlign="center">
        We are unable to generate a topic list and candidate summary because
        there are no non-restricted interviews for this candidate. Change the
        share setting on one more more existing interviews to &ldquo;Hiring Team
        + Anyone Invited&rdquo; to make them available for generated summaries.
      </Text>
    </SummaryEmptyStateWrapper>
  );
};

export default InterviewTabV2;
