import { Select } from "@chakra-ui/react";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { OrgZoomAppAutoOpenRule } from "../main/graphql";
import { FormControl, FormControlProps } from "./FormControl";
import { Link } from "./Link";

type ZoomAppAutoOpenOrgSettingProps = Omit<FormControlProps, "children"> & {
  register: UseFormRegisterReturn;
};

export const ZoomAppAutoOpenOrgSetting: React.FC<
  ZoomAppAutoOpenOrgSettingProps
> = ({ register, displayNameProps, ...props }) => {
  return (
    <FormControl
      displayName="Auto-open Interview Assistant in Zoom"
      displayNameProps={{
        fontSize: "md",
        color: "primary",
        ...displayNameProps,
      }}
      helpText={
        <>
          BrightHire can automatically open the Interview Assistant in Zoom when
          a user joins a Zoom meeting for one of their interviews. Users will
          need to install the{" "}
          <Link
            href="https://marketplace.zoom.us/apps/TUyIp7DkQM29tYD4qg0s9g"
            target="_blank"
          >
            BrightHire Interview Assistant Zoom app
          </Link>{" "}
          for this to work.
        </>
      }
      {...props}
    >
      <Select {...register}>
        <option value={OrgZoomAppAutoOpenRule.Disabled}>Disabled</option>
        <option value={OrgZoomAppAutoOpenRule.DefaultDisabled}>
          Enabled, default to off
        </option>
        <option value={OrgZoomAppAutoOpenRule.DefaultEnabled}>
          Enabled, default to on
        </option>
        <option value={OrgZoomAppAutoOpenRule.ForcedEnabled}>
          Forced enabled for all users
        </option>
      </Select>
    </FormControl>
  );
};
