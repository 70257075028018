import { Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components";
import AnalyticsInfoModal from "../AnalyticsInfoModal";

type SkillsReportHeaderProps = {
  includeBuild?: boolean;
  includeDetails?: boolean;
};

const SkillsReportHeader: React.FC<SkillsReportHeaderProps> = ({
  includeBuild,
  includeDetails,
}) => {
  const navigate = useNavigate();
  const startBuildReport = (): void => navigate("/insights/skill-report");
  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
      h={16}
      px={8}
      borderBottom="1px solid"
      borderColor="gray.200"
      backgroundColor="white"
    >
      <HStack spacing={2} align="center">
        <Text fontSize="xl" fontWeight="500" color="gray.800">
          Skills Coverage
        </Text>
        {includeDetails && (
          <AnalyticsInfoModal dangerousHTMLString="In the Skills Coverage report, each defined skill is measured as either ‘covered’ or ‘not covered’ for each interview. All interviews for a given candidate are then aggregated to determine if a skill was covered or not for a candidate. The bar chart indicates the percentage of candidates for the requested position(s) that discussed a skill in any of their interviews. Unavailable data is excluded from the score calculations." />
        )}
      </HStack>
      {includeBuild && (
        <Flex>
          <Button onClick={startBuildReport} colorScheme="blue" size="sm">
            New report
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default SkillsReportHeader;
