import { Flex } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";

import {
  AiNotesProcessingStatus,
  CallAiSummaryFormat,
  useExternalShareAiNotesLazyQuery,
} from "../../graphql";
import { useExternalShareParams } from "../../pages/external-share/useExternalShareParams";
import {
  AiNotesNoCandidate,
  AiNotesNoNotes,
  AiNotesNoTranscript,
} from "../AiNotes/AiNotesErrors";
import { ReadonlyAiNoteBlock } from "../AiNotes/ReadonlyAiNoteBlock";
import AiNotesLoading from "../Recording/SidebarTabs/Ai/Notes/NotesQA/AiNotesLoading";

type ExternalRecordingAiNotesTabProps = {
  hasTranscript?: boolean;
  hasCandidate?: boolean;
  onSeek(t: number): void;
};

export const ExternalRecordingAiNotesTab: React.FC<
  ExternalRecordingAiNotesTabProps
> = ({ hasCandidate, hasTranscript, onSeek }) => {
  const { shareId } = useExternalShareParams();
  const [fetchAiNotes, { data, stopPolling: stopPollingAiNotes }] =
    useExternalShareAiNotesLazyQuery({
      variables: { id: shareId },
    });
  const aiNotes = data?.externalShareAiNotes;

  const footnoteMap = useMemo(() => {
    const uniqueTimestamps = new Set<number>();

    aiNotes?.headers?.forEach((header) => {
      [header.startTime]
        .concat(header.notes?.flatMap((n) => n.startTimes))
        .forEach((t) => {
          if (typeof t === "number") {
            uniqueTimestamps.add(t);
          }
        });
    });

    return Array.from(uniqueTimestamps)
      .sort((a, b) => a - b)
      .reduce(
        (acc, cur, idx) => Object.assign(acc, { [cur]: idx + 1 }),
        {} as Record<number, number>
      );
  }, [aiNotes]);

  const aiNotesLoading = ![
    AiNotesProcessingStatus.Completed,
    AiNotesProcessingStatus.Failed,
  ].includes(aiNotes?.status as AiNotesProcessingStatus);

  useEffect(() => {
    if (aiNotesLoading) {
      fetchAiNotes({ pollInterval: 5000 });
      return () => stopPollingAiNotes();
    }
  }, [aiNotesLoading]);

  return (
    <Flex px="5" pb="8" pt="4" direction="column" gap="4">
      {aiNotesLoading ? (
        <AiNotesLoading />
      ) : !hasCandidate ? (
        <AiNotesNoCandidate canUseOtherAISummaryFormats={false} />
      ) : !hasTranscript ? (
        <AiNotesNoTranscript />
      ) : !aiNotes?.headers.length ? (
        <AiNotesNoNotes />
      ) : (
        aiNotes.headers.map((section) => (
          <ReadonlyAiNoteBlock
            key={section.id}
            header={section.text}
            headerStartTime={section.startTime}
            notes={section.notes}
            footnoteMap={footnoteMap}
            emptyText={
              aiNotes.type === CallAiSummaryFormat.Scorecard
                ? "No notes can be provided because this question may not have been covered or requires subjective feedback."
                : "No results found for this topic."
            }
            onSeek={onSeek}
          />
        ))
      )}
    </Flex>
  );
};
