import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  StyleProps,
} from "@chakra-ui/react";
import React from "react";
import { FieldError } from "react-hook-form";

export type FormControlProps = {
  /** The name of the field to use in the UI */
  displayName?: string;
  displayNameProps?: StyleProps;
  /** Optional help text to display to the user below the form field */
  helpText?: React.ReactNode;
  /** Will hide `helpText` if the field has an error */
  hideHelpOnError?: boolean;
  /** The `FieldError` from react hook form, or a string */
  error?: Pick<FieldError, "message"> | string;
  /** The form field to render (e.g. `<Input />`) */
  children: React.ReactNode;
} & ChakraFormControlProps;

/**
 * A wrapper around Chakra UI for a single form field
 */
const BaseFormControl: React.ForwardRefRenderFunction<
  HTMLDivElement,
  FormControlProps
> = (props, ref) => {
  const {
    displayName,
    displayNameProps,
    helpText,
    hideHelpOnError,
    error,
    children,
    ...formControlProps
  } = props;
  const errorMessage = typeof error === "string" ? error : error?.message;

  let showHelpText = !!helpText;
  if (errorMessage && hideHelpOnError) {
    showHelpText = false;
  }

  return (
    <ChakraFormControl
      isInvalid={!!errorMessage}
      ref={ref}
      {...formControlProps}
    >
      {displayName && (
        <FormLabel
          color="gray.600"
          lineHeight="5"
          mb="1"
          mr="0"
          {...displayNameProps}
        >
          {displayName}
        </FormLabel>
      )}
      {children}
      {showHelpText && (
        <FormHelperText fontSize="sm" color="gray.500" mt="1" lineHeight="base">
          {helpText}
        </FormHelperText>
      )}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </ChakraFormControl>
  );
};

export const FormControl = forwardRef<FormControlProps, "div">(BaseFormControl);
