import { Box, Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { HiArrowLeft } from "react-icons/hi2";

import { Button, FullscreenHeader, IconButton } from "../../../components";

const pageHeaderHeight = "68px";
const topNavHeight = "48px";

type PickOrCreateTemplateProps = {
  templateId: string | undefined;
  onClose: (templateId?: string) => void;
  onContinueToPickTemplate: () => void;
  onContinueToWriteTemplate: () => void;
  onBack: () => void;
};

const PickOrCreateTemplate: React.FC<PickOrCreateTemplateProps> = ({
  templateId,
  onClose,
  onContinueToPickTemplate,
  onContinueToWriteTemplate,
  onBack,
}) => {
  return (
    <Box>
      <FullscreenHeader>
        <Flex height="40px" my={3} flexDir="row" alignItems="center">
          <IconButton
            aria-label="Back"
            icon={<HiArrowLeft size="24" />}
            color="gray.900"
            variant="icon"
            onClick={onBack}
            mr={9}
          />
          <Box fontSize="xl" fontWeight="medium">
            {templateId ? "Edit" : "New"} AI notes template
          </Box>
          <Button
            ml="auto"
            size="prismMd"
            variant="prismTertiary"
            onClick={() => onClose(templateId)}
          >
            Cancel
          </Button>
        </Flex>
        <Box
          height={1}
          background="linear-gradient(99.01deg, #0074DD -2.93%, #591EFF 58.85%, #9644FF 126.18%)"
        />
      </FullscreenHeader>
      <BodyWrapper>
        <ColumnWrapper>
          <Tile
            imagePath="/static/images/custom_templates_pick_example.svg"
            title="Pick from examples"
            description="Choose from the many default AI notes templates available."
            onClick={onContinueToPickTemplate}
          />
          <Flex
            mt="4"
            justify="center"
            fontSize="sm"
            fontWeight="normal"
            color="gray.600"
          >
            <Button
              variant="link"
              color="blue.500"
              onClick={onContinueToWriteTemplate}
            >
              Start from scratch
            </Button>
          </Flex>
        </ColumnWrapper>
      </BodyWrapper>
    </Box>
  );
};

type TileProps = {
  imagePath: string;
  title: string;
  description: string;
  onClick: () => void;
};

const Tile: React.FC<TileProps> = ({
  imagePath,
  title,
  description,
  onClick,
}) => {
  return (
    <Box
      rounded="md"
      border="1px solid"
      borderColor="blue.200"
      color="gray.600"
      borderRadius="2xl"
      textAlign="center"
      cursor="pointer"
      _hover={{
        borderWidth: "4px",
        borderColor: "blue.400",
        margin: "-3px",
      }}
      onClick={onClick}
    >
      <Box bg="blue.50" px="50px" py="26px" borderTopRadius="2xl" width="300px">
        <img src={imagePath} alt={title} width="200px" height="120px" />
      </Box>
      <Box p={6} width="300px">
        <Box color="gray.800" fontSize="2xl" fontWeight="semibold">
          {title}
        </Box>
        <Box color="gray.600" fontSize="sm">
          {description}
        </Box>
      </Box>
    </Box>
  );
};

const BodyWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const height = [
    "auto",
    "auto",
    `calc(100vh - ${pageHeaderHeight} - ${topNavHeight})`,
    `calc(100vh - ${pageHeaderHeight})`,
  ];

  return (
    <Flex
      overflowY="auto"
      height={height}
      flex="1"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      pb={[4, 4, 0, 0]}
    >
      <Box
        fontSize={["xl", "xl", "2xl", "2xl"]}
        color="gray.800"
        textAlign="center"
        mt={[8, 8, 0, 0]}
        mb={4}
      >
        How would you like to build your template?
      </Box>
      <Flex
        flexGrow="0"
        flexDir={["column", "column", "row", "row"]}
        justifyContent="center"
        gap={4}
      >
        {children}
      </Flex>
    </Flex>
  );
};

const ColumnWrapper: React.FC<
  {
    children: React.ReactNode;
  } & FlexProps
> = ({ children, ...flexProps }) => {
  return (
    <Flex
      flexDir="column"
      px={1}
      py={1}
      height="100%"
      overflowY="auto"
      width="310px"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export default PickOrCreateTemplate;
