import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import {
  HiOutlineChatAlt2,
  HiOutlineClock,
  HiOutlineUsers,
} from "react-icons/hi";

import { LoadingIndicator } from "../../../../components";
import {
  InterviewTotals,
  MetricName,
  ReportConfig,
  ReportDataPoint,
  UserFragment,
} from "../../../graphql";
import AnalyticsDateSelect from "../AnalyticsDateSelect";
import { REPORT_METRIC_MAP, REPORT_METRICS, SCORE_METRIC } from "../const";
import { FormatterOptions, xFormatter } from "../line-chart/xFormatter";
import { MetricConfig } from "../MetricConfig";
import { ReportMetric } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import useEnabledMetrics from "../useEnabledMetrics";
import {
  getStructuredFormatFromValue,
  getUnixFromISO,
  hasValidDataForMetric,
} from "../utils";
import MyAnalyticsCard from "./MyAnalyticsCard";
import MyAnalyticsLineChart from "./MyAnalyticsLineChart";
import useMyAnalyticsConfig, {
  MyAnalyticsConfig,
} from "./useMyAnalyticsConfig";
import useMyInsightsData from "./useMyInsightsData";

type MyAnalyticsProps = {
  interviewerId: string;
  interviewer: UserFragment;
  lifetimeValuesLoading: boolean;
  lifetimeValues?: InterviewTotals;
};

const MyAnalytics: React.FC<MyAnalyticsProps> = ({
  interviewerId,
  interviewer,
  lifetimeValuesLoading,
  lifetimeValues,
}) => {
  const { atsDataState } = useAnalyticsContext();
  const queryConfig = useMyAnalyticsConfig(interviewer);
  const enabledMetrics = new Set(useEnabledMetrics(atsDataState));

  const {
    tabularData: { base: baseTabularData, comparison: comparisonTabularData },
    timeseriesData: {
      base: baseTimeseriesData,
      comparison: comparisonTimeseriesData,
    },
    columnsConfig,
    loading,
    error,
    bucketSize,
    bucketInterval,
    benchmarks,
    totalCalls,
  } = useMyInsightsData(queryConfig, interviewer.email);

  const totalDuration = getStructuredFormatFromValue(
    lifetimeValues?.totalDuration,
    "hours-minutes"
  );

  const totalDurationValue = totalDuration.map((duration) => {
    const { value, unitSymbol } = duration;
    let finalSymbol = unitSymbol === "h" ? "hrs" : "mins";
    if (value === 1 && unitSymbol === "h") finalSymbol = "hr";
    if (value === 1 && unitSymbol === "m") finalSymbol = "min";
    return (
      <Box as="span" key={unitSymbol}>
        {value || 0}
        <Text
          as="span"
          pl="1"
          pr="2"
          fontSize="md"
          fontWeight="600"
          lineHeight="base"
          letterSpacing="initial"
        >
          {finalSymbol}
        </Text>
      </Box>
    );
  });

  const formatX = (x: string): string => {
    const customOptions =
      bucketInterval === "DAY"
        ? ({
            month: "long",
            day: "numeric",
            year: "numeric",
          } as FormatterOptions)
        : undefined;
    return xFormatter(
      getUnixFromISO(x),
      bucketInterval,
      bucketSize,
      customOptions
    );
  };

  const getDateRangeDescription = useCallback(
    (bucketInterval: string) => {
      const byWeek = bucketInterval === "WEEK" ? "Week of " : "";
      const description = (
        <>
          {byWeek}
          {formatX(baseTimeseriesData[0].xLabel as string)} &ndash;{" "}
          {byWeek.toLowerCase()}
          {formatX(baseTimeseriesData.slice(-1)[0].xLabel as string)}{" "}
        </>
      );
      return description;
    },
    [baseTimeseriesData, bucketInterval]
  );

  const metrics = [SCORE_METRIC].concat(
    REPORT_METRICS.filter(({ metric }) => enabledMetrics.has(metric))
  );

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Text fontSize="24px" fontWeight="500" color="gray.800" pr="2" mb="6">
          {interviewer.fullName.length > 0
            ? `My Insights: ${interviewer.fullName}`
            : "My Insights"}
        </Text>
      </Flex>
      <Box bg="gray.50" borderRadius="8px" p="6" mb="6" mt="2">
        <HStack spacing="1">
          <LifetimeValue
            title="Lifetime Total Interviews"
            loading={lifetimeValuesLoading}
            value={lifetimeValues?.totalInterviews || 0}
            icon={HiOutlineChatAlt2}
          />
          <LifetimeValue
            title="Lifetime Total Time"
            loading={lifetimeValuesLoading}
            value={totalDurationValue}
            icon={HiOutlineClock}
          />
          <LifetimeValue
            title="Lifetime Total Candidates"
            loading={lifetimeValuesLoading}
            value={lifetimeValues?.totalCandidates || 0}
            icon={HiOutlineUsers}
          />
        </HStack>
      </Box>
      <Flex dir="row" align="start" justify="space-between" mb="4">
        {!loading && baseTimeseriesData.length > 0 && (
          <Flex display="column">
            <Text fontSize="lg" fontWeight="600">
              {getDateRangeDescription(bucketInterval)}
            </Text>
            <Text>{totalCalls} interviews</Text>
          </Flex>
        )}
        <Flex ml="auto">
          <AnalyticsDateSelect
            state={queryConfig.dateRange.value}
            onSelect={queryConfig.dateRange.setValue}
          />
        </Flex>
      </Flex>
      {loading && (
        <Center>
          <LoadingIndicator />
        </Center>
      )}
      {!loading && error && (
        <Center>
          <Text color="red.500">Error loading data</Text>
        </Center>
      )}
      {!loading && !error && baseTimeseriesData.length > 0 && (
        <Tabs variant="unstyled">
          <TabList w="100%" overflowX="scroll" pb="2">
            {metrics.map((metric) => (
              <LineChartTab
                key={metric.label}
                metric={metric}
                baseTimeseriesData={baseTimeseriesData}
              >
                <MyAnalyticsCard
                  metric={metric}
                  baseData={baseTabularData}
                  comparisonData={comparisonTabularData}
                />
              </LineChartTab>
            ))}
          </TabList>
          <TabPanels>
            {metrics.map((metric) => (
              <LineChartTabPanel
                key={metric.label}
                metric={metric}
                baseTimeseriesData={baseTimeseriesData}
                comparisonTimeseriesData={comparisonTimeseriesData}
                columnsConfig={columnsConfig}
                bucketSize={bucketSize}
                bucketInterval={bucketInterval}
                queryConfig={queryConfig}
                benchmarkRange={benchmarks.find(
                  (b) => b.metric === metric.metric
                )}
                interviewerFullName={interviewer.fullName}
              />
            ))}
          </TabPanels>
        </Tabs>
      )}
    </>
  );
};

const LineChartTab: React.FC<{
  metric: ReportMetric;
  baseTimeseriesData: ReportDataPoint[];
  children: React.ReactNode;
  metricDisabled?: boolean;
}> = ({ metric, baseTimeseriesData, children, metricDisabled }) => {
  const noValidData = !hasValidDataForMetric(
    baseTimeseriesData,
    metric.rawDataKey
  );

  return (
    <Tab
      mr="4"
      _last={{ mr: "0" }}
      p="0"
      bg="white"
      border="1px solid"
      borderColor="gray.200"
      key={metric.label}
      _selected={{ bg: "blue.50", borderColor: "blue.500" }}
      _hover={{
        bg: "tabs.hoverBackground",
      }}
      borderRadius="lg"
      isDisabled={noValidData || metricDisabled}
    >
      {children}
    </Tab>
  );
};

const LineChartTabPanel: React.FC<{
  metric: ReportMetric;
  baseTimeseriesData: ReportDataPoint[];
  comparisonTimeseriesData: ReportDataPoint[];
  columnsConfig: ReportConfig[];
  bucketSize: number;
  bucketInterval: string;
  queryConfig: MyAnalyticsConfig;
  benchmarkRange?: {
    lowerBound: number;
    upperBound: number;
  };
  interviewerFullName: string;
}> = ({
  metric,
  baseTimeseriesData,
  comparisonTimeseriesData,
  columnsConfig,
  bucketSize,
  bucketInterval,
  queryConfig,
  benchmarkRange,
  interviewerFullName,
}) => {
  let detailsPlainText = metric.metric
    ? MetricConfig[metric.metric].detailsPlainText
    : null;

  // My Analytics calculates IQ score with default metrics
  // not the org custom metrics
  if (metric.metric === MetricName.Report) {
    detailsPlainText = `The Interview Quality score is an aggregate score of a several metrics. It’s computed by giving a positive or negative rating for each constituent metric, and then averaging those ratings across all interviews in the given time frame to produce a single overall Interview Quality score. The following metrics are used to compute your score: ${columnsConfig
      .map(({ name }) => {
        return REPORT_METRIC_MAP[name].label;
      })
      .join(", ")}.`;
  }

  return (
    <TabPanel key={metric.label}>
      <Box>
        <Text fontSize="lg" fontWeight="600" color="gray.800" mb="1" mt="5">
          {metric.label}
        </Text>
        {detailsPlainText}
      </Box>
      <Box height="500px" mt="10">
        <MyAnalyticsLineChart
          baseTimeseriesData={baseTimeseriesData}
          comparisonTimeseriesData={comparisonTimeseriesData}
          columnsConfig={columnsConfig}
          bucketSize={bucketSize}
          bucketInterval={bucketInterval}
          metric={metric}
          dateRangeState={queryConfig.dateRange.value}
          benchmarkRange={benchmarkRange}
          queryConfig={queryConfig}
          interviewerFullName={interviewerFullName}
        />
      </Box>
    </TabPanel>
  );
};

const LifetimeValue: React.FC<{
  title: string;
  value: React.ReactNode;
  loading: boolean;
  icon: React.ElementType;
}> = ({ title, value, loading, icon }) => {
  return (
    <Flex flexDir="row" alignItems="flex-end" w="100%" flexGrow="1">
      <Box mr="1.5" mb="2">
        <Icon as={icon} color="gray.500" w="20px" h="20px" />
      </Box>
      <Flex flexDir="column">
        <Text
          color="gray.500"
          fontSize="xs"
          fontWeight="600"
          textTransform="uppercase"
        >
          {title}
        </Text>
        {loading && <Skeleton height="48px" mt="4" width="100%" />}
        {!loading && (
          <Text
            fontSize="48px"
            fontWeight="300"
            color="gray.800"
            mt="4"
            lineHeight="48px"
            letterSpacing="-1.92px"
          >
            {value}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default MyAnalytics;
