import { Tooltip } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { IconButton } from "../../../components";
import { useSendGAEvent } from "../../../utils/googleAnalytics";

const EditTemplateButton: React.FC<{
  templateId: string;
}> = ({ templateId }) => {
  const navigate = useNavigate();
  const sendGAEvent = useSendGAEvent();

  return (
    <Tooltip label="Edit template">
      <IconButton
        aria-label="Edit template"
        variant="ghost"
        size="sm"
        color="gray.900"
        icon={<HiOutlinePencil size="20px" strokeWidth="1.4" />}
        onClick={() => {
          LogRocket.track(`ai-notes-templates-edit-start`);
          sendGAEvent(
            "ai_notes_custom_template_edit_start",
            "call_ai_templates",
            undefined,
            undefined,
            {
              templateId,
            }
          );
          navigate(`/template/${templateId}/edit`);
        }}
      />
    </Tooltip>
  );
};

export default EditTemplateButton;
