import React, { FormEventHandler, useRef, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import {
  ConfirmModalProps,
  errorToast,
  SettingsNote,
  successToast,
  useToast,
} from "../../../../components";
import { pluck } from "../../../../utils/array";
import {
  DataRetentionCountQueryVariables,
  useDataRetentionCountLazyQuery,
  useUpdateOrganizationDataRetentionMutation,
} from "../../../graphql";
import { DataRetentionInputsProps, FormValues } from "./types";

/**
 * Manages form state and form submission for Data Retention settings.
 *
 * On submission, a confirmation modal may be shown if the chosen settings
 * will result in data being deleted automatically
 */
export const useDataRetentionSettingsForm = ({
  organization,
}: DataRetentionInputsProps): Omit<
  UseFormReturn<FormValues>,
  "handleSubmit"
> & {
  onSubmit: FormEventHandler;
  submitLoading: boolean;
  confirmationModal: ConfirmModalProps;
} => {
  const toast = useToast();

  const [getRetentionCount, { loading: retentionCountLoading }] =
    useDataRetentionCountLazyQuery();

  const [updateSettings, { loading: loadingUpdateSettings }] =
    useUpdateOrganizationDataRetentionMutation({
      onError: (err) => {
        errorToast(toast, `Failed to setup : ${err.message}`);
      },
      onCompleted: () => {
        successToast(toast, "Retention values successfully updated");
      },
    });

  const { handleSubmit, ...form } = useForm<FormValues>({
    defaultValues: {
      enableRetentionSinceCandidateHired:
        organization.enableRetentionSinceCandidateHired,
      retentionDaysSinceCandidateHired:
        organization.retentionDaysSinceCandidateHired,
      enableRetentionSinceCandidateRejected:
        organization.enableRetentionSinceCandidateRejected,
      retentionDaysSinceCandidateRejected:
        organization.retentionDaysSinceCandidateRejected,
      enableRetentionSinceLastInterview:
        organization.enableRetentionSinceLastInterview,
      retentionDaysSinceLastInterview:
        organization.retentionDaysSinceLastInterview,
      enableRedactionForGreenhouse: organization.enableRedactionForGreenhouse,
      enableRedactionForLever: organization.enableRedactionForLever,
      enableRedactionForSmartrecruiters:
        organization.enableRedactionForSmartrecruiters,
      enableRedactionForAshby: organization.enableRedactionForAshby,
      enableRetentionSinceInterview: organization.enableRetentionSinceInterview,
      enableAutomaticRetentionDeletion:
        organization.enableAutomaticRetentionDeletion,
      retentionDaysSinceInterview: organization.retentionDaysSinceInterview,
      preventRedactionForTraining: organization.preventRedactionForTraining,
      // To be loaded async and set with reset
      retentionEmailList: [],
      retentionNotificationDays:
        organization.retentionNotificationDays?.filter(
          (s): s is string => !!s
        ) ?? [],
      retentionEmailSendTimezone: organization.retentionEmailSendTimezone,
      retentionEmailSendTime: getDefaultTime(
        organization.retentionEmailSendTime
      ),
    },
  });

  const { showConfirmation, ...confirmationModal } = useConfirmationModal();
  const getUserConfirmation = async (
    variables: DataRetentionCountQueryVariables
  ): Promise<boolean> => {
    const countQuery = await getRetentionCount({ variables });
    const callCount = countQuery.data?.previewCallsToRedact?.count ?? 0;
    const candidateCount =
      countQuery.data?.previewCandidatesToRedact?.totalCandidateCount ?? 0;

    let confirmed = false;
    if (callCount || candidateCount) {
      const callsStr = callCount
        ? `${callCount} interview${callCount > 1 ? "s" : ""}`
        : "";
      const candidateStr = candidateCount
        ? `${candidateCount} candidate${candidateCount > 1 ? "s" : ""}`
        : "";
      const displayCounts = [callsStr, candidateStr]
        .filter(Boolean)
        .join(" and ");

      confirmed = await showConfirmation({
        modalBodyText: (
          <>
            These settings will delete {displayCounts} in the next 24 hours.
            Would you like to continue?
            <SettingsNote mt="6">
              To view the candidates and interviews that are ready for deletion,
              go back and save these settings without automatic deletion
              enabled.
            </SettingsNote>
          </>
        ),
      });
    } else {
      // nothing to confirm
      confirmed = true;
    }

    return confirmed;
  };

  return {
    ...form,
    submitLoading: retentionCountLoading || loadingUpdateSettings,
    confirmationModal,
    onSubmit: handleSubmit(async (values) => {
      const {
        enableRetentionSinceCandidateHired,
        retentionDaysSinceCandidateHired,
        enableRetentionSinceCandidateRejected,
        retentionDaysSinceCandidateRejected,
        enableRetentionSinceLastInterview,
        retentionDaysSinceLastInterview,
        enableRetentionSinceInterview,
        retentionDaysSinceInterview,
        preventRedactionForTraining,
        enableAutomaticRetentionDeletion,
      } = values;

      if (enableAutomaticRetentionDeletion) {
        const confirmed = await getUserConfirmation({
          enableRetentionSinceCandidateHired,
          retentionDaysSinceCandidateHired,
          enableRetentionSinceCandidateRejected,
          retentionDaysSinceCandidateRejected,
          enableRetentionSinceLastInterview,
          retentionDaysSinceLastInterview,
          enableRetentionSinceInterview,
          retentionDaysSinceInterview,
          preventRedactionForTraining,
        });
        if (!confirmed) return;
      }

      const {
        enableRedactionForGreenhouse,
        enableRedactionForLever,
        enableRedactionForSmartrecruiters,
        enableRedactionForAshby,
        retentionEmailSendTime,
        retentionNotificationDays,
        retentionEmailSendTimezone,
        retentionEmailList,
      } = values;

      updateSettings({
        variables: {
          enableRetentionSinceCandidateHired,
          retentionDaysSinceCandidateHired,
          enableRetentionSinceCandidateRejected,
          retentionDaysSinceCandidateRejected,
          enableRetentionSinceLastInterview,
          retentionDaysSinceLastInterview,
          enableRedactionForGreenhouse,
          enableRedactionForLever,
          enableRedactionForSmartrecruiters,
          enableRedactionForAshby,
          enableRetentionSinceInterview,
          retentionDaysSinceInterview,
          retentionNotificationDays,
          retentionEmailList: pluck(retentionEmailList, "value"),
          retentionEmailSendTime,
          retentionEmailSendTimezone,
          preventRedactionForTraining,
          enableAutomaticRetentionDeletion,
        },
      });
    }),
  };
};

const useConfirmationModal = (): ConfirmModalProps & {
  /** Shows the modal and resolves `true` if the user confirms */
  showConfirmation(modalProps?: Partial<ConfirmModalProps>): Promise<boolean>;
} => {
  const [isOpen, setIsOpen] = useState(false);
  const resolver = useRef<(c: boolean) => void>();

  const [modalProps, setModalProps] = useState<Partial<ConfirmModalProps>>();

  return {
    ...modalProps,
    isOpen,
    onCancel: () => {
      setIsOpen(false);
      resolver.current?.(false);
    },
    onConfirm: () => {
      setIsOpen(false);
      resolver.current?.(true);
    },
    showConfirmation: (props) => {
      setModalProps(props);
      setIsOpen(true);
      return new Promise<boolean>((resolve) => {
        resolver.current = resolve;
      });
    },
  };
};

const getDefaultTime = (retentionEmailSendTime: any): string => {
  const date = new Date();
  const [hours, minutes] = retentionEmailSendTime?.split(":") ?? ["12", "00"];
  date.setHours(parseInt(hours));
  date.setMinutes(parseInt(minutes));
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
