import { ExampleTemplate } from "../types";

export const hiringManagerScreenExample: ExampleTemplate = {
  name: "Hiring Manager Screen",
  description: "Take detailed notes from the hiring manager screen.",
  prompt: `Take complete notes from this hiring manager screen. 

For the bullets, focus on key information like:
* logistics and situation information related to employment (current employer, timeline, hiring constraints)
* relevant skills and experience for the job
* interest in the job
* any issues that may need a follow up from the hiring manager

Organize it as an in-order list of topics, like a table of contents of the conversation. Be comprehensive. Use citation links for each bullet. Only output the topics and bullets.`,
  output: `#### General Work Preferences and Remote Work
- The candidate expressed appreciation for remote work, noting the convenience of not having to commute and the flexibility it provides [1:08](?t=68).

#### Questions About Company's Progress
- The candidate inquired about FutureTech's growth since their last interview in February, referencing the company's seed funding and long-term success strategies [2:06](?t=126).

#### Handling Complex Rollouts and Stakeholder Relationships
- The candidate described their approach to managing complex rollouts, emphasizing the importance of understanding stakeholder hierarchies and building trust early [16:07](?t=967).
- They provided an example from their time at Cardinal Systems, where they addressed challenges with a detractor by organizing an onsite meeting to align stakeholders and address concerns [17:23](?t=1043).

#### Managing High Workloads
- The candidate shared an example from their time at Horizon Enterprises, where they managed 16 projects simultaneously by creating a Gantt chart to allocate resources and prioritize tasks [22:36](?t=1356).
- They highlighted the use of tools like Trackit and QuickNotes for organization and documentation [25:17](?t=1517).

#### Building Scalable Low-Touch Onboarding Processes
- The candidate discussed the importance of standardization and data health in creating scalable onboarding processes [26:29](?t=1589).
- They provided an example of configuring intake meeting templates to streamline hiring processes and ensure consistency [29:07](?t=1747).

#### Prioritizing Tasks in a Queue System
- The candidate described their experience with task prioritization in TaskFlow and Trackit, emphasizing the importance of clear categorization and communication with clients [34:18](?t=2058).
- They explained how they used parent-child relationships in Trackit to manage workflows and avoid clutter [36:03](?t=2163).`,
};
