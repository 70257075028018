import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { HiDotsVertical, HiExternalLink, HiOutlineMail } from "react-icons/hi";
import {
  HiOutlineHome,
  HiOutlinePencil,
  HiOutlinePhone,
  HiOutlineSparkles,
} from "react-icons/hi2";

import {
  AtsIcon,
  Button,
  IconButton,
  Link,
  MenuButton,
  PillSelectOptions,
  RouterLink,
} from "../../../../components";
import BhLinkedIn from "../../../../components/Icons/BhLinkedIn";
import VideoCameraDisabled from "../../../../components/Icons/VideoCameraDisabled";
import { buildAshbyLink } from "../../../../utils/ashby";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { buildLeverLink } from "../../../../utils/lever";
import {
  Ats,
  CandidateAlertCandidateSubscription,
  CandidateAlertListItemFragment,
  CandidateFragment,
  CandidateLink,
  CandidateRatingBreakdown,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { CandidateAlertButton } from "./CandidateAlertButton";
import CandidateHeaderLinks, { LinkInputType } from "./CandidateHeaderLinks";
import CandidatePositionSelect from "./CandidatePositionSelect";
import CandidateStarRating from "./CandidateStarRating";

export type CandidateAlertSubscription = Pick<
  CandidateAlertCandidateSubscription,
  "mutedAt"
>;

export type CandidateHeaderBaseProps = {
  candidate: Pick<
    CandidateFragment,
    | "id"
    | "canEdit"
    | "fullName"
    | "phoneNumbers"
    | "emails"
    | "greenhouseLink"
    | "greenhouseId"
    | "mergeType"
    | "mergeRemoteId"
    | "lastLeverOpportunityId"
    | "customAtsUrl"
    | "recordingDisabled"
    | "rating"
    | "ratingBreakdown"
    | "skipRedaction"
  >;
  positionId?: string;
  candidatePositionOptions: PillSelectOptions;
  onPositionChange(positionId: string): void;
  alerts: CandidateAlertListItemFragment[];
  alertsEnabled: boolean;
  alertsLoading?: boolean;
  hasMoreAlerts?: boolean;
  alertSubscription?: CandidateAlertSubscription;
  onSubscribeToAlerts?(): void;
  onMuteAlerts?(): void;
  onLoadMoreAlerts?(): void;
  isExtension: boolean;
  isSmallScreen: boolean;
  atsOptInEnabled?: boolean;
  isAskEnabled: boolean;
  hasCompletedInterviews?: boolean;
  onAskClick?(): void;
  smartRecruitersLink?: string | null;
  linkedInLinks: Pick<CandidateLink, "source" | "type" | "link">[];
  onOpenEditCandidate(): void;
  onOpenDeleteCandidate?(): void;
  onOpenToggleCandidateDeletion?(): void;
  onUpdateCandidateOptOut?(): void;
  sendCandidateAlertEvent?(eventName: string): void;
};

/**
 * UI-only component for candidate header
 */
const CandidateHeaderBase: React.FC<CandidateHeaderBaseProps> = ({
  candidate,
  positionId,
  candidatePositionOptions,
  onPositionChange,
  alerts,
  alertsEnabled,
  alertsLoading = false,
  hasMoreAlerts = false,
  alertSubscription,
  onSubscribeToAlerts,
  onMuteAlerts,
  onLoadMoreAlerts,
  isExtension,
  isSmallScreen,
  isAskEnabled,
  hasCompletedInterviews,
  onAskClick,
  atsOptInEnabled,
  smartRecruitersLink,
  linkedInLinks,
  onOpenEditCandidate,
  onOpenDeleteCandidate,
  onOpenToggleCandidateDeletion,
  onUpdateCandidateOptOut,
  sendCandidateAlertEvent,
}) => {
  const sendGAEvent = useSendGAEvent();
  const useCandidatePageV2 = useFeatureFlag("candidate_page:v2");

  const isDesktop = !isSmallScreen;

  let atsName = "ATS";
  let href = null;
  if (candidate.lastLeverOpportunityId) {
    atsName = "Lever";
    href = buildLeverLink(undefined, candidate.lastLeverOpportunityId);
  }
  if (candidate.greenhouseId) {
    atsName = "Greenhouse";
    href = candidate.greenhouseLink;
  }
  if (candidate.mergeType === Ats.Ashby && !!candidate.mergeRemoteId) {
    atsName = "Ashby";
    href = buildAshbyLink(candidate.mergeRemoteId);
  }
  if (smartRecruitersLink) {
    atsName = "SmartRecruiters";
    href = smartRecruitersLink;
  }

  const candidateLinks = (
    <>
      {candidate.phoneNumbers.length > 0 && (
        <CandidateHeaderLinks
          label="Call Candidate"
          linkType="phone_number"
          links={candidate.phoneNumbers as LinkInputType[]}
          icon={<HiOutlinePhone strokeWidth="1.5px" size={20} />}
          dataTestId="candidate-phone-numbers-popover"
        />
      )}
      {candidate.emails.length > 0 && (
        <CandidateHeaderLinks
          label="Email Candidate"
          linkType="email"
          links={candidate.emails as LinkInputType[]}
          icon={<HiOutlineMail strokeWidth="1.5px" size={20} />}
          dataTestId="candidate-emails-popover"
        />
      )}
      {atsName !== "ATS" && !!href && (
        <Tooltip label={`View candidate in ${atsName}`}>
          <Link href={href} isExternal variant="icon">
            <AtsIcon
              fill="currentColor"
              marginRight="0"
              isGreenhouse={atsName === "Greenhouse"}
              isLever={atsName === "Lever"}
              isAshby={atsName === "Ashby"}
              isSmartrecruiters={atsName === "SmartRecruiters"}
            />
          </Link>
        </Tooltip>
      )}
      {!!candidate.customAtsUrl && (
        <Tooltip label="View candidate in your ATS">
          <Link href={candidate.customAtsUrl} isExternal variant="icon">
            {/* TODO: replace with a custom icon/update react-icons */}
            <HiExternalLink fill="currentColor" size={19} />
          </Link>
        </Tooltip>
      )}
      {linkedInLinks.length === 1 && (
        <Tooltip label="View candidate's LinkedIn profile">
          <Link
            href={linkedInLinks[0].link}
            isExternal
            variant="icon"
            aria-label="LinkedIn"
          >
            <BhLinkedIn width={4} height={4} />
          </Link>
        </Tooltip>
      )}
      {linkedInLinks.length > 1 && (
        <CandidateHeaderLinks
          label="View Candidate LinkedIn Profile"
          links={linkedInLinks as LinkInputType[]}
          linkType="linked_in"
          icon={<BhLinkedIn />}
        />
      )}
      {candidate.recordingDisabled && (
        <Flex
          alignItems="center"
          color="yellow.800"
          backgroundColor="yellow.200"
          px="1.5"
          py="0.5"
          borderRadius="2px"
          ml="3"
          mr="2"
        >
          <VideoCameraDisabled />
          <Text
            fontWeight="400"
            ml="1.5"
            fontSize="sm"
            data-testid="candidate-recording-disabled-text"
            whiteSpace="nowrap"
          >
            Recording disabled
          </Text>
        </Flex>
      )}
    </>
  );

  return (
    <Box>
      {!isExtension && !useCandidatePageV2 && (
        <Breadcrumb
          fontSize="sm"
          mb="3"
          color="gray.500"
          separator={<Text color="gray.300">/</Text>}
          spacing="2"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/">
              <HiOutlineHome size={16} />
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} fontWeight="400" to="/candidates">
              Candidates
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              as={RouterLink}
              to="#"
              color="gray.900"
              fontWeight="500"
            >
              Candidate details
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      )}
      <Flex justifyContent="space-between" alignItems={["baseline", null]}>
        <Flex alignItems={isSmallScreen ? "center" : "baseline"} px={[2, 0]}>
          <Text
            data-intercom="candidate-name-heading"
            data-testid="candidate-name-heading"
            as="h2"
            fontWeight="600"
            color="gray.800"
            fontSize={{ base: "3xl", lg: "34px" }}
            mr="4"
          >
            {candidate.fullName}
          </Text>
          {isDesktop && (
            <Flex alignItems="center" gap="0.5">
              {candidateLinks}
            </Flex>
          )}
          {isSmallScreen &&
            isAskEnabled &&
            hasCompletedInterviews !== undefined && (
              <Tooltip
                label="This is only available if you have access to at least 1 completed interview."
                placement="bottom-start"
                isDisabled={hasCompletedInterviews}
              >
                <Button
                  ml="auto"
                  bg="purple.450"
                  size="sm"
                  colorScheme="purple"
                  leftIcon={<Icon as={HiOutlineSparkles} />}
                  onClick={onAskClick}
                  isDisabled={!hasCompletedInterviews}
                  flexShrink={0}
                >
                  Ask
                </Button>
              </Tooltip>
            )}
        </Flex>
        <Flex gap="2">
          {isDesktop && (
            <>
              {alertsEnabled && (
                <CandidateAlertButton
                  alerts={alerts}
                  loading={alertsLoading}
                  showLoadMore={hasMoreAlerts}
                  onShowMenu={() => {
                    sendCandidateAlertEvent?.("closing_risk_menu_click");
                  }}
                  onLoadMore={() => onLoadMoreAlerts?.()}
                />
              )}
              <Tooltip
                label={
                  candidate.canEdit
                    ? "Edit Candidate"
                    : `Candidate info is editable only in ${atsName}`
                }
              >
                <IconButton
                  variant="icon"
                  aria-label="Edit Candidate"
                  icon={<HiOutlinePencil strokeWidth="1.5px" size={20} />}
                  onClick={onOpenEditCandidate}
                  data-testid="candidate-edit-button"
                  disabled={!candidate.canEdit}
                />
              </Tooltip>
            </>
          )}
          <Menu variant="new" autoSelect={false}>
            <MenuButton
              as={IconButton}
              variant="icon"
              icon={<HiDotsVertical size={20} />}
              onClick={(e) => e.stopPropagation()}
              data-testid="candidate-more-menu-button"
            />
            <MenuList minWidth="220px">
              {isSmallScreen && (
                <MenuItem onClick={onOpenEditCandidate}>
                  Edit candidate
                </MenuItem>
              )}
              {atsOptInEnabled ? (
                <MenuItem
                  isDisabled
                  data-testid="candidate-menu-option-opt-out"
                >
                  <Tooltip label="This candidate's recording settings are managed in your ats">
                    <span>
                      {candidate.recordingDisabled
                        ? "Record interviews"
                        : "Do not record interviews"}
                    </span>
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem
                  data-testid="candidate-menu-option-opt-out"
                  onClick={onUpdateCandidateOptOut}
                >
                  {candidate.recordingDisabled
                    ? "Record interviews"
                    : "Do not record interviews"}
                </MenuItem>
              )}
              {onOpenToggleCandidateDeletion && (
                <MenuItem onClick={onOpenToggleCandidateDeletion}>
                  {candidate.skipRedaction ? "Allow " : "Prevent "}
                  deletion
                </MenuItem>
              )}
              {onOpenDeleteCandidate && (
                <MenuItem onClick={onOpenDeleteCandidate}>
                  Delete candidate
                </MenuItem>
              )}
              {alertsEnabled &&
                onSubscribeToAlerts &&
                (!alertSubscription || alertSubscription.mutedAt) && (
                  <MenuItem onClick={onSubscribeToAlerts}>
                    Subscribe to alerts
                  </MenuItem>
                )}
              {alertsEnabled &&
                onMuteAlerts &&
                alertSubscription &&
                !alertSubscription.mutedAt && (
                  <MenuItem onClick={onMuteAlerts}>Mute alerts</MenuItem>
                )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      {isSmallScreen && (
        <Flex pb="2" alignItems="center" wrap="wrap" columnGap="0.5" rowGap="1">
          {candidateLinks}
          {alertsEnabled && (
            <CandidateAlertButton
              alerts={alerts}
              loading={alertsLoading}
              showLoadMore={hasMoreAlerts}
              onShowMenu={() => {
                sendCandidateAlertEvent?.("closing_risk_menu_click");
              }}
              onLoadMore={() => onLoadMoreAlerts?.()}
              menuProps={{ computePositionOnMount: true }}
              menuListProps={{ m: "0", maxH: "min(700px, 90vh)" }}
            />
          )}
        </Flex>
      )}
      <Flex
        direction={["column", "row"]}
        alignItems={["flex-start", "center"]}
        flexWrap="wrap"
        gap={[2, 5]}
        px={[2, 0]}
        mt={[2, 2]}
      >
        <CandidatePositionSelect
          options={candidatePositionOptions.filter((option) => option.count)}
          positionId={positionId}
          onSelect={(position) => {
            sendGAEvent("position_change", "candidate");
            onPositionChange(position.key);
          }}
        />
        {candidate.rating && candidate.ratingBreakdown && (
          <CandidateStarRating
            rating={Math.floor(candidate.rating)}
            ratingBreakdown={
              candidate.ratingBreakdown as CandidateRatingBreakdown[]
            }
          />
        )}
      </Flex>
    </Box>
  );
};

export default CandidateHeaderBase;
