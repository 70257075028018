import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateFragment,
  useAddCandidateAlertUserSubscriptionsMutation,
  useCandidateAlertLazyQuery,
  useCandidateAlertUserSubscriptionsQuery,
  useMuteCandidateAlertUserSubscriptionsMutation,
} from "../../../graphql";
import { useIsExtension } from "../../../hooks/useAppEnvironmentContext";
import { useCandidateAlertDisabled } from "../../CandidateAlert";
import { CandidateHeaderBaseProps } from "./CandidateHeaderBase";

const PAGE_LIMIT = 10;

type UseCandidateAlertsParams = {
  candidate: Pick<CandidateFragment, "id">;
  onSubscribeToAlerts?(): void;
  onMuteAlerts?(): void;
  onSubscriptionError?(error: ApolloError): void;
};

type UseCandidateAlertsReturn = Pick<
  CandidateHeaderBaseProps,
  | "alerts"
  | "alertsEnabled"
  | "alertsLoading"
  | "alertSubscription"
  | "hasMoreAlerts"
  | "sendCandidateAlertEvent"
  | "onMuteAlerts"
  | "onSubscribeToAlerts"
  | "onLoadMoreAlerts"
>;

export const useCandidateAlerts = ({
  candidate,
  onSubscribeToAlerts,
  onMuteAlerts,
  onSubscriptionError,
}: UseCandidateAlertsParams): UseCandidateAlertsReturn => {
  const sendGAEvent = useSendGAEvent();
  const isExtension = useIsExtension();

  const candidateId = candidate.id;
  const candidateAlertsDisabled = useCandidateAlertDisabled();

  const sendCandidateAlertEvent = (event: string): void =>
    sendGAEvent(
      event,
      "candidate_alert",
      isExtension ? "extension" : "main app",
      undefined,
      { candidate }
    );

  const [getAlert, candidateAlertQuery] = useCandidateAlertLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const candidateAlertResults =
    candidateAlertQuery.data?.candidateAlert.results || [];
  const hasMoreAlerts =
    candidateAlertQuery.data?.candidateAlert.pageInfo.hasNextPage ?? false;
  const alertsLoading = candidateAlertQuery.loading;

  useEffect(() => {
    if (!candidateAlertsDisabled) {
      getAlert({
        variables: {
          candidateId: candidate.id,
          pagination: {
            limit: PAGE_LIMIT,
          },
        },
      });
    }
  }, [candidateAlertsDisabled]);

  const { data: alertSubscriptionsData } =
    useCandidateAlertUserSubscriptionsQuery({
      skip: candidateAlertsDisabled,
    });
  const alertSubscription =
    alertSubscriptionsData?.candidateAlertUserSubscriptions.candidateSubscriptions.find(
      (c) => c.candidateId === candidateId
    );

  const [addSubscription] = useAddCandidateAlertUserSubscriptionsMutation({
    onCompleted: (data) => {
      if (data.addCandidateAlertUserSubscriptions) {
        onSubscribeToAlerts?.();
      }
    },
    onError: (error) => onSubscriptionError?.(error),
  });

  const [muteSubscription] = useMuteCandidateAlertUserSubscriptionsMutation({
    onCompleted: (data) => {
      if (data.muteCandidateAlertUserSubscriptions) {
        onMuteAlerts?.();
      }
    },
    onError: (error) => onSubscriptionError?.(error),
  });

  const ifEnabled = <T>(val: T): T | undefined =>
    candidateAlertsDisabled ? undefined : val;

  return {
    alerts: candidateAlertResults,
    alertsEnabled: !candidateAlertsDisabled,
    alertsLoading,
    hasMoreAlerts,
    alertSubscription,
    sendCandidateAlertEvent,
    onSubscribeToAlerts: ifEnabled(() => {
      addSubscription({
        variables: {
          candidateIds: [candidate.id],
          positionIds: [],
        },
      });
      sendCandidateAlertEvent("subscribe_to_alert");
    }),
    onMuteAlerts: ifEnabled(() => {
      muteSubscription({
        variables: {
          candidateIds: [candidate.id],
          positionIds: [],
        },
      });
      sendCandidateAlertEvent("mute_alert");
    }),
    onLoadMoreAlerts: ifEnabled(() => {
      candidateAlertQuery.fetchMore({
        variables: {
          candidateId,
          pagination: {
            limit: Number(candidateAlertResults.length) + PAGE_LIMIT,
          },
        },
      });
      sendCandidateAlertEvent("load_more");
    }),
  };
};
