import {
  Box,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Icon,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useRef } from "react";
import { isMobile } from "react-device-detect";
import { GoDotFill } from "react-icons/go";
import ResizeTextarea from "react-textarea-autosize";

import { Link } from "../../../../../../../components";
import { formatDuration } from "../../../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../../../utils/googleAnalytics";
import { QuestionAndAnswer } from "../../../../../../graphql";
import MonospacedText from "../../../../../MonospacedText";
import AiNotesEditableQuestion from "./AiNotesEditableQuestion";
import AiNotesQuestionMenu from "./AiNotesQuestionMenu";

const AiNotesQuestionBlock: React.FC<{
  questionAndAnswer: QuestionAndAnswer;
  callId: string;
  updateAiNote: (itemId: string, newValue: string) => void;
  updateAiNoteQuestion: (itemId: string, newValue: string) => void;
  debug: boolean;
  canEdit: boolean;
  onClickTimestamp(t: number): void;
  copyNotes: () => void;
}> = ({
  questionAndAnswer,
  callId,
  updateAiNote,
  updateAiNoteQuestion,
  debug,
  canEdit,
  onClickTimestamp,
  copyNotes,
}) => {
  const input = useRef<any>(null); // any type
  const sendGAEvent = useSendGAEvent();

  const moveCursor = useCallback(() => {
    if (input?.current) {
      const el = input.current;
      el.setSelectionRange(el.value.length, el.value.length);
    }
  }, [input]);

  // Old ai notes were always for the interviewer, so assume default false
  const { isCandidate } = questionAndAnswer;

  return (
    <Box
      mb={isCandidate ? "0" : "4"}
      pb="12px"
      pr="12px"
      fontSize="sm"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
    >
      <Popover
        isOpen={isMobile ? false : undefined}
        placement="top-end"
        trigger="hover"
        openDelay={0}
        closeDelay={0}
        gutter={-8}
      >
        <PopoverTrigger>
          <Flex
            py={isCandidate ? "5px" : "10px"}
            px="4"
            borderRadius="sm"
            flexDir="row"
            alignItems="start"
          >
            <AiNotesEditableQuestion
              text={questionAndAnswer.question}
              callAiNoteId={questionAndAnswer.id}
              edited={questionAndAnswer.edited || false}
              updateAiNoteQuestion={updateAiNoteQuestion}
              canEdit={canEdit}
              isCandidate={isCandidate}
            />
            <Link
              fontWeight="500"
              href={`/interview/${callId}?t=${questionAndAnswer.startTime}`}
              onClick={(e) => {
                e.preventDefault();
                sendGAEvent("ai_notes_seek", "call_review");
                LogRocket.track("ai-notes-seek");
                onClickTimestamp(questionAndAnswer.startTime);
              }}
            >
              <MonospacedText
                text={formatDuration(Math.round(questionAndAnswer.startTime))}
              />
            </Link>
          </Flex>
        </PopoverTrigger>
        <PopoverContent border="none" w="auto">
          <AiNotesQuestionMenu
            callId={callId}
            time={questionAndAnswer.startTime}
            copyNotes={copyNotes}
          />
        </PopoverContent>
      </Popover>
      {!isCandidate && (
        <Box color="gray.700">
          <UnorderedList listStyleType="none" ml="0">
            {questionAndAnswer.answerItems.map((item) => (
              <Box
                as={ListItem}
                key={item.id}
                _hover={{
                  bg: "blue.50",
                }}
              >
                <Editable
                  defaultValue={item.text}
                  isPreviewFocusable
                  selectAllOnFocus={false}
                  onSubmit={(newValue: string) => {
                    if (newValue === item.text) return;
                    updateAiNote(item.id, newValue);
                    LogRocket.track("ai-notes-edit-answer");
                    sendGAEvent("ai_notes_edit_answer", "call_review");
                  }}
                  onEdit={moveCursor}
                  isDisabled={!canEdit}
                >
                  {({ isEditing }: { isEditing: boolean }) => {
                    return (
                      <Flex direction="row" align="baseline" cursor="pointer">
                        <Icon
                          as={GoDotFill}
                          boxSize="2"
                          ml="4"
                          mr="6px"
                          flexGrow="0"
                        />
                        <Box display="flex" py="1" flexGrow="1">
                          <EditablePreview cursor="pointer" flexGrow="1" />
                          {!isEditing && item.edited && (
                            <>
                              {" "}
                              <Text
                                as="span"
                                pt={1}
                                mr={4}
                                fontSize="xs"
                                color="gray.400"
                              >
                                (edited)
                              </Text>
                            </>
                          )}
                        </Box>
                        <EditableInput
                          ref={input}
                          as={ResizeTextarea}
                          flexGrow="1"
                          resize="none"
                          overflow="hidden"
                          _focus={{ boxShadow: "none" }}
                        />
                      </Flex>
                    );
                  }}
                </Editable>
              </Box>
            ))}
          </UnorderedList>
        </Box>
      )}
      {debug && (
        <Box px="5" color="gray.600">
          <Divider my="12px" />
          <Text fontWeight="semibold" mb="3">
            Match Reason:{questionAndAnswer.matchReason}
          </Text>
          <Text fontWeight="semibold" mb="3">
            Original Question:{questionAndAnswer.originalQuestion}
          </Text>
          <Text>Original Answer:{questionAndAnswer.originalAnswer}</Text>
        </Box>
      )}
    </Box>
  );
};

export default AiNotesQuestionBlock;
