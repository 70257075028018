import { Box, Center, SimpleGrid } from "@chakra-ui/react";
import React from "react";

import { Alert as ErrorAlert, LoadingIndicator } from "../../../../components";
import { ATS_METRICS, NON_ATS_METRICS } from "../const";
import { getPercentDifferenceDisplay } from "../difference";
import { MetricConfig } from "../MetricConfig";
import { ReportMetric } from "../types";
import useAnalyticsContext from "../useAnalyticsContext";
import useEnabledMetrics from "../useEnabledMetrics";
import { getAverageMetricValue, StructuredFormattedValue } from "../utils";
import OverviewCard from "./OverviewCard";
import OverviewMetricLineChart from "./OverviewMetricLineChart";
import OverviewSection from "./OverviewSection";
import { OverviewConfig } from "./types";
import useOverviewData from "./useOverviewData";

type OverviewSectionMetricsProps = {
  /* The organization ID */
  // Organization ID is set by analytics context to allow for internal org switching
  selectedOrgId: string;
  overviewConfig: OverviewConfig;
};

const OverviewSectionMetrics: React.FC<OverviewSectionMetricsProps> = ({
  selectedOrgId,
  overviewConfig,
}) => {
  const { atsDataState } = useAnalyticsContext();
  const overviewData = useOverviewData(overviewConfig, selectedOrgId);
  const enabledMetrics = new Set(useEnabledMetrics(atsDataState));

  const getMetricValue = (
    metric: ReportMetric
  ): string | StructuredFormattedValue[] => {
    const avg = getAverageMetricValue(overviewData.baseData, metric.rawDataKey);
    if (avg === null) {
      return "—";
    }
    return [
      {
        value: (metric.format ? metric.format(avg) : avg).toString(),
        unitSymbol: metric.units || "",
      },
    ];
  };

  const metrics = NON_ATS_METRICS.concat(ATS_METRICS).filter(({ metric }) =>
    enabledMetrics.has(metric)
  );

  return (
    <OverviewSection title="Interview Activity">
      {overviewData.loading && (
        <Center>
          <LoadingIndicator />
        </Center>
      )}
      {overviewData.error && (
        <ErrorAlert
          status="error"
          description="There was an error fetching your organization data."
        />
      )}
      <SimpleGrid columns={2} spacing="4">
        {!overviewData.loading &&
          !overviewData.error &&
          metrics.map((metric) => (
            <OverviewCard
              key={metric.dataKey}
              title={metric.label}
              value={getMetricValue(metric)}
              tooltipLabel={MetricConfig[metric.metric].detailsPlainText}
              percentDifference={getPercentDifferenceDisplay(
                getAverageMetricValue(overviewData.baseData, metric.rawDataKey),
                getAverageMetricValue(
                  overviewData.comparisonData || [],
                  metric.rawDataKey
                )
              )}
            >
              <Box mt="10">
                <OverviewMetricLineChart
                  overviewData={overviewData}
                  metric={metric}
                  dateRangeState={overviewConfig.dateRangeState}
                  benchmarkRange={overviewData.benchmarks.find(
                    (b) => b.metric === metric.metric
                  )}
                />
              </Box>
            </OverviewCard>
          ))}
      </SimpleGrid>
    </OverviewSection>
  );
};

export default OverviewSectionMetrics;
